import React from 'react'

global.isclient && require('jsoneditor/dist/jsoneditor.css')
const JSONEditor = global.isclient ? require('jsoneditor') : {}

const RxUpload = global.rootRequire('components/shares/rxUpload').default
const RxToggle = global.rootRequire('components/shares/rxToggle').default
const { MainTable } = global.rootRequire('components/shares/rxTemplates')
const rxu = global.rxu

// T A B L E
export const renderTable = (vm) =>
  <MainTable><thead>{vm.run('renderTableHead')}</thead><tbody>{vm.run('renderTableBody')}</tbody></MainTable>

export const renderTableHead = (vm) =>
  <tr>
    <th className='rxwidth--100'>Pic</th>
    <th className={vm.helpSortClass('name', ['rxwidth--220'])} onClick={(e) => vm.onClickSort(e, 'name')}>Name</th>
    <th className={vm.helpSortClass('created_at', ['rxwidth--120'])} onClick={(e) => vm.onClickSort(e, 'created_at')}>Created at</th>
    <th className='rxwidth--100'>Action</th>
  </tr>

export const renderTableBody = (vm) => global.rxu.array(vm.state.data).map(perdata =>
  <tr key={perdata._id}>
    <td><img className='betable__img' alt={perdata.name} src={global.rxu.config.base_api + '/upload/image/' + (perdata.img_landscape || 'ico_app_default.jpg')} /></td>
    <td>{perdata.name}</td>
    <td><small>{global.rxu.date(perdata.created_at)}</small></td>
    {vm.renderTableBodyBtns(vm, perdata)}
  </tr>)

export const renderTableBodyBtns = (context, perdata, vm) => {
  return <td>
    {context.state.paging.search_is_deleted !== 1 &&
      <div> {(perdata.is_hot) ? <span className='betable__icohot'><span className='icon-fire' />Hot</span> : <span className='betable__iconothot' />}
        {(perdata.is_active !== 0) && <span className='betable__icoon'>On</span>}{(perdata.is_active === 0) && <span className='betable__icooff'>Off</span>}
        <span className='betable__btnedit' onClick={(e) => context.onClickDataEdit(e, perdata)}><i className='icon-pencil' /></span>
        <span className='betable__btndelete' onClick={(e) => context.onClickDataDelete(e, perdata)}><i className='icon-close' /></span>
      </div>}
    {context.state.paging.search_is_deleted === 1 &&
      <div> <span className='betable__restore' onClick={(e) => context.onClickDataRestore(e, perdata)}>Restore</span></div>}
  </td>
}

// P A G I N
export const renderPagin = (vm) => {
  const paging = vm.state.paging
  const count = global.rxu.get(vm.state.extra, 'count', paging.pg_size)
  const maxpage = Math.ceil(count / paging.pg_size)
  const pages = global.rxu.arrayFromTo(1, maxpage)
  return (
    <div className='clearfix rxpagingwrap'>
      <div className='rxpagingcount'>Total Rows <b>{global.rxu.get(vm.state.extra, 'count', 0)}</b></div>
      <div className='rxpaging'>
        <div className='rxpagesize'>
          <span>Pagesize</span>
          <select value={paging.pg_size} onChange={e => vm.onChangePageSize(e)}>
            {[1, 2, 5, 10, 20].map(ele => (<option key={ele} value={ele * 10}>{ele * 10}</option>))}
          </select>
        </div>
        <div className='rxpage' onClick={(evt) => { vm.onClickPaging(paging.pg_page - 1) }}><i className='icon-arrow-left' /></div>
        {pages.map((ele) => ([1, paging.pg_page, maxpage].indexOf(ele) !== -1 || ele % Math.floor((maxpage / 10)) === 0) &&
          (<div key={ele} className={ele === paging.pg_page ? 'rxpage rxactive' : 'rxpage'} onClick={(evt) => { vm.onClickPaging(ele) }}>{ele}</div>))}
        <div className='rxpage' onClick={(evt) => { vm.onClickPaging(paging.pg_page + 1) }}><i className='icon-arrow-right' /></div>
      </div>
    </div>
  )
}

// F I L T E R
export const renderFilter = (vm) =>
  <div>
    <div className='betable__filter'>
      <div className='betable__btns clearfix'>
        <span className='betable__btnadd btn' onClick={(e) => vm.run('onClickDataNew', e)}><i className='icon-plus betable__addbtn' />Add</span>
        <span className='betable__btnimport btn'><i className='icon-cloud-upload' />Import</span>
        <span className='betable__btnexport btn' onClick={(e) => vm.onClickDataExport(e)}><i className='icon-cloud-download' />Export</span>
      </div>
      <div className='betable__findwrap'>
        <input className='betable__findinput' type='text' placeholder='Find' onKeyUp={(e) => vm.onBlurDatafilter(e, 'name')} />
        {vm.flag('findExpand')
          ? <span className='btn--default btn--findexpand' onClick={e => vm.toggle('findExpand')}><i className='icon-arrow-up betable__recycle' /></span>
          : <span className='btn--default btn--findexpand' onClick={e => vm.toggle('findExpand')}><i className='icon-arrow-down betable__recycle' /></span>}
        {vm.state.paging.search_is_deleted !== 1
          ? <span className='btn--default' onClick={(e) => vm.onClickDataTrash(e, 1)}><i className='icon-trash betable__recycle' /></span>
          : <span className='btn--default' onClick={(e) => vm.onClickDataTrash(e, 0)}><i className='icon-list betable__recycle' /></span>}
        {vm.flag('findExpand')
          ? <div className='findexpand'>
            <div className='findexpand_head'>Advance Filter</div>
            <div className='findexpand_body clearfix'>{vm.run('renderFindExpand')}</div>
            <div />
          </div> : <div />}
      </div>
    </div>
    {vm.props.name.indexOf('permission') !== -1 && <div className='adblock__btngen' onClick={(e) => vm.onClickGenPermission()}>Generate permissions</div>}
  </div>

// F I N D  E X P A N D
export const renderFindExpand = (vm) => {
  const filterFields = rxu.get(vm.state, ['temp', 'filterFields'])
  const filterEles = []
  for (const i in filterFields) {
    filterEles.push(
      <div className='col-md-6' key={i}>
        <div className='betable__findlabel'>Find by {i}</div>
        <input className='betable__findinput betable__findinput-full' type='text' placeholder={'vd: ' + filterFields[i]} onKeyUp={(e) => vm.onBlurDatafilter(e, i)} />
      </div>)
  }
  return <div className='rxfull'>
    {filterEles}
  </div>
}

// F O R M
export const renderForm = (vm) => {
  if (vm.state.editingData.created_at || vm.state.editingData.created_at === 0) {
    global.scrollfire()
    return <div className='adform'>
      <div className='adform__header'>
        {vm.run('renderFormHead')}
      </div>
      <div className='adform__body'>
        {vm.run('renderFormBody')}
        {vm.run('renderFormRaw')}
      </div>
      <div className='adform__footer'>
        {vm.run('renderFormBtns')}
      </div>
    </div>
  }
}

const renderFormBodyDefault = (vm) =>
  [{ name: 'Picture', func: () => (<RxUpload callback={(e) => vm.callbackUpload(e)} images={vm.state.editingData.img_landscape} />) },
    { type: 'devide' },
    { name: 'Name', func: () => (<input tabIndex='1' type='text' value={vm.state.editingData.name} onChange={(e) => vm.onBlurData(e, 'name')} className='fullwidth-input' />) },
    { name: 'Desc', func: () => (<input tabIndex='2' type='text' value={vm.state.editingData.desc} onChange={(e) => vm.onBlurData(e, 'desc')} className='fullwidth-input' />) },
    { type: 'devide' },
    { name: 'Status', func: () => (<RxToggle value={vm.state.editingData.is_active} onToggle={(newValue) => vm.onBlurDataValue(newValue, 'is_active')} />) }]

export const renderFormBody = (vm) => {
  const tempform = vm.props.form || renderFormBodyDefault(vm)

  if ((vm.state.editingData.created_at || vm.state.editingData.created_at === 0) && tempform) {
    const form = tempform.map((perdata, index) => {
      perdata.type = perdata.type || 'input'
      let result = (<div />)
      switch (perdata.type) {
        case 'input':
          result = <div key={index}><div className='fullwidth-label'>{perdata.name}</div>{perdata.func(vm)}</div>
          break
        case 'fullinput':
          result = <div key={index} className='fullwidth-frominput'><div className='fullwidth-label'>{perdata.name}</div>{perdata.func(vm)}</div>
          break
        case 'devide':
          result = <div key={index} className='fullwidth-formdevide cleafix' />
          break
        default:
          result = <div key={index}><div className='fullwidth-label'>{perdata.name}</div>{perdata.func(vm)}</div>
          break
      }
      return result
    })
    return form
  }
}

export const renderFormHead = (vm) =>
  <div className='adform__name rx-sticky'>Edit <b>{vm.state.editingData.name}</b><div className='adform__close icon-close' onClick={(e) => vm.onClickDataEdit(e, {})} /></div>

export const renderFormRaw = (vm) => {
  if (vm.state.editingData._id) {
    if (vm.state.flags.rawedit) {
      setTimeout(() => {
        vm.jsoneditor = new JSONEditor(vm.jsonEditor, { mode: 'tree' })
        vm.jsoneditor.set(vm.state.editingData)
      }, 500)
    }

    return (
      <div className='clearfix adform__rawedit'>
        <div onClick={evt => vm.toggle('rawedit')} className='adform__raweditbtn'>RawEdit</div>
        {vm.state.flags.rawedit ? <div>
          <div className='adform__raweditcontent' ref={e => { vm.jsonEditor = e }} />
          <div tabIndex='11' className='adform__btnedit adform__btnedit--raw' onClick={(e) => vm.onClickDataUpdateSubmitRaw(e)} onKeyPress={(e) => vm.onClickDataUpdateSubmitRaw(e)}>RawUpdate</div>
        </div> : <div />}
      </div>
    )
  }
}

export const renderFormBtns = (vm) =>
  <div className='adform__btns clearfix rx-stickybot'>
    <div tabIndex='10' className='adform__btncancel' onClick={(e) => vm.onClickDataEdit(e, {})} onKeyPress={(e) => vm.onClickDataEdit(e, {})}>Cancel</div>
    {vm.state.editingData.created_at !== 1 && <div tabIndex='11' className='adform__btnedit' onClick={(e) => vm.onClickDataUpdateSubmit(e)} onKeyPress={(e) => vm.onClickDataUpdateSubmit(e)}>Update</div>}
    <div tabIndex='12' className='adform__btnclone' onClick={(e) => vm.onClickDataCreateSubmit(e)} onKeyPress={(e) => vm.onClickDataCreateSubmit(e)}>Create new</div>
  </div>

export const renderComponent = (vm) =>
  <div>
    <div className='betable'>
      {vm.run('renderFilter')}
      {vm.run('renderPagin')}
      {vm.run('renderTable')}
    </div>
    {vm.run('renderForm')}
  </div>
