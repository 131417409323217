import * as Jsx from './ggads.jsx'
const RxComponent = global.rootRequire('components/shares/rxComponent').default
const { apiget } = global.rootRequire('classes/request')

class ggadsComponent extends RxComponent {
  constructor (props, context) {
    super(props, context, Jsx)
    this.state = {
      dashboard: [],
      studio: [],
      games: []
    }
    this.onClickCatItem = this.onClickCatItem.bind(this)
    this.runUpdateCat = this.runUpdateCat.bind(this)

    this.fetchDataGames()
  }

  fetchDataGames() {
    apiget(global.rxu.config.api_game + '/listall', {}, { 1: (json) => {
      console.log(json)
      this.setState({ games: json.data }, () => {}) 
    } })
  }

  onClickDataNew (e) {
    global.scrolltop()
    const clone = {
      name: '',
      customer_id: '',
      created_at: 1,
      is_deleted: 0,
      is_active: 1,
      is_hot: 0,
 
    }
    e.inthis.setState({ editingData: clone })
  }

  onClickCatItem (e) {
    this.setState({ flagUpdateCat: !this.state.flagUpdateCat, curCat: e._id })
  }

  runUpdateCat (e) {
    const paging = (this.state.curCat) ? { searchid_appdist: this.state.curCat, st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 } : { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 }
    e.inthis.setState({ paging: paging }, () => { e.inthis.fetchData() })
  }

  // R E N D E R S
  render () { return this.renderComponent() }
}

export default ggadsComponent
