import * as Jsx from './campaignDashboard.jsx'
const RxComponent = global.rootRequire('components/shares/rxComponent').default
const { apiget } = global.rootRequire('classes/request')

class gameComponent extends RxComponent {
  constructor (props, context) {
    super(props, context, Jsx)
    this.state = {
      dashboard: [],
      studio: [],
      admod: [],
      arrGgAds: [],
      platform: [{ _id: 0, name: 'Android' }, { _id: 1, name: 'IOS' }],
      gameId: [{ _id: 0, name: 'Android' }, { _id: 1, name: 'IOS' }],
      categories: [{ _id: 0, name: 'Android' }, { _id: 1, name: 'IOS' }]
    }
    this.onClickCatItem = this.onClickCatItem.bind(this)
    this.runUpdateCat = this.runUpdateCat.bind(this)
    this.fetchData()
    this.fetchStudio()
    this.fetchAdmod()
    this.fetchGgAds()
    this.fetchAccount()
  }

  onClickDataNew (e) {
    global.scrolltop()
    const timeStr = Date.now().toString()
    const clone = {
      name: timeStr,
      desc: '',
      accountFilter: '',
      created_at: 1,
      is_deleted: 0,
      is_active: 1,
      is_hot: 0,
      img_landscape: '',
      packageBundle: '',
      platform: '',
      gameFbId: '',
      gameGgId: '',
      packageName: '',
      customer_account_id: '',
      status: 0,
      storeStatus: 0,
      bigQueryCrawlEnabled: 0,
      appUrlOnStore: '',
      gameId: '',
      faceAppId: '',
      faceAppSecret: '',
      googleBigquery: '',
      googleIap: '',
      developBucketId: '',
      fcmService: '',
      categories: '',
      account: []
    }
    e.inthis.setState({ editingData: clone })
  }

  fetchData () {
    apiget(global.rxu.config.api_dashboard, {}, { 1: (json) => { this.setState({ dashboard: json.data }, () => {}) } })
  }

  fetchStudio () {
    apiget(global.rxu.config.api_studio, {}, { 1: (json) => { this.setState({ studio: json.data }) } })
  }

  fetchAdmod () {
    apiget(global.rxu.config.api_admod, {}, { 1: (json) => { this.setState({ admod: json.data }) } })
  }

  fetchGgAds () {
    apiget(global.rxu.config.api_ggads, {}, { 1: (json) => { this.setState({ arrGgAds: json.data }) } })
  }

  fetchAccount () {
    apiget(global.rxu.config.api_account, {}, { 1: (json) => { this.setState({ account: json.data }) } })
  }


  onClickCatItem (e) {
    this.setState({ flagUpdateCat: !this.state.flagUpdateCat, curCat: e._id })
  }

  // H E L P E R S
  helpProductcat (id) {
    let result = ''
    let accounts = this.state.account || []
    if (accounts) {
      for (let i = 0; i < accounts.length; i++) { if (accounts[i]._id === id) { result = accounts[i].name } }
      return result
    }
  }
  

  runUpdateCat (e) {
    const paging = (this.state.curCat) ? { searchid_appdist: this.state.curCat, st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 } : { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 }
    e.inthis.setState({ paging: paging }, () => { e.inthis.fetchData() })
  }

  // R E N D E R S
  render () { return this.renderComponent() }
}

export default gameComponent
