import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { activateGeod, closeGeod, rxnavClose } from './redux'
import { rxgetExchangeRate } from './classes/request'

import Main from './main'

const Header = global.rootRequiretheme('header').default
const Footer = global.rootRequiretheme('footer').default

class App extends Component {
  componentDidMount () {
	console.log('--- App componentDidMount')
	console.log(global.rxu.config.api_setting)
	rxgetExchangeRate(true, (value) => {
		console.log('--- abc', value)
	})
	//apiget(global.rxu.config.api_home, {}, {
	//	1: (json) => { console.log('--- Exchange Rate', json) }
	//  })
	// Load exchange rate

    this.unlisten = this.props.history.listen((location, action) => {
      global.scrolltop()
      setTimeout(() => { global.onResizeWindow() }, 50)
    })
  }

  componentWillUnmount () {
    this.unlisten()
  }

  render () {
    const linkCss = '/static/css/main_' + global.currentTheme + '.css'
    return (
      <div>
        <link rel='stylesheet' href={linkCss} />
        <Header staticContext={this.props.staticContext} />
        <Main />
        <Footer />
      </div>
    )
  }
}

// AppContainer.js
const mapStateToProps = (state, ownProps) => ({
  geod: state.geod
})

const mapDispatchToProps = {
  activateGeod,
  closeGeod,
  rxnavClose
}

const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(App)

export default withRouter(AppContainer)
