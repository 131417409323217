/* global CustomEvent */
// import * as ramda from 'ramda', // global.rxi = ramda
import jQuery from 'jquery'
import sweetAlert from 'sweetalert2'
import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

let chartist = {}
if (typeof window !== 'undefined') { chartist = require('chartist') }

const rxu = require('./classes/ulti').default
const translate = require('./classes/lang').default

if (!global.defined) {
  const secret = rxu.secret
  const theme = secret.theme || 'default'

  global.defined = true
  global.rxu = rxu
  global.currentTheme = theme
  global.$ = jQuery
  global.t = translate
  global.sweetAlert = sweetAlert
  global.Chart = Chart

  global.isclient = typeof window !== 'undefined'
  global.rootbase = __dirname
  global.nodebase = global.rootbase + '../node_modules/'
  global.rootRequire = function (name) {
    // eslint-disable-next-line
    return require(__dirname + '/' + name)
  }

  global.rootRequiretheme = function (name) {
    // eslint-disable-next-line
    return require(__dirname + '/themes/' + theme + '/' + name)
  }

  // global ulti
  global.Chart.defaults.LineWithLine = global.Chart.defaults.line
  global.Chart.controllers.LineWithLine = global.Chart.controllers.line.extend({
    draw: function (ease) {
      global.Chart.controllers.line.prototype.draw.call(this, ease)

      if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
        var activePoint = this.chart.tooltip._active[0]
        var ctx = this.chart.ctx
        var x = activePoint.tooltipPosition().x
        var topY = this.chart.legend.bottom
        var bottomY = this.chart.chartArea.bottom

        ctx.save()
        ctx.beginPath()
        ctx.moveTo(x, topY)
        ctx.lineTo(x, bottomY)
        ctx.lineWidth = 1
        ctx.strokeStyle = '#f62459'
        ctx.stroke()
        ctx.restore()
      }
    }
  })
  global.Chart.plugins.unregister(ChartDataLabels)
  global.Chart.Legend.prototype.afterFit = function () { this.height = this.height + 20 }
  global.Chart.helpers.merge(global.Chart.defaults.global, { layout: { padding: { top: 32 } } })
  global.Chart.helpers.merge(global.Chart.defaults.global.plugins.datalabels, { align: 'end', anchor: 'end', color: '#000000', font: { size: 14, weight: 'bold' } })
  global.rootContext = function (context) {
    if (typeof window !== 'undefined') {
      context = context || window.__STATIC_CONTEXT__
    }
    return context
  }

  global.JSONToCSVConvertor = function (JSONData, ReportTitle, ShowLabel = true) {
    const arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
    let CSV = ''; CSV += ReportTitle + '\r\n\n'
    if (ShowLabel) { let row = ''; for (const index in arrData[0]) { row += index + ',' }; row = row.slice(0, -1); CSV += row + '\r\n' }
    for (let i = 0; i < arrData.length; i++) { let row = ''; for (const index in arrData[i]) { row += '"' + arrData[i][index] + '",' }; row.slice(0, row.length - 1); CSV += row + '\r\n' } if (CSV === '') { return }
    let fileName = 'Report_'; fileName += ReportTitle.replace(/ /g, '_'); const uri = 'data:text/csv;charset=utf-8,' + escape(CSV); const link = document.createElement('a')
    link.href = uri; link.style = 'visibility:hidden'; link.download = fileName + '.csv'
    document.body.appendChild(link); link.click(); document.body.removeChild(link)
  }

  // Jquery addon and jquery global stuff
  const $ = global.$
  global.scrolltop = function () {
    $('html, body').animate({ scrollTop: 0 }, 'fast')
  }
  global.scrollfire = function () {
    window.dispatchEvent(new CustomEvent('scroll'))
  }

  // new chartist.Line('#chart1', { labels: [1, 2, 3, 4], series: [[100, 120, 180, 200]] })
  global.chart = chartist
  global.chartist = function (ele, options, options1, options2) {
    return new chartist.Line(ele, options, options1, options2)
  }

  global.chartistBar = function (ele, options, options1, options2) {
    return new chartist.Bar(ele, options, options1, options2)
  }

  global.chartistPie = function (ele, options, options1, options2) {
    return new chartist.Pie(ele, options, options1, options2)
  }

  global.global_init = global.global_init || 'notyet'
  if (global.global_init === 'notyet' && typeof window !== 'undefined') {
    // Default worker
    $('img').on('error', function () {
      $(this).attr('src', '/images/missing.png')
    })

    // Sidebar navigator
    setTimeout(() => {
      $('.adnav__itemname.rxactive').parent('.adnav__groupwrap').addClass('rxactive')
      $('.adnav__itemname.rxactive').parent('.adnav__groupwrap').prev('.adnav__groupname').addClass('adnav__groupname--expand')
    }, 1000)

    // Plugin
    ;(function ($) {
      let uniqueCntr = 0
      $.fn.scrolled = function (waitTime, fn) {
        if (typeof waitTime === 'function') {
          fn = waitTime
          waitTime = 20
        }
        var tag = 'scrollTimer' + uniqueCntr++
        this.scroll(function () {
          var self = $(this)
          var timer = self.data(tag)
          if (timer) {
            clearTimeout(timer)
          }
          timer = setTimeout(function () {
            self.removeData(tag)
            fn.call(self[0])
          }, waitTime)
          self.data(tag, timer)
        })
      }
    })($)

    global.onResizeWindow = function () { $('.rxwindowheight').css({ height: $(window).height() + 'px' }) }

    $(document).click(function (event) {
      if ($(event.target).hasClass('adminnav__avatarmenu rxactive') ||
          $(event.target).parents('.adminnav__avatarmenu.rxactive').length > 0) {
      } else {
        $(document).find('.adminnav__avatarmenu.rxactive').removeClass('rxactive')
      }

      if ($(event.target).hasClass('showFilterBtn') ||
          $(event.target).hasClass('showFilter rxactive') ||
          $(event.target).parents('.showFilter.rxactive').length > 0) {
      } else {
        $(document).find('.showFilter.rxactive').removeClass('rxactive')
      }
      if ($(event.target).hasClass('noteForGameBtn') ||
          $(event.target).hasClass('noteForGame rxactive') ||
          $(event.target).parents('.noteForGame.rxactive').length > 0) {
      } else {
        $(document).find('.noteForGame.rxactive').removeClass('rxactive')
      }
    })

    $(document).on('click', '.showFilterBtn', function () {
      $('.showFilter').toggleClass('rxactive')
    })

    $(document).on('click', '.noteForGameBtn', function () {
      $('.noteForGame').toggleClass('rxactive')
    })

    $(window).on('load', global.onResizeWindow)
    $(window).resize(global.onResizeWindow)
    $(window).scrolled(function () {
      // Stick top
      const sticky = $('.rx-sticky')
      const scroll = $(window).scrollTop()
      if (scroll >= 220) {
        $('.rx-sticky').each(function (i) {
          const ele = $(this)
          ele.addClass('fixed')
          ele.outerWidth(ele.parent().width())
        })
      } else {
        sticky.removeClass('fixed')
        sticky.outerWidth('')
      }

      // Stick bot
      $('.rx-stickybot').each(function (i) {
        const ele = $(this)
        ele.addClass('fixedbot')
        ele.outerWidth(ele.parent().width())
      })

      if (typeof sticky.offset() !== 'undefined') {
        const stickyBottom = sticky.offset().top + sticky.outerHeight(true) + 100
        sticky.find('.product-content-nav-item').each(function () {
          const ele = $(this)
          const theClass = ele.attr('class').match(/rxid[\w-]*\b/)[0]

          // Check what section currently
          const block = $('#' + theClass.replace('rxid', ''))
          if (typeof block.offset() !== 'undefined' && stickyBottom <= block.offset().top + block.outerHeight(true) && stickyBottom >= block.offset().top) {
            $('.product-content-nav-item').removeClass('rx-active')
            $('.' + theClass).addClass('rx-active')
          }
        })
      }
    })
  }
}

export default {}
