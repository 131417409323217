import React from 'react'
import { Link } from 'react-router-dom'

const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const RxCrud = global.rootRequire('components/shares/rxCrud').default
const RxUpload = global.rootRequire('components/shares/rxUpload').default
const RxSelectbox = global.rootRequire('components/shares/rxSelectbox').default
const RxToggle = global.rootRequire('components/shares/rxToggle').default

// R E N D E R S
export const renderCatHead = (inthis, vm) =>
  <tr>
    <th className={inthis.helpSortClass('name')} onClick={(e) => inthis.onClickSort(e, 'name')}>Name</th>
    <th className='rxwidth--100'>Action</th>

  </tr>

export const renderCatBody = (inthis, vm) => {
  const tempData = global.rxu.array(inthis.state.data).slice(0)
  tempData.unshift({ _id: 0, name: 'All dashboard' })
  return tempData.map((perdata, index) => (
    <tr key={perdata._id} onClick={(e) => inthis.run('onClickCatItem', perdata)}><td>{perdata.name}</td>
      {perdata._id !== 0 ? <td>
        {inthis.state.paging.search_is_deleted !== 1 &&
          <div> <span className='betable__btnedit' onClick={(e) => inthis.onClickDataEdit(e, perdata)}><i className='icon-pencil' /></span>
            <span className='betable__btndelete' onClick={(e) => inthis.onClickDataDelete(e, perdata)}><i className='icon-close' /></span>
          </div>}
        {inthis.state.paging.search_is_deleted === 1 &&
          <div> <span className='betable__restore' onClick={(e) => inthis.onClickDataRestore(e, perdata)}>Restore</span></div>}
      </td> : <td />}
    </tr>))
}

export const renderTableHead = (context, vm) =>
  <tr>
    <th className='rxwidth--100'>Icon</th>
    <th className={context.helpSortClass('name', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'name')}>Name</th>
    <th className={context.helpSortClass('created_at', ['rxwidth--120'])} onClick={(e) => context.onClickSort(e, 'created_at')}>Created at</th>
    <th className='rxwidth--100'>Action</th>
  </tr>

export const renderTableBody = (context, vm) => global.rxu.array(context.state.data).map(perdata =>
  <tr key={perdata._id}>
    <td><img className='betable__img' alt={perdata.name} src={global.rxu.config.base_api + '/upload/image/' + (perdata.img_landscape || 'ico_app_default.jpg')} /></td>
    <td className='betable__gamelink'><Link to={`/admin/dashboard/${perdata._id || 123}`}>{perdata.name}</Link></td>
    <td><small>{global.rxu.date(perdata.created_at)}</small></td>
    {context.renderTableBodyBtns(context, perdata)}

  </tr>)

export const renderComponent = (vm) =>
  <AdminBlock name='Game' desc='Manage game'>
    <div className='rxcol-35'>
      <RxCrud
        onClickDataNew={vm.onClickDataNew} renderTableHead={vm.renderTableHead} renderTableBody={vm.renderTableBody} name='game' api='api_game' form={[
          { name: 'Picture', func: (inthis) => (<RxUpload callback={(e) => inthis.callbackUpload(e)} images={inthis.state.editingData.img_landscape} />) },
          { type: 'devide' },
          {
            name: 'Platform',
            func: (inthis) => (<div className='rxradio' onChange={(e) => inthis.onChangeValue(e, 'platform')}>
              <input type='radio' value={0} checked={Number(inthis.state.editingData.platform) === 0} onChange={(e) => inthis.onChangeValue(e, 'platform')} />Android
              <input type='radio' value={1} checked={Number(inthis.state.editingData.platform) === 1} onChange={(e) => inthis.onChangeValue(e, 'platform')} />iOS
            </div>)
          },
          { type: 'devide' },
          { name: 'Application URL On Store', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.appUrlOnStore} onChange={(e) => inthis.onBlurData(e, 'appUrlOnStore')} className='fullwidth-input' />) },

          { type: 'devide' },
          { name: 'Name', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.name} onChange={(e) => inthis.onBlurData(e, 'name')} className='fullwidth-input' />) },

          { type: 'devide' },
          { name: 'gameId', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.gameId} onChange={(e) => inthis.onBlurData(e, 'gameId')} className='fullwidth-input' />) },
          // { name: 'Game Id', func: (inthis) => (<RxSelectbox options={vm.state.gameId} results={inthis.state.editingData.gameId || ''} onChange={(result) => { inthis.onBlurDataValue(result, 'gameId') }} />) },
          { type: 'devide' },
          { name: 'Game FB ID', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.gameFbId} onChange={(e) => inthis.onBlurData(e, 'gameFbId')} className='fullwidth-input' />) },
          { type: 'devide' },
          { name: 'Game GG ID', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.gameGgId} onChange={(e) => inthis.onBlurData(e, 'gameGgId')} className='fullwidth-input' />) },
          { type: 'devide' },
          { name: 'Package Name', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.packageName} onChange={(e) => inthis.onBlurData(e, 'packageName')} className='fullwidth-input' />) },

          { type: 'devide' },
          { name: 'Categories', func: (inthis) => (<RxSelectbox options={vm.state.categories} results={inthis.state.editingData.categories || ''} onChange={(result) => { inthis.onBlurDataValue(result, 'categories') }} />) },

          { type: 'devide' },
          { name: 'packageBundle', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.packageBundle} onChange={(e) => inthis.onBlurData(e, 'packageBundle')} className='fullwidth-input' />) },

          { type: 'devide' },
          {
            name: 'storeStatus',
            func: (inthis) => (<div className='rxradio' onChange={(e) => inthis.onChangeValue(e, 'storeStatus')}>
              <input type='radio' value={0} checked={Number(inthis.state.editingData.storeStatus) === 0} onChange={(e) => inthis.onChangeValue(e, 'storeStatus')} />Unpublished
              <input type='radio' value={1} checked={Number(inthis.state.editingData.storeStatus) === 1} onChange={(e) => inthis.onChangeValue(e, 'storeStatus')} />Published
            </div>)
          },

          { type: 'devide' },
          {
            name: 'Status',
            func: (inthis) => (<div className='rxradio' onChange={(e) => inthis.onChangeValue(e, 'status')}>
              <input type='radio' value={0} checked={Number(inthis.state.editingData.status) === 0} onChange={(e) => inthis.onChangeValue(e, 'status')} />Activated
              <input type='radio' value={1} checked={Number(inthis.state.editingData.status) === 1} onChange={(e) => inthis.onChangeValue(e, 'status')} />Archived
            </div>)
          },
          { type: 'devide' },
          {
            name: 'bigQueryCrawlEnabled',
            func: (inthis) => (<div className='rxradio' onChange={(e) => inthis.onChangeValue(e, 'bigQueryCrawlEnabled')}>
              <input type='radio' value={0} checked={Number(inthis.state.editingData.bigQueryCrawlEnabled) === 0} onChange={(e) => inthis.onChangeValue(e, 'bigQueryCrawlEnabled')} />Off
              <input type='radio' value={1} checked={Number(inthis.state.editingData.bigQueryCrawlEnabled) === 1} onChange={(e) => inthis.onChangeValue(e, 'bigQueryCrawlEnabled')} />On
            </div>)
          },
          { type: 'devide' },
          { name: 'Facebook App ID', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.faceAppId} onChange={(e) => inthis.onBlurData(e, 'faceAppId')} className='fullwidth-input' />) },

          { type: 'devide' },
          { name: 'Facebook App Secret', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.faceAppSecret} onChange={(e) => inthis.onBlurData(e, 'faceAppSecret')} className='fullwidth-input' />) },

          { type: 'devide' },
          { name: 'Google Bigquery Service Account', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.googleBigquery} onChange={(e) => inthis.onBlurData(e, 'googleBigquery')} className='fullwidth-input' />) },

          { type: 'devide' },
          { name: 'Google Iap Service Account', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.googleIap} onChange={(e) => inthis.onBlurData(e, 'googleIap')} className='fullwidth-input' />) },

          { type: 'devide' },
          { name: 'Develop Bucket Id', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.developBucketId} onChange={(e) => inthis.onBlurData(e, 'developBucketId')} className='fullwidth-input' />) },

          { type: 'devide' },
          { name: 'Fcm Service Account (privateKey Json)', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.fcmService} onChange={(e) => inthis.onBlurData(e, 'fcmService')} className='fullwidth-input' />) },

          { type: 'devide' },
          { name: 'Dashboard name', func: (inthis) => (<RxSelectbox options={vm.state.dashboard} results={inthis.state.editingData.dashboard || ''} onChange={(result) => { inthis.onBlurDataValue(result, 'dashboard') }} />) }

        ]}
      />
    </div>
    <div className='rxcol-65'>
      <RxCrud
        renderTableHead={vm.renderCatHead} dataCallback={vm.fetchData} renderTableBody={vm.renderCatBody} onClickCatItem={vm.onClickCatItem} name='dashboard' api='api_dashboard' form={[
          { name: 'Dashboard name', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.name} onChange={(e) => inthis.onBlurData(e, 'name')} className='fullwidth-input' />) },
          { name: 'Desc', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.desc} onChange={(e) => inthis.onBlurData(e, 'desc')} className='fullwidth-input' />) },
          { name: 'Options', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.options} onChange={(e) => inthis.onBlurData(e, 'options')} className='fullwidth-input' />) },
          { type: 'devide' },
          { name: 'Status', func: (inthis) => (<RxToggle value={inthis.state.editingData.is_active} onToggle={(newValue) => inthis.onBlurDataValue(newValue, 'is_active')} />) }

          // [[RX_WEB_DATA_CUSTOM]] //

          // [[RX_WEB_DATA_CUSTOM_END]] //

        ]}
      />
    </div>
  </AdminBlock>
