import React, { Component } from 'react'
const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const RxCrud = global.rootRequire('components/shares/rxCrud').default
const RxToggle = global.rootRequire('components/shares/rxToggle').default

class productComponent extends Component {
  componentDidMount () {
  }

  // R E N D E R S
  renderTableHead (inthis) {
    return (<tr>
      <th className={inthis.helpSortClass('phone', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'phone')}>Phone</th>
      <th className={inthis.helpSortClass('address', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'address')}>Address</th>
      <th className={inthis.helpSortClass('order', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'order')}>Order</th>
      <th className='rxwidth--100'>Status</th>
      <th className={inthis.helpSortClass('created_at', ['rxwidth--120'])} onClick={(e) => inthis.onClickSort(e, 'created_at')}>Created at</th>
      <th className='rxwidth--100'>Action</th>
    </tr>)
  }

  renderTableBody (inthis) {
    return global.rxu.array(inthis.state.data).map(perdata => (<tr key={perdata._id}>
      <td>{perdata.phone}</td>
      <td>{perdata.address}</td>
      <td className='ordertable__detail'>{(perdata.order && Object.keys(perdata.order).length) ? Object.keys(perdata.order).map((i) => {
        const cartItem = perdata.order[i]
        return (cartItem.data) ? (<div key={cartItem.data._id}>{cartItem.data.name}  <b>{cartItem.amount + ' X ' + cartItem.data.price + ' = ' + cartItem.amount * cartItem.data.price}</b></div>) : <div key={i} />
      }) : <span />}
      </td>
      <td>{perdata.status}</td>
      <td><small>{global.rxu.date(perdata.created_at)}</small></td>
      {inthis.renderTableBodyBtns(inthis, perdata)}
    </tr>))
  }

  render () {
    return (
      <AdminBlock name='Order' desc='Manage orders'>
        <div className='rxcol-100'>
          <RxCrud
            renderTableHead={this.renderTableHead} renderTableBody={this.renderTableBody} name='review' api='api_order' form={[
            // [[RX_WEB_DATA_CUSTOM]] //
            // [[RX_WEB_DATA_CUSTOM_END]] //
              { name: 'State', func: (inthis) => (<RxToggle value={inthis.state.editingData.state} onToggle={(newValue) => inthis.onBlurDataValue(newValue, 'state')} />) },
              { type: 'devide' },
              { name: 'Hot', func: (inthis) => (<RxToggle value={inthis.state.editingData.is_hot} onToggle={(newValue) => inthis.onBlurDataValue(newValue, 'is_hot')} />) },
              { type: 'devide' },
              { name: 'Status', func: (inthis) => (<RxToggle value={inthis.state.editingData.is_active} onToggle={(newValue) => inthis.onBlurDataValue(newValue, 'is_active')} />) }
            ]}
          />
        </div>
      </AdminBlock>
    )
  }
}

export default productComponent
