import React, { Component } from 'react'
import { connect } from 'react-redux'

const { apiget } = global.rootRequire('classes/request')
const { cartClear, cartAdd, rxsearchClear } = global.rootRequire('redux')

class ProductSingle_ extends Component {
  constructor (props) {
    super(props)

    this.maxPrice = 10000
    this.minPrice = 100

    this.timeout = null
    this.state = global.rootContext(this.props.staticContext) || {}
  }

  componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.match.params.slug !== this.state.slug) {
      const slug = nextProps.match.params.slug
      const oldslug = this.state.slug
      this.setState({ slug: slug }, () => {
        // When new slug come, not from server
        if (oldslug) { this.fetchData() }
      })
    }
  }

  componentDidMount () {
    this.initData()
  }

  initData () {
    global.scrolltop()
    if (!this.state.products) { this.fetchData() }
  }

  fetchData () {
    apiget(global.rxu.config.api_site_product, { slug: this.state.slug }, {
      1: (json) => {
        this.setState({ products: json.data }, () => {})
      }
    })
  }

  render () {
    return (<div />)
  }
}

const mapStateToProps = (state, ownProps) => ({
  cart: state.cart,
  rxsearch: state.rxSearch
})

const mapDispatchToProps = {
  cartClear,
  cartAdd,
  rxsearchClear
}

const ProductSingle = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSingle_)

export default ProductSingle
