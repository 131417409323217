/* global $ */
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import '../game/dashboard.css'
// import { MultiSelect } from 'react-multi-select-component'
// import MultiSelect from 'react-multi-select-component'

import { DateRangePicker } from 'react-date-range'
 
import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
const { apiget } = global.rootRequire('classes/request')
const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const ulti = require('../game/dashboardUlti').default

const rxu = global.rxu

class studioDashboardComponent extends Component {
  constructor (props) {
    super(props)
    this.toDate = new Date()
    this.toDate7Ago = new Date()
    this.toDate7Ago.setDate(this.toDate7Ago.getDate() - 6)

    this.toDate15Ago = new Date()
    this.toDate15Ago.setDate(this.toDate15Ago.getDate() - 14)

    this.toDate30Ago = new Date()
    this.toDate30Ago.setDate(this.toDate30Ago.getDate() - 29)

    this.toDate90Ago = new Date()
    this.toDate90Ago.setDate(this.toDate30Ago.getDate() - 89)

    this.typeDashboard = [{ _id: 0, name: 'Default', api: 'api_overview' }, { _id: 1, name: 'Custom', api: 'api_overview' }]
    this.state = {
      data: [],
      id: rxu.get(this.props, 'match.params.id'),
      selectionRange: {
        startDate: this.toDate30Ago,
        endDate: this.toDate,
        key: 'selection'
      },
      selectedNation: [],
      api_dashboard: 'api_report_account',
      is_active: 0,
      flags: {},
      loading: false
    }
  }

  run (name, params) {
    if (params) { params.inthis = this } else { params = this }
    if (typeof this.props[name] !== 'undefined' && typeof this.props[name] === 'function') {
      return this.props[name](params)
    } else if (typeof this[name] !== 'undefined' && typeof this[name] === 'function') {
      return this[name]()
    }
  }

  toggle (flagname) {
    const flags = this.state.flags
    flags[flagname] = flags[flagname] ? 0 : 1
    this.setState({ flags: flags })
  }

  flag (flagname) {
    return rxu.get(this.state, ['flags', flagname], 0)
  }

  handleSelect (ranges) {
    this.clearCanvasDom()

    // if (ranges && ranges.selection) {
    //   if (ranges.selection.startDate !== ranges.selection.endDate) {
    //     ranges.selection.startDate.setDate(ranges.selection.startDate.getDate() - 1)
    //     ranges.selection.endDate.setDate(ranges.selection.endDate.getDate() + 1)
    //   }
    // }
    
    this.setState({
      selectionRange: ranges.selection
    }, () => {
      this.fetchData()
    })
  }

  componentDidMount () {
    if ($(window).width() > 1000) {
      this.isFullview = true
      $('.beside').addClass('rxsideactive')
      $('.bemain').addClass('rxsideactive')
      $('.adtopbar__togglesidebar').addClass('rxsideactive')
      $('.adtopbar__logo').addClass('rxsideactive')
    }
    this.fetchData()
  }

  componentWillUnmount () {
    if ($(window).width() > 1000 && this.isFullview) {
      $('.beside').removeClass('rxsideactive')
      $('.bemain').removeClass('rxsideactive')
      $('.adtopbar__togglesidebar').removeClass('rxsideactive')
      $('.adtopbar__logo').removeClass('rxsideactive')
    }
  }

  static getDerivedStateFromProps (props, state) {
    const id = rxu.get(props, 'match.params.id')
    const idOld = state.id

    const navigated = id !== idOld
    if (navigated) {
      return { idOld, id }
    }
    return null
  }

  fetchData () {
    this.setState({ loading: true }, () => {
      const api_dashboard = this.state.api_dashboard
      console.log(api_dashboard)
      apiget(rxu.get(rxu.config, api_dashboard), {
        ...this.state.paging,
        id: this.state.id,
        startDate: this.state.selectionRange.startDate,
        endDate: this.state.selectionRange.endDate,
        nations: rxu.array(this.state.selectedNation.map(e => e.value)).join(',')
      }, {
        1: (json) => {
          this.setState({ data: json.data, loading: false }, () => { this.initCharAll(json.data.data) })
        }
      })
    })
  }

  initCharAll (data) {
    $('#chartShow').html('')
    for (const i in data) {
      const curData = data[i]
      this.initChart(i, curData)
    }
  }

  initCharBlock (item, index, color) {
    return {
      lineTension: 0,
      data: item,
      label: index,
      backgroundColor: global.rxu.strRgbaAddAlpha(color, 1),
      borderColor: color,
      borderWidth: 2.5,
      pointBackgroundColor: global.rxu.strRgbaAddAlpha(color, 1),
      pointBorderWidth: 1 // pointStyle: 'circle'
    }
  }

  initCharOption (options) {
    return {
      elements: {
        point: {
          radius: 2
        }
      },
      maintainAspectRatio: false,
      title: { display: false },
      tooltips: {
        position: 'nearest',
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function (tooltipItem, chart) {
            var dataLabel = `${chart.datasets[tooltipItem.datasetIndex].label}` || ''
            const returnLabel = options?.format ? (dataLabel + ': ' + ulti.number_format_(tooltipItem.yLabel, 2)) : (dataLabel + ': ' + ulti.number_format(tooltipItem.yLabel, 2))

            var corporation = chart.datasets[tooltipItem.datasetIndex].label
            var valor = chart.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]

            var total = 0
            for (var i = 0; i < chart.datasets.length; i++) { total += chart.datasets[i].data[tooltipItem.index] }

            if (tooltipItem.datasetIndex !== chart.datasets.length - 1) {
              return returnLabel
            } else {
                return options?.format ? [corporation + ': ' + ulti.number_format_(valor, 2)] : options?.hasTotal ? [corporation + ': ' + ulti.number_format(valor, 2), 'Total : ' + ulti.number_format(total, 2)] : [corporation + ': ' + ulti.number_format(valor, 2)]
             }
          }

        }
      },
      legend: {
        display: true,
        position: 'top',
        labels: { pointBorderWidth: 0, boxWidth: 2, boxHeight: 2, marginBottom: 0, fontSize: 12, usePointStyle: false, marginRight: 15 },
        onClick: function (e, legendItem) {
          var index = legendItem.datasetIndex
          var ci = this.chart
          var alreadyHidden = (ci.getDatasetMeta(index).hidden === null) ? false : ci.getDatasetMeta(index).hidden
          ci.data.datasets.forEach(function (e, i) {
            var meta = ci.getDatasetMeta(i)
            if (i !== index) {
              if (!alreadyHidden) {
                meta.hidden = meta.hidden === null ? !meta.hidden : null
              } else if (meta.hidden === null) {
                meta.hidden = true
              }
            } else if (i === index) {
              meta.hidden = null
            }
          })
          ci.update()
        }
      },
      hover: { mode: 'nearest', intersect: true },
      interaction: { mode: 'nearest', intersect: true },
      scales: {
        xAxes: [{ ticks: { fontColor: 'rgba(150,150,150,0.7)', minRotation: 30, autoSkip: true, maxTicksLimit: 15 }, display: true, gridLines: { display: false }, stacked: options.stacked || false }],
        yAxes: [{ ticks: { callback: function (value) { return ulti.number_format(value) }, fontColor: 'rgba(0,0,0,0.8)', autoSkip: true, maxTicksLimit: 10 }, display: true, gridLines: { display: true, color: 'rgba(150,150,200,0.2)', lineWidth: 0.5 }, stacked: options.stacked || false }]
      }
    }
  }

  initChart (id, data) {
    // Simple cook data
    const options = this.initCharOption(data.options || {})
    const chartMap = {}
    const chartData = data.data
    const labelArray = data.label
    const chartOptions = data.options || {}
    if (typeof chartData.forEach !== 'undefined') {
      chartData.forEach((item, index) => {
        chartMap[item.label] = item.data
      })
    }

    // Makeup dataset
    const chartDataset = []
    Object.keys(chartMap).forEach((index) => {
      const item = chartMap[index]
      const currentColor = ulti.strToRgb(index || '')
      const currentFilltype = (chartOptions.type === 'line' ? { fill: false } : { fill: true })
      chartDataset.push({ ...this.initCharBlock(item, index, currentColor), ...currentFilltype })
    })

    const labelId = `chart_${id}`
    if (typeof (this[labelId]) !== 'undefined') { this[labelId].destroy() }
    $('#chartShow').append(`
    <div class='${chartOptions.class}'>
      <div class='chart__item'>
        <div class='chart__title'> ${data.name} </div>
        <div class='chart__content'><canvas id='${labelId}' width='800' height='800' /></div>
      </div>
    </div>`).ready(function () {
      const ctx = document.getElementById(labelId).getContext('2d')

      let chartType = chartOptions.type || 'line'
      chartType = (chartType === 'line') ? 'LineWithLine' : chartType

      const config = {
        type: chartType,
        options: options,
        hover: { mode: 'index', intersect: false },
        data: { labels: labelArray, datasets: chartDataset }
      }
      this[labelId] = new global.Chart(ctx, config)
    })
  }

  handleNationChange (event) {
    this.clearCanvasDom()
    this.setState({ selectedNation: event }, () => {
      this.fetchData()
    })
  }

  backToGame(e) {
    this.clearCanvasDom()
    window.history.back()
  }

  releaseCanvas(labelId) {
    let canvas = document.getElementById(labelId)
    if (canvas) {
      canvas.width = 1
      canvas.height = 1
      const ctx = canvas.getContext('2d')
      ctx && ctx.clearRect(0, 0, 1, 1)
    }
  }

  clearCanvasDom() {
    for (let i = 0; i < 8; i++) {
      let labelId = `chart_${i}`
      this.releaseCanvas(labelId)
      if (typeof (this[labelId]) !== 'undefined') { 
        this[labelId].destroy()
      }
    }
  }

  handleTypeDashboadChange (event) {
    this.clearCanvasDom()
    let api_dashboard = this.state.api_dashboard
    api_dashboard = event === 0 ? 'api_overview' : 'api_overview'
    this.setState({ api_dashboard: api_dashboard, is_active: event }, () => {
      this.fetchData()
    })
  }

  // R E N D E R S
  renderDaterangeSelect () {
    return (
      <select className='chart__select'>
        <option value='1week'> Theo tuần</option>
        <option value='1mouth'> Theo tháng</option>
        <option value='1year'> Theo năm</option>
      </select>
    )
  }

  render () {
    return (
      <div>
      { this.state.loading && <div className='bg_loading'><div className='rxloading'></div></div> }
      <AdminBlock name={'Dashboard ' + rxu.get(this.state, 'data.name')} desc={'Dashboard ' + rxu.get(this.state, 'data.desc')} exclass='adsticky'>
        <div className='row'>
          <div className='filtersum__block'>
            <div className='showFilterBtn attop icon-magnifier-add' />
            <div className='filtersum__nation'><b>Nation</b> {rxu.array(this.state.selectedNation.map(e => e.value)).join(',') || 'All'}</div>
            <div className='filtersum__date'><b>Date</b> {this.state.selectionRange.startDate.toLocaleDateString('vi-VN')} - {this.state.selectionRange.endDate.toLocaleDateString('vi-VN')} </div>
          </div>
        </div>
        <div className='row' id='chartShow' />
        <div className='showFilterBtn icon-magnifier-add' />
        <div className='showFilter'>
          <div className='filterWrap'>
            <div className='filterLabel'>Type Dashboard</div>
            <div className='tabSelect'>
              <div className='tabSelectLabel'><span className={Number(this.state.is_active) === 0 ? 'tabSelectItem tabSelectItemActive' : 'tabSelectItem'} onClick={(e) => this.handleTypeDashboadChange(0)}>Default</span></div>
              <div className='tabSelectLabel'><span className={Number(this.state.is_active) === 1 ? 'tabSelectItem tabSelectItemActive' : 'tabSelectItem'} onClick={(e) => this.handleTypeDashboadChange(1)}>Custom</span></div>
            </div>
            <div className='filterLabel'>Nation</div>
            {/* <MultiSelect options={ulti.countryOptions} value={this.state.selectedNation} onChange={this.handleNationChange.bind(this)} labelledBy='Select' /> */}
            <div className='filterLabel'>Date</div>
            <DateRangePicker
              staticRanges={[{
                ...staticRangeHandler,
                label: 'Last 7 days',
                range: () => ({ startDate: this.toDate7Ago, endDate: this.toDate })
              }, {
                ...staticRangeHandler,
                label: 'Last 15 days',
                range: () => ({ startDate: this.toDate15Ago, endDate: this.toDate })
              }, {
                ...staticRangeHandler,
                label: 'Last 30 days',
                range: () => ({ startDate: this.toDate30Ago, endDate: this.toDate })
              }, {
                ...staticRangeHandler,
                label: 'Last 90 days',
                range: () => ({ startDate: this.toDate90Ago, endDate: this.toDate })
              }]}
              ranges={[this.state.selectionRange]}
              onChange={this.handleSelect.bind(this)}
            />
          </div>
        </div>
        {/* <Link className='backToGame' to='/rbac/studio'>X</Link> */}
        <button className='backToGame' onClick={(e) => this.backToGame()}> x </button>
      </AdminBlock>
      </div>
    )
  }
}

const staticRangeHandler = {
  range: {},
  isSelected (range) {
    const definedRange = this.range()
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    )
  }
}

function isSameDay (date1, date2) {
  try {
    return (date1.toString() === date2.toString())
  } catch (e) {
    return false
  }
}

export default studioDashboardComponent
