// Website - rxshop
// src/classes/ulti.js
import { rxsecret } from './../config/index'
const rxu = require('@rxframework/rxulti')

rxu.navClass = function (route, props, showObj, activeClass) {
  let result = ''
  showObj = showObj || {}
  const showPermission = showObj.showPermission || ''
  const showClass = showObj.showClass || ' rxshow '
  const hideClass = showObj.hideClass || ' rxhide '

  if (props.location) {
    const currentLocation = props.location.pathname || ''
    activeClass = activeClass || ' rxactive '

    if (currentLocation === route) {
      result += ' ' + activeClass
    }

    if (rxu.get(props, 'auth.user.arrper', ['']).concat(['']).indexOf(showPermission) !== -1) {
      result += ' ' + showClass
    } else {
      result += ' ' + hideClass
    }
  }

  return result
}

const secret = rxsecret()
export const rxconfig = (function () {
  const config = []

  // Web
  config.web_port = secret.web_port

  // Server
  config.base_api = secret.base_api

  // Authorize
  // Homepage
  config.api_home = config.base_api + '/site'
  config.api_site_product = config.base_api + '/site/product'
  config.api_site_productcate = config.base_api + '/site/productcate'
  config.api_site_order_add = config.base_api + '/site/orderadd'

  // Note
  config.api_note = config.base_api + '/note'

  // Note cate
  config.api_cate = config.base_api + '/cate'

  // Note cate
  config.api_review = config.base_api + '/review'

  config.api_game = config.base_api + '/game'
  config.api_game_list = config.api_game + '/getListGame'
  config.api_game_dashboard = config.api_game + '/dashboard'
  config.api_game_dashboard_custom = config.api_game + '/dashboardCustom'
  config.api_game_edit = config.api_game + '/edit'
  config.api_game_delete = config.api_game + '/delete'
  config.api_game_restore = config.api_game + '/restore'
  config.api_game_detail = config.api_game + '/gameDetail'
  config.api_game_info = config.api_game + '/info'

  config.api_campaign = config.base_api + '/campaign'
  config.api_campaign_list = config.api_campaign + '/getListGame'
  config.api_campaign_dashboard = config.api_campaign + '/dashboard'
  config.api_campaign_dashboard_custom = config.api_campaign + '/dashboardCustom'
  config.api_campaign_edit = config.api_campaign + '/edit'
  config.api_campaign_delete = config.api_campaign + '/delete'
  config.api_campaign_restore = config.api_campaign + '/restore'
  config.api_campaign_detail = config.api_campaign + '/gameDetail'
  config.api_campaign_info = config.api_campaign + '/info'

  config.api_store_performance = config.base_api + '/storePerformance'
  config.api_store_performance_dashboard = config.api_store_performance + '/dashboard'
  config.api_store_performance_dashboard_custom = config.api_store_performance + '/dashboardCustom'
  config.api_store_performance_info = config.api_store_performance + '/info'
  config.api_store_performance_detail = config.api_store_performance + '/storePerformanceDetail'

  config.api_dashboard = config.base_api + '/dashboard'
  config.api_dashboard_edit = config.api_dashboard + '/edit'
  config.api_dashboard_delete = config.api_dashboard + '/delete'
  config.api_dashboard_restore = config.api_dashboard + '/restore'
  config.api_overview = config.api_dashboard + '/overview'
  config.api_report_game = config.api_dashboard + '/reportGame'
  config.api_report_game_detail = config.api_dashboard + '/reportGameDetail'
  config.api_report_overall = config.api_dashboard + '/reportOverall'
  config.api_report_source_rev = config.api_dashboard + '/reportSourceRev'
  config.api_studio = config.base_api + '/studio'
  config.api_studio_edit = config.api_studio + '/edit'
  config.api_studio_delete = config.api_studio + '/delete'
  config.api_studio_restore = config.api_studio + '/restore'

  config.api_admod = config.base_api + '/admod'
  config.api_admod_edit = config.api_admod + '/edit'
  config.api_admod_delete = config.api_admod + '/delete'
  config.api_admod_restore = config.api_admod + '/restore'

  config.api_ggads = config.base_api + '/ggads'
  config.api_ggads_edit = config.api_ggads + '/edit'
  config.api_ggads_delete = config.api_ggads + '/delete'
  config.api_ggads_restore = config.api_ggads + '/restore'

  config.api_account = config.base_api + '/account'
  config.api_account_edit = config.api_account + '/edit'
  config.api_account_delete = config.api_account + '/delete'
  config.api_account_restore = config.api_account + '/restore'

  config.api_account_list = config.api_account + '/getListGame'
  config.api_account_info =  config.api_account + '/info'
  config.api_account_dashboard = config.api_account + '/dashboard'
  config.api_account_dashboard_custom = config.api_account + '/dashboardCustom'

  config.api_report_account = config.api_account + '/reportGame'
  config.api_report_account_detail = config.api_account + '/reportGameDetail'
  config.api_report_account_overall = config.api_account + '/reportOverall'

  // Order
  config.api_order = config.base_api + '/order'
  config.api_productcate = config.base_api + '/productcate'
  config.api_product = config.base_api + '/product'

  config.api_authorize = config.base_api + '/authorize'
  config.api_user = config.base_api + '/user'
  config.api_user_edit = config.api_user + '/edit'
  config.api_user_delete = config.api_user + '/delete'
  config.api_user_restore = config.api_user + '/restore'
  config.api_role = config.base_api + '/role'
  config.api_role_edit = config.api_role + '/edit'
  config.api_role_delete = config.api_role + '/delete'
  config.api_role_restore = config.api_role + '/restore'
  config.api_role_all = config.api_role + '/all'
  config.api_permission = config.base_api + '/permission'
  config.api_permission_edit = config.api_permission + '/edit'
  config.api_permission_delete = config.api_permission + '/delete'
  config.api_permission_restore = config.api_permission + '/restore'
  config.api_permission_all = config.api_permission + '/all'
  config.api_permission_genpermission = config.api_permission + '/generation'

  config.api_setting = config.base_api + '/setting'
  // [[RX_APIS]] //

  return config
}())

rxu.secret = secret
rxu.config = rxconfig
export default rxu
