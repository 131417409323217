import React, { Component } from 'react'

const { apiget, apipost } = global.rootRequire('classes/request')
const RxUpload = global.rootRequire('components/shares/rxUpload').default
const RxToggle = global.rootRequire('components/shares/rxToggle').default

const WAIT_INTERVAL = 500
class roleComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: [],
      paging: { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 },
      editingData: {},
      dataPermission: [],
      permissionArray: [],
      selected_array: [],
      showselected_array: [],
      isPermission: false
    }
  }

  componentDidMount () {
    this._ismounted = true
    this.fetchAlldata()
    this.timerDatafilter = null
  }

  componentWillUnmount () { this._ismounted = false }
  rxsetState (obj) { if (this._ismounted) { this.setState(obj) } }

  fetchAlldata () {
    this.fetchData()
    this.fetchDataPermission()
  }

  fetchData () {
    apiget(global.rxu.config.api_role, this.state.paging, { 1: (json) => { this.rxsetState({ data: json.data }) } })
  }

  fetchDataPermission () {
    apiget(global.rxu.config.api_permission_all, {}, {
      1: (json) => {
        this.permission_array = []

        // Making data
        this.rxsetState({ dataPermission: json.data })
        const perArray = this.groupBy(this.state.dataPermission)
        const arrayParse = this.parseObjToarray(perArray)

        const keys = []
        for (const key in arrayParse) {
          keys.push(arrayParse[key])
        }
        this.rxsetState({ permissionArray: keys })
      }
    })
  }

  // Role //
  onBlurDatafilter (e, name) {
    clearTimeout(this.timerDatafilter)
    const paging = this.state.paging
    paging['search_' + name] = e.target.value
    this.setState({ paging: paging })

    this.timerDatafilter = setTimeout((e, name) => {
      this.fetchData()
    }, WAIT_INTERVAL)
  }

  addPermission () {
    this.setState({ isPermission: true })
    this.checkPermission(this.state.selected_array)
  }

  checkPermission (permissionChecked) {
    const tempPermission = this.state.dataPermission

    if (permissionChecked !== null) {
      for (const keyPer in tempPermission) {
        if (permissionChecked.indexOf(tempPermission[keyPer].id) !== -1) {
          tempPermission[keyPer].checked = true
        }
      }
    }

    this.setState({ dataPermission: tempPermission })
    return this.state.dataPermission
  }

  checkedAll (data) {
    let checkList = true
    for (const key in data.value) {
      if (data.value[key].checked !== true) {
        checkList = checkList && false
      } else {
        checkList = checkList && true
      }
    }
    return checkList
  }

  checkedExist (data) {
    const arrchecked = data.value.map((x) => x.checked)
    if (arrchecked.indexOf(true) === -1) {
      return false
    } else {
      return true
    }
  }

  toggleAll (e, index) {
    const arrpermission = this.state.permissionArray
    const data = arrpermission[index]
    const arrselected = this.state.selected_array
    if (this.checkedAll(data)) {
      for (const key in data.value) {
        const index1 = arrselected.indexOf(data.value[key].id)
        arrselected.splice(index1, 1)
        data.value[key].checked = false
      }
    } else {
      for (const key in data.value) {
        if (arrselected.indexOf(data.value[key].id) === -1) {
          arrselected.push(data.value[key].id)
          data.value[key].checked = true
          arrpermission[index] = data
        }
      }
    }

    this.setState({ permissionArray: arrpermission, selected_array: arrselected })
  }

  toggleOne (e, index, perindex, checked) {
    const arrselected = this.state.selected_array
    const arrpermission = this.state.permissionArray
    const data = this.state.permissionArray[index].value[perindex]
    data.checked = checked
    if (arrselected.indexOf(data.id) === -1) {
      if (checked) {
        arrselected.push(data.id)
      }
    } else {
      if (!checked) {
        const index = arrselected.indexOf(data.id)
        arrselected.splice(index, 1)
      }
    }
    this.setState({ permissionArray: arrpermission, selected_array: arrselected })
  }

  addResetPermission () {
    this.fetchDataPermission()
    this.setState({ selected_array: this.initialPermission(this.state.editingData), editingData: this.state.editingData, isPermission: false })
  }

  addSavePermission () {
    const editingData = this.state.editingData
    this.showPermission(this.state.selected_array)
    editingData.permission = this.state.selected_array
    this.setState({ editingData: editingData, isPermission: false })
  }

  initialPermission (perdata) {
    if (perdata && typeof (perdata.permission) === 'string' && perdata.permission !== null) {
      const array = perdata.permission.split(',').map(Number)
      this.setState({ selected_array: array })
      return array
    } else {
      this.setState({ selected_array: [] })
      return []
    }
  }

  showPermission (data) {
    const dataperSelected = []
    for (const keyPer in this.state.dataPermission) {
      if (data.indexOf(this.state.dataPermission[keyPer].id) !== -1) {
        dataperSelected.push(this.state.dataPermission[keyPer])
      }
    }
    const dataperTmp = this.groupBy(dataperSelected)
    this.showselected_array = this.parseObjToarray(dataperTmp)
    this.setState({ showselected_array: this.showselected_array })
  }

  parseObjToarray (value) {
    if (!value) {
      return value
    }
    const keys = []
    for (const key in value) {
      keys.push({ key: key, value: (value[key]) })
    }
    return keys
  }

  groupBy (permissionArr) {
    const groups = {}
    permissionArr.forEach(function (permission) {
      permission.checked = false
      groups[permission.controller] = groups[permission.controller] || []
      groups[permission.controller].push(permission)
    })
    return groups
  }

  run (name, params) {
    if (params) { params.inthis = this } else { params = this }
    if (typeof this.props[name] !== 'undefined' && typeof this.props[name] === 'function') {
      return this.props[name](params)
    } else if (typeof this[name] !== 'undefined' && typeof this[name] === 'function') {
      return this[name]()
    }
  }

  // C L I C K   E V E N T
  onClickSort (e, stcol) {
    const paging = this.state.paging
    paging.st_type = (paging.st_col !== stcol) ? -1 : (-1 * (paging.st_type))
    paging.st_col = stcol

    this.setState({ paging: paging }, () => { this.fetchData() })
  }

  onClickDataNew (e) {
    const timeStr = Date.now().toString()
    const clone = { name: 'Role_' + timeStr.substr(timeStr.length - 5), desc: '', created_at: 1, is_deleted: 0, is_active: 1, is_hot: 0, price: 100000, app: '', appdist: '' }
    this.setState({ editingData: clone })
  }

  onClickDataEdit (e, perdata) {
    if (typeof perdata.inthis !== 'undefined') { delete perdata.inthis }
    const clone = JSON.parse(JSON.stringify(perdata))
    const initPer = this.initialPermission(clone)
    this.checkPermission(initPer)
    this.showPermission(initPer)
    this.setState({ editingData: clone })
  }

  onClickDataDelete (e, perdata) {
    e.stopPropagation()
    apiget(global.rxu.config.api_role_delete, perdata, {
      1: (json) => { this.fetchData(true) }
    })
  }

  onClickDataRestore (e, perdata) {
    e.stopPropagation()
    apiget(global.rxu.config.api_role_restore, perdata, {
      1: (json) => { this.fetchData(true) }
    })
  }

  onClickDataUpdateSubmit (e, perdata) {
    apipost(global.rxu.config.api_role_edit, this.state.editingData, {
      1: (json) => { this.fetchData(true) }
    })
    this.onClickDataEdit({}, {})
  }

  onClickDataCreateSubmit (e, perdata) {
    apipost(global.rxu.config.api_role, this.state.editingData, {
      1: (json) => { this.fetchData(true) }
    })
    this.onClickDataEdit({}, {})
  }

  onClickDataTrash (e, isdeleted) {
    const paging = this.state.paging
    paging.search_is_deleted = isdeleted
    this.setState({ paging: paging }, () => {
      this.fetchData()
    })
  }

  // B L U R   E V E N T
  onBlurData (e, name) {
    const editingData = this.state.editingData
    editingData[name] = e.target.value
    this.setState({ editingData: editingData })
  }

  onBlurDataValue (value, name) {
    const editingData = this.state.editingData
    editingData[name] = value
    this.setState({ editingData: editingData })
  }

  // P A G I N
  onClickPaginBack (e) {
    const paging = this.state.paging
    paging.pg_page = (paging.pg_page > 1) ? (paging.pg_page - 1) : paging.pg_page
    this.setState({ paging: paging }, () => { this.fetchData() })
  }

  onClickPaginNext (e) {
    const paging = this.state.paging
    paging.pg_page += 1
    this.setState({ paging: paging }, () => { this.fetchData() })
  }

  // H E L P E R S
  helpSortClass (stcol, extraclass) {
    extraclass = extraclass || []

    let result = ''
    if (this.state.paging.st_col === stcol) {
      result = this.state.paging.st_type === 1 ? 'rx-sort-asc' : 'rx-sort-desc'
    }

    for (let i = 0; i < extraclass.length; i++) {
      result += ' ' + extraclass[i]
    }

    return result
  }

  // R E N D E R
  renderForm () {
    const tempform = this.props.form || [
      { name: 'Picture', func: () => (<RxUpload callback={(e) => this.callbackUpload(e)} images={this.state.editingData.img_landscape} />) },

      { type: 'devide' },
      { name: 'Name', func: () => (<input tabIndex='1' type='text' value={this.state.editingData.name} onChange={(e) => this.onBlurData(e, 'name')} className='fullwidth-input' />) },
      { name: 'Desc', func: () => (<input tabIndex='2' type='text' value={this.state.editingData.desc} onChange={(e) => this.onBlurData(e, 'desc')} className='fullwidth-input' />) },

      { type: 'devide' },
      { name: 'Status', func: () => (<RxToggle value={this.state.editingData.is_active} onToggle={(newValue) => this.onBlurDataValue(newValue, 'is_active')} />) },
      { type: 'devide' }]

    if ((this.state.editingData.created_at || this.state.editingData.created_at === 0) && tempform) {
      const form = tempform.map((perdata, index) => {
        perdata.type = perdata.type || 'input'

        let result = (<div />)
        switch (perdata.type) {
          case 'input':
            result = <div key={index}><div className='fullwidth-label'>{perdata.name}</div>{perdata.func(this)}</div>
            break

          case 'fullinput':
            result = <div key={index} className='fullwidth-frominput'><div className='fullwidth-label'>{perdata.name}</div>{perdata.func(this)}</div>
            break

          case 'devide':
            result = <div key={index} className='fullwidth-formdevide cleafix' />
            break

          default:
            result = <div key={index}><div className='fullwidth-label'>{perdata.name}</div>{perdata.func(this)}</div>
            break
        }
        return result
      })
      return form
    }
  }

  renderHead () {
    return (<tr>
      <th className='rxwidth--100'>Picture</th>
      <th className={this.helpSortClass('name', ['rxwidth--220'])} onClick={(e) => this.onClickSort(e, 'name')}>Name</th>
      <th className={this.helpSortClass('desc', ['rxwidth--220'])} onClick={(e) => this.onClickSort(e, 'desc')}>Desc</th>
      <th className={this.helpSortClass('created_at', ['rxwidth--150'])} onClick={(e) => this.onClickSort(e, 'created_at')}>Created at</th>
      <th className='rxwidth--100'>Action</th>
    </tr>)
  }

  renderBody () {
    return this.state.data.map(perdata => (<tr key={perdata._id}>
      <td><img className='betable__img' alt={perdata.name} src={global.rxu.config.base_api + '/upload/image/' + (perdata.img_landscape || 'ico_app_default.jpg')} /></td>
      <td>{perdata.name}</td>
      <td>{perdata.desc}</td>
      <td><small>{global.rxu.date(perdata.created_at)}</small></td>
      <td>
        {this.state.paging.search_is_deleted !== 1 &&
          <div> {(perdata.is_hot) && <span className='betable__icohot'><span className='icon-fire' />Hot</span>}
            {(perdata.is_active !== 0) && <span className='betable__icoon'>On</span>}{(perdata.is_active === 0) && <span className='betable__icooff'>Off</span>}
            <span className='betable__btnedit' onClick={(e) => this.onClickDataEdit(e, perdata)}><i className='icon-pencil' /></span>
            <span className='betable__btndelete' onClick={(e) => this.onClickDataDelete(e, perdata)}><i className='icon-close' /></span>
          </div>}
        {this.state.paging.search_is_deleted === 1 &&
          <div> <span className='betable__restore' onClick={(e) => this.onClickDataRestore(e, perdata)}>Restore</span></div>}
      </td>
    </tr>))
  }

  // R E N D E R S //
  render () {
    const arrayPermission = this.state.permissionArray.map((perdata, index) => (
      <div className='col-sm-4 col-xs-6' key={index}>
        <div className='adcheckper'>
          <div className='adcheckper__head'>
            <div className='adcheckper__checkcontroller' onClick={(e) => this.toggleAll(e, index)}>
              {(this.checkedAll(perdata)) && <div className='adcheckper__checkedall' />}
              {(!this.checkedAll(perdata) && this.checkedExist(perdata)) && <div className='adcheckper__checkedone_notall' />}
            </div>
            <span className='adcheckper__nametext'><b>{perdata.key}</b></span>
          </div>
          <div className='adcheckper__body'>
            {perdata.value.map((permission, perindex) => (
              <div key={permission._id} className='adcheckper__child clearfix'>
                <div className='adcheckper__checkaction' onClick={(e) => this.toggleOne(e, index, perindex, !permission.checked)}>
                  {permission.checked && <div className='adcheckper__checkedone' />}
                </div>
                <span className='adcheckper__nametext'>{permission.action}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    ))

    const arrayShowPermission = this.state.showselected_array.map((permissiongroup, index) => (
      <div className='adperblock__group clearfix' key={index}>
        <div className='adperblock__title'>{permissiongroup.key}</div>
        <div>
          {permissiongroup.value.map((permission, perindex) => (<span className='adperblock__peraction' key={perindex}>{permission.action}</span>))}
        </div>
      </div>
    ))

    return (
      <div className='adblock'>
        <div className='adblock__head'>
          <div className='adblock__title'>role</div>
          <div className='adblock__desc'>role</div>
        </div>
        <div className='adblock__body'>
          <div className='row adblock__inner'>
            <div className='rxcol-100'>
              {(!this.state.editingData.created_at && !this.state.isPermission) && <div className='betable'>
                <span className='betable__filter'>
                  <div className='betable__btns clearfix'>
                    <span className='betable__btnadd btn' onClick={(e) => this.onClickDataNew(e)}><i className='icon-plus betable__addbtn' />Tạo mới</span>
                    {this.state.paging.search_is_deleted !== 1 && <span className='btn--default' onClick={(e) => this.onClickDataTrash(e, 1)}><i className='icon-trash betable__recycle' /></span>}
                    {this.state.paging.search_is_deleted === 1 && <span className='btn--default' onClick={(e) => this.onClickDataTrash(e, 0)}><i className='icon-list betable__recycle' /></span>}
                  </div>
                  <input className='betable__findinput' type='text' placeholder='Tìm kiếm' onKeyUp={(e) => this.onBlurDatafilter(e, 'name')} />
                </span>

                <div className='betable__pagin'>
                  {(this.state.paging.pg_page !== 1) && <div className='betable__paginback' onClick={(e) => this.onClickPaginBack(e)}><i className='icon-arrow-left' /></div>}
                  <div className='betable__pagincurr'>{this.state.paging.pg_page}</div>
                  {(this.state.data.length >= this.state.paging.pg_size) && <div className='betable__paginnext' onClick={(e) => this.onClickPaginNext(e)}><i className='icon-arrow-right' /></div>}
                </div>

                <div className='betable__main'>
                  <table className='betable__inner'>
                    <thead>{this.run('renderHead')}</thead>
                    <tbody>{this.run('renderBody')}</tbody>
                  </table>
                </div>

                <div className='betable__pagin betable__pagin--bot'>
                  {(this.state.paging.pg_page !== 1) && <div className='betable__paginback' onClick={(e) => this.onClickPaginBack(e)}><i className='icon-arrow-left' /></div>}
                  <div className='betable__pagincurr'>{this.state.paging.pg_page}</div>
                  {(this.state.data.length >= this.state.paging.pg_size) && <div className='betable__paginnext' onClick={(e) => this.onClickPaginNext(e)}><i className='icon-arrow-right' /></div>}
                </div>
              </div>}

              {(this.state.editingData.created_at && !this.state.isPermission) && <div className='adform'>
                <div className='adform__name'>Edit object <b>{this.state.editingData.name}</b><div className='adform__close' onClick={(e) => this.onClickDataEdit(e, {})}>x</div></div>
                <div className='adform__body'>
                  {this.run('renderForm')}

                  <div className='adform__btnshowper' onClick={(e) => this.addPermission()}>Edit Permission For Role</div>
                  {this.state.showselected_array.length > 0 && <div className='adperblock'>{arrayShowPermission}</div>}

                  <div className='adform__btns clearfix'>
                    <div tabIndex='10' className='adform__btncancel' onClick={(e) => this.onClickDataEdit(e, {})} onKeyPress={(e) => this.onClickDataEdit(e, {})}>Back</div>
                    {this.state.editingData.created_at !== 1 && <div tabIndex='11' className='adform__btnedit' onClick={(e) => this.onClickDataUpdateSubmit(e)} onKeyPress={(e) => this.onClickDataUpdateSubmit(e)}>Update</div>}
                    <div tabIndex='12' className='adform__btnclone' onClick={(e) => this.onClickDataCreateSubmit(e)} onKeyPress={(e) => this.onClickDataCreateSubmit(e)}>Clone</div>
                  </div>
                </div>
              </div>}

              {this.state.isPermission && <div>
                <div className='row'>{arrayPermission}</div>
                <div className='adform__btns clearfix'>
                  <div tabIndex='10' className='adform__btncancel' onClick={(e) => this.addResetPermission(e)} onKeyPress={(e) => this.addResetPermission(e)}>Cancel</div>
                  <div tabIndex='11' className='adform__btnedit' onClick={(e) => this.addSavePermission(e)} onKeyPress={(e) => this.addSavePermission(e)}>Update</div>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default roleComponent
