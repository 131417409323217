import React, { Component } from 'react'
global.isclient && require('./rxSelectbox.css')

class RxNewSelectbox extends Component {
  constructor (props, context) {
    super(props, context)
    this.state = {
      toggle: false,
      options: this.props.options,
      multi: this.props.multi || true,
      results: this.props.results ? (this.props.results.constructor === Array ? this.props.results : this.props.results.split(',')) : [],
      strsearch: ''
    }
  }

  toggle () {
    // this.setState({toggle: !this.state.toggle})
  }

  onClickItem (e, item) {
    e.stopPropagation()
    if (this.state.results.indexOf(item._id) === -1) {
      let tempResults = this.state.results
      tempResults.unshift(item._id)
      if (!this.props.multi) {
        tempResults = tempResults.slice(0, 1)
      }
      this.setState({ results: tempResults }, () => {
        const tempResults = this.state.results ? (this.state.results.constructor === Array ? this.state.results.join(',') : this.state.results) : ''
        this.props.onChange(tempResults)
      })
    }
  }

  onClickResult (e, item) {
    e.stopPropagation()
    const tempIndex = this.state.results.indexOf(item._id)
    if (tempIndex !== -1) {
      const tempResults = this.state.results
      tempResults.splice(tempIndex, 1)

      this.setState({ results: tempResults }, () => {
        const tempResults = this.state.results ? (this.state.results.constructor === Array ? this.state.results.join(',') : this.state.results) : ''
        this.props.onChange(tempResults)
      })
    }
  }

  rxChangeSlug(x, isNotNull = false) {
    let str = x
    if (typeof (x) !== 'undefined') {
      str = str.toLowerCase()
      str = str.replace(/[`~!@#$%^&*()_|+=?;:'",.<>{}[\]\\/]/gi, '')
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
      str = str.replace(/đ/g, 'd')
      str = str.replace(/ + /g, ' ')
      str = str.replace(/ /g, '-')
      str = str.trim()
    }
    return isNotNull ? (str || x) : str
  }

  onChangeFilter (e) {
    let tempValue = e.target.value
    this.setState({
      strsearch: tempValue
    })
  }

  render () {
    console.log(this.state.toggle)
    let strsearch = this.state.strsearch
    const options = this.props.options.map((item, index) => {
      if (strsearch) {
        console.log(strsearch)
        console.log(this.rxChangeSlug(strsearch))
        console.log(this.rxChangeSlug(item.name))

        return (this.state.results.indexOf(item._id) === -1 && this.rxChangeSlug(item.name).indexOf(this.rxChangeSlug(strsearch)) !== -1 ) ? <div key={index} onClick={(e) => this.onClickItem(e, item)}>{item.name}</div> : <span key={index} />
      } else {
        return this.state.results.indexOf(item._id) === -1 ? <div key={index} onClick={(e) => this.onClickItem(e, item)}>{item.name}</div> : <span key={index} />  
      }
    })
    const results = this.props.options.map((item, index) => (
      this.state.results.indexOf(item._id) !== -1 ? <div key={index} onClick={(e) => this.onClickResult(e, item)}>{item.name} <b>x</b></div> : <span key={index} />
    ))
    return (
      <div className='rxselectbox-wrap'>
        <div className='rxselectbox-result clearfix' onClick={() => this.toggle()}>
          {!this.state.results.length && <span className='rxselectbox-resultempty'>Nhấp vào để chọn</span>} {results}
        </div>
        {!this.state.toggle && <div className='rxnewselectbox-toggle'>
          <div className='rxnewselectbox-filter'> 
            <input type='text' className='' placeholder='Tìm kiếm' onChange={(e) => this.onChangeFilter(e)} /> 
          </div>
          <div className='rxnewselectbox-listitem'>
            {options}
          </div>
        </div>}
      </div>
    )
  }
}
RxNewSelectbox.defaultProps = { onChange: () => {}, options: [], results: [] }
// Options : {key: , text: }
export default RxNewSelectbox
