import React from 'react'
const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const RxCrud = global.rootRequire('components/shares/rxCrud').default

export const renderComponent = (vm) =>
  <AdminBlock name='Notecate' desc='Manage note cate'>
    <div className='rxcol-100'>
      <RxCrud name='Cate' api='api_cate' />
    </div>
  </AdminBlock>
