import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
global.isclient && require('./home.css')

const { apiget } = global.rootRequire('classes/request')
const { cartClear, cartAdd, cartDelete, favoriteAdd, favoriteDelete, rxnavToggle, rxnavClose } = global.rootRequire('redux')

class Component_ extends Component {
  constructor (props) {
    super(props)
    this.state = global.rootContext(this.props.staticContext) || { products: [], reviews: [] }
    this.state.products = this.state.products || []
    this.state.reviews = this.state.reviews || []
  }

  componentDidMount () {
    if (!this.state.products.lenght && !this.state.reviews.lenght) {
      this.fetchData()
    }
  }

  fetchData () {
    apiget(global.rxu.config.api_home, {}, {
      1: (json) => { this.setState({ products: json.data.products, reviews: json.data.reviews }) }
    })
  }

  onClickAddCart (e, product) {
    e.stopPropagation()
    this.props.cartAdd(product._id, { amount: 1, data: product, key: '' })
    this.props.rxnavClose('rxcart', {})
    this.props.rxnavToggle('rxcart', {})
  }

  render () {
    return (<div>
      <div className='slide'>
        <div className='slide__item'>
          <div className='icon-arrow-left nav-icon slide__back' />
          <Link to='/'>
            <img className='slide__itemimg' alt='logoimg' src='/images/static/sli3.jpg' />
            <div className='slide__itemtxt'>
              <div className='container container--relative'>
                <div className='slide__txttitle'>Rxcode Generator</div>
                <div className='slide__txtdesc'>Tiết kiệm thời gian và chi phí</div>
                <img className='slide__itemgif' alt='logoimg' src='/images/static/rocketani.gif' />
              </div>
            </div>
          </Link>
          <div className='icon-arrow-right nav-icon slide__next' />
        </div>
      </div>
      <div className='container block__supportwrap'>
        <div className='container block__support'>
          <div className='col-md-4 col-sm-4 col-xs-12'>
            <div className='icon-plane nav-icon block__supportico' />
            <div>Nhanh chóng</div>
            <div className='block__supportdesc'>Bắt đầu phát triển phần mềm ngay lập tức</div>
          </div>
          <div className='col-md-4 col-sm-4 col-xs-12'>
            <div className='icon-shield nav-icon block__supportico' />
            <div>Thuận tiện</div>
            <div className='block__supportdesc'>Với công cụ thuận tiện</div>
          </div>
          <div className='col-md-4 col-sm-4 col-xs-12'>
            <div className='icon-present nav-icon block__supportico' />
            <div>Công nghệ</div>
            <div className='block__supportdesc'>Công nghệ mới nhất cả backend và frontend</div>
          </div>
        </div>
      </div>
      <div className='block__productwrap'>
        <div className='block__product container'>
          <div className='block__producttitle'>Nổi bật nhất</div>
          <div className='block__products'>
            {this.state.products && Array.isArray(this.state.products) ? this.state.products.slice(0, 6).map(perobj =>
              <div className='col-md-4 col-sm-4 col-xs-12' key={perobj._id}>
                <div className='block__productdetail'>
                  <div className='product__image'><img alt={perobj.name} src={global.rxu.config.base_api + '/upload/image/' + (perobj.img_landscape || 'ico_app_default.jpg')} /></div>
                  <div className='product__rate'>
                    <span className='icon-star nav-icon product__star' />
                    <span className='icon-star nav-icon product__star' />
                    <span className='icon-star nav-icon product__star' />
                    <span className='icon-star nav-icon product__star' />
                    <span className='icon-star nav-icon product__star' />
                  </div>
                  <div className='product__name'>{perobj.name}</div>
                  <div className='product__price'>
                    <span className='product__pricenew'>{global.rxu.strToMoney(perobj.price)}<span className='product__currency'>đ</span></span>
                  </div>
                  <div className='product__buy' onClick={(e) => this.onClickAddCart(e, perobj)}>Mua ngay</div>
                </div>
              </div>
            ) : <div />}
          </div>
        </div>
      </div>

      <div className='block__parallax'>
        <div className='block__parallaxcontent'>
          <div className='block__parallaxtitle'>RXCODE GENERATOR</div>
          <div className='block__parallaxdesc'>Nhanh chóng, thuận tiện và tối ưu, bắt đầu phát triển phần mềm ngay bây giờ, với công nghệ mới nhất</div>
        </div>
        <div className='block__parallaxstroketop' />
        <div className='block__parallaxstrokebot' />
      </div>

      <div className='block__reviewwrap'>
        <div className='block__review container'>
          <div className='block__reviewtitle'>Nhận xét người dùng</div>
          <div className='block__reviews'>
            {this.state.reviews && Array.isArray(this.state.reviews) ? this.state.reviews.slice(0, 6).map(perobj =>
              <div className='col-md-4 col-sm-4 col-xs-12' key={perobj._id}>
                <div className='block__reviewdetail'>
                  <div className='review__decor'>&#10077;</div>
                  <div className='review__content' dangerouslySetInnerHTML={{ __html: perobj.content }} />
                  <div className='review__image'><img alt={perobj.name} src={global.rxu.config.base_api + '/upload/image/' + (perobj.img_landscape || 'ico_app_default.jpg')} /></div>
                  <div className='review__name'>{perobj.name}</div>
                </div>
              </div>
            ) : <div />}
          </div>
        </div>
      </div>

      <div className='block__partnerwrap'>
        <div className='block__partner container' />
      </div>
      <div className='block__customer' />
    </div>)
  }
}

const mapStateToProps = (state, ownProps) => ({
  cart: state.cart,
  auth: state.auth,
  favorite: state.favorite
})

const mapDispatchToProps = {
  cartClear,
  cartAdd,
  cartDelete,
  favoriteAdd,
  favoriteDelete,
  rxnavClose,
  rxnavToggle
}

const ComponentWrapped = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component_)

export default ComponentWrapped
