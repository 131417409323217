import * as Jsx from './cate.jsx'
const RxComponent = global.rootRequire('components/shares/rxComponent').default

class cateComponent extends RxComponent {
  constructor (props, context) {
    super(props, context, Jsx)
    this.state = {}
  }

  // R E N D E R S
  render () { return this.renderComponent() }
}

export default cateComponent
