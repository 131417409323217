import React from 'react'

const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const RxCrud = global.rootRequire('components/shares/rxCrud').default
const RxNewSelectbox = global.rootRequire('components/shares/rxNewSelectBox').default
// const RxToggle = global.rootRequire('components/shares/rxToggle').default

// R E N D E R S
export const renderCatHead = (inthis, vm) =>
  <tr>
    <th className={inthis.helpSortClass('name')} onClick={(e) => inthis.onClickSort(e, 'name')}>Name</th>
    <th className='rxwidth--100'>Action</th>
  </tr>

export const renderCatBody = (inthis, vm) => {
  const tempData = global.rxu.array(inthis.state.data).slice(0)
  tempData.unshift({ _id: 0, name: 'All dashboard' })
  return tempData.map((perdata, index) => (
    <tr key={perdata._id} onClick={(e) => inthis.run('onClickCatItem', perdata)}><td>{perdata.name}</td>
      {perdata._id !== 0 ? <td>
        {inthis.state.paging.search_is_deleted !== 1 &&
          <div> <span className='betable__btnedit' onClick={(e) => inthis.onClickDataEdit(e, perdata)}><i className='icon-pencil' /></span>
            <span className='betable__btndelete' onClick={(e) => inthis.onClickDataDelete(e, perdata)}><i className='icon-close' /></span>
          </div>}
        {inthis.state.paging.search_is_deleted === 1 &&
          <div> <span className='betable__restore' onClick={(e) => inthis.onClickDataRestore(e, perdata)}>Restore</span></div>}
      </td> : <td />}
    </tr>))
}

export const renderTableHead = (context, vm) =>
  <tr>
    <th className={context.helpSortClass('name', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'name')}>Name Account</th>
    <th className={context.helpSortClass('customer_id', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'customer_id')}>Customer Id</th>
    <th className={context.helpSortClass('created_at', ['rxwidth--120'])} onClick={(e) => context.onClickSort(e, 'created_at')}>Created at</th>
  
    <th className='rxwidth--100'>Action</th>
  </tr>

export const renderTableBody = (context, vm) => global.rxu.array(context.state.data).map(perdata =>
  <tr key={perdata._id}>
    <td className='betable__gamelink'>{perdata.name}</td>
    <td>{perdata.customer_id}</td>
    <td><small>{global.rxu.date(perdata.created_at)}</small></td>
    {context.renderTableBodyBtns(context, perdata)}
  </tr>)

export const renderComponent = (vm) =>
  <AdminBlock name='Google Ads Configure' desc='Manage Google Ads'>
    <div className='rxcol-100'>
      <RxCrud
        onClickDataNew={vm.onClickDataNew} renderTableHead={vm.renderTableHead} renderTableBody={vm.renderTableBody} name='ggads' api='api_ggads' form={[

          { type: 'devide' },
          { name: 'Name', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.name} onChange={(e) => inthis.onBlurData(e, 'name')} className='fullwidth-input' />) },
          { type: 'devide' },
          { name: 'Customer Id', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.customer_id} onChange={(e) => inthis.onBlurData(e, 'customer_id')} className='fullwidth-input' />) },
          { type: 'devide' },
          { name: 'Games', func: (inthis) => (<RxNewSelectbox multi options={vm.state.games} results={inthis.state.editingData.games || ''} onChange={(result) => { inthis.onBlurDataValue(result, 'games') }} />) },

        ]}
      />
    </div>
  </AdminBlock>
