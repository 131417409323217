/* global $ */
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import './../game/dashboard.css'
// import { MultiSelect } from 'react-multi-select-component'
// import MultiSelect from 'react-multi-select-component'

import { DateRangePicker } from 'react-date-range'

import React, { Component } from 'react'

// import { Link } from 'react-router-dom'

const { apiget, apipost, getExchangeRate } = global.rootRequire('classes/request')
const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const { MainTable } = global.rootRequire('components/shares/rxTemplates')

const ulti = require('../game/dashboardUlti').default

const rxu = global.rxu

class DashboardComponent extends Component {
  constructor(props) {
    super(props)

    this.toDate = new Date()

    this.toDate1Ago = new Date()
    this.toDate1Ago.setDate(this.toDate1Ago.getDate() - 1)

    this.toDate3Ago = new Date()
    this.toDate3Ago.setDate(this.toDate3Ago.getDate() - 3)

    this.toDate7Ago = new Date()
    this.toDate7Ago.setDate(this.toDate7Ago.getDate() - 7)

    this.toDate15Ago = new Date()
    this.toDate15Ago.setDate(this.toDate15Ago.getDate() - 15)

    this.toDate30Ago = new Date()
    this.toDate30Ago.setDate(this.toDate30Ago.getDate() - 30)

    this.toDate90Ago = new Date()
    this.toDate90Ago.setDate(this.toDate30Ago.getDate() - 90)

    this.typeDashboard = [{ _id: 0, name: 'Default', api: 'api_store_performance_dashboard' }, { _id: 1, name: 'Custom', api: 'api_store_performance_dashboard_custom' }]
    this.state = {
      paging: { st_col: 'dateUnix', st_type: -1, pg_page: 1, pg_size: 10 },
      data: [],
      dataOverallToday: [],
      dataOverallYesterday: [],
      id: rxu.get(this.props, 'match.params.id'),
      selectionRange: {
        startDate: this.toDate7Ago,
        endDate: this.toDate1Ago,
        key: 'selection'
      },
      selectedCampaign: [],
      selectedNation: [],
      api_dashboard: 'api_store_performance_dashboard',
      is_active: 0,
      flags: {},
      loading: false,
      activeFillter: true,
      editingData: {}
    }
  }

  run(name, params) {
    if (params) { params.inthis = this } else { params = this }
    if (typeof this.props[name] !== 'undefined' && typeof this.props[name] === 'function') {
      return this.props[name](params)
    } else if (typeof this[name] !== 'undefined' && typeof this[name] === 'function') {
      return this[name]()
    }
  }

  toggle(flagname) {
    const flags = this.state.flags
    flags[flagname] = flags[flagname] ? 0 : 1
    this.setState({ flags: flags })
  }

  flag(flagname) {
    return rxu.get(this.state, ['flags', flagname], 0)
  }

  handleSelect(ranges) {
    this.clearCanvasDom()

    // if (ranges && ranges.selection) {
    //   if (ranges.selection.startDate !== ranges.selection.endDate) {
    //     ranges.selection.startDate.setDate(ranges.selection.startDate.getDate() - 1)
    //   }
    // }

    this.setState({
      selectionRange: ranges.selection
    }, () => {
      this.fetchData()
      this.fetchDetail()
    })
  }

  onClickSort(e, stcol) {
    const paging = this.state.paging
    paging.st_type = (paging.st_col !== stcol) ? -1 : (-1 * (paging.st_type))
    paging.st_col = stcol
    this.setState({ paging: paging }, () => { this.fetchDetail() })
  }

  onBlurDatafilter(e, name) {
    clearTimeout(this.timerDatafilter)
    const paging = this.state.paging
    paging['search_' + name] = e.target.value
    this.setState({ paging: paging })

    this.timerDatafilter = setTimeout((e, name) => {
      this.fetchData()
      this.fetchDetail()
    }, 500)
  }

  onBlurDatafilterName(e, val, type) {
    this.clearCanvasDom()
    const activeFillter = this.state.activeFillter
    const selectedNation = this.state.selectedNation
    const selectedCampaign = this.state.selectedCampaign

    console.log(activeFillter)
    console.log(selectedNation)
    console.log(val)
    console.log(type)
    console.log('7777777777')

    if (activeFillter) {
      if (selectedNation.indexOf(val) === -1) {
        if (type === 'country') {
          selectedNation.push(val)
        } else {
          selectedCampaign.push(val)
        }
      } else {
        if (type === 'country') {
          selectedNation.splice(selectedNation.indexOf(val), 1)
        } else {
          selectedCampaign.splice(selectedCampaign.indexOf(val), 1)
        }
      }

      this.setState({ selectedNation: selectedNation, selectedCampaign: selectedCampaign, activeFillter: !activeFillter }, (e) => { this.fetchDetail(); this.fetchData() })
    } else {
      if (type === 'country') {
        selectedNation.pop(val)
      } else {
        selectedCampaign.pop(val)
      }
      this.setState({ selectedNation: selectedNation, selectedCampaign: selectedCampaign, activeFillter: !activeFillter }, (e) => { this.fetchDetail(); this.fetchData() })
    }
  }

  helpSortClass(stcol, extraclass) {
    extraclass = extraclass || []
    let result = ''
    if (this.state.paging.st_col === stcol) {
      result = this.state.paging.st_type === 1 ? 'rx-sort-asc' : 'rx-sort-desc'
    }
    for (let i = 0; i < extraclass.length; i++) { result += ' ' + extraclass[i] }
    return result
  }

  componentDidMount() {
    if ($(window).width() > 1000) {
      this.isFullview = true
      $('.beside').addClass('rxsideactive')
      $('.bemain').addClass('rxsideactive')
      $('.adtopbar__togglesidebar').addClass('rxsideactive')
      $('.adtopbar__logo').addClass('rxsideactive')
    }
    this.fetchData()
    this.fetchDetail()
    this.fetchDataInfoGame()
  }

  componentWillUnmount() {
    if ($(window).width() > 1000 && this.isFullview) {
      $('.beside').removeClass('rxsideactive')
      $('.bemain').removeClass('rxsideactive')
      $('.adtopbar__togglesidebar').removeClass('rxsideactive')
      $('.adtopbar__logo').removeClass('rxsideactive')
    }
  }

  static getDerivedStateFromProps(props, state) {
    const id = rxu.get(props, 'match.params.id')
    const idOld = state.id

    const navigated = id !== idOld
    if (navigated) {
      return { idOld, id }
    }
    return null
  }

  fetchData() {
    this.setState({ loading: true }, () => {
      const api_dashboard = this.state.api_dashboard
      let params = {
        ...this.state.paging,
        id: this.state.id,
        type: 'traffic_source',
        startDate: this.state.selectionRange.startDate,
        endDate: this.state.selectionRange.endDate,
      }

      let country = rxu.array(this.state.selectedNation.map(e => e.value ? e.value : e)).join(',')
      let campaign = rxu.array(this.state.selectedCampaign.map(e => e.value ? e.value : e)).join(',')
      if (country) {
        params.country = country
      }
      if (campaign) {
        params.campaign = campaign
      }

      apiget(rxu.get(rxu.config, api_dashboard), params, {
        1: (json) => {
          this.setState({ data: json.data, loading: false }, () => { this.initCharAll(json.data.data) })
        }
      })
    })
  }

  fetchDetail() {
    let params = {
      ...this.state.paging,
      id: this.state.id,
      type: 'traffic_source',
      startDate: this.state.selectionRange.startDate,
      endDate: this.state.selectionRange.endDate,
    }

    let country = rxu.array(this.state.selectedNation.map(e => e.value ? e.value : e)).join(',')
    let campaign = rxu.array(this.state.selectedCampaign.map(e => e.value ? e.value : e)).join(',')
    if (country) {
      params.country = country
    }
    if (campaign) {
      params.campaign = campaign
    }

    apiget(global.rxu.config.api_store_performance_detail, params, {
      1: (json) => {
        console.log(json)
        this.setState({ dataOverallToday: json.data.data, dataOverallYesterday: json.data.dataago })
      }
    })
  }

  initCharAll(data) {
    $('#chartShow').html('')
    for (const i in data) {
      // this.clearCanvasDom(i)
      const curData = data[i]
      this.initChart(i, curData)
    }
  }

  initCharBlock(item, index, color) {
    return {
      lineTension: 0,
      data: item,
      label: index,
      backgroundColor: global.rxu.strRgbaAddAlpha(color, 1),
      borderColor: color,
      borderWidth: 2.5,
      pointBackgroundColor: global.rxu.strRgbaAddAlpha(color, 1),
      pointBorderWidth: 1 // pointStyle: 'circle'
    }
  }

  initCharOption(options) {
    return {
      elements: {
        point: {
          radius: 2,
          zIndex: 100
        }
      },
      maintainAspectRatio: false,
      title: { display: false },
      tooltips: {
        position: 'nearest',
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function (tooltipItem, chart) {
            var dataLabel = `${chart.datasets[tooltipItem.datasetIndex].label}` || ''
            const returnLabel = options?.format ? (dataLabel + ': ' + ulti.number_format_round(tooltipItem.yLabel, 2)) : (dataLabel + ': ' + ulti.number_format(tooltipItem.yLabel, 2))

            // return returnLabel // dataLabel + ':' + ulti.number_format(tooltipItem.yLabel, 2);

            var corporation = chart.datasets[tooltipItem.datasetIndex].label
            var valor = chart.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]

            // Loop through all datasets to get the actual total of the index
            var total = 0
            for (var i = 0; i < chart.datasets.length; i++) { total += chart.datasets[i].data[tooltipItem.index] }

            // If it is not the last dataset, you display it as you usually do
            if (tooltipItem.datasetIndex !== chart.datasets.length - 1) {
              return returnLabel
            } else {
              return options?.format ? [corporation + ': ' + ulti.number_format_round(valor, 2)] : options?.hasTotal ? [corporation + ': ' + ulti.number_format(valor, 2), 'Total : ' + ulti.number_format(total, 2)] : [corporation + ': ' + ulti.number_format(valor, 2)]
              // return options?.format ? [corporation + ': ' + ulti.number_format_round(valor, 2)] : [corporation + ': ' + ulti.number_format(valor, 2), 'Total : ' + ulti.number_format(total, 2)]
            }
          }
          // label: function(tooltipItem, chart) {
          //     var dataLabel = chart.datasets[tooltipItem.datasetIndex].label || ''
          //     let returnLabel = options?.format ? dataLabel + ': ' + ulti.number_format_round(tooltipItem.yLabel, 2) : dataLabel + ': ' + ulti.number_format(tooltipItem.yLabel, 2)
          //     return returnLabel // dataLabel + ':' + ulti.number_format(tooltipItem.yLabel, 2);
          // }
        }
      },
      legend: {
        display: true,
        position: 'top',
        labels: { pointBorderWidth: 0, boxWidth: 2, boxHeight: 2, marginBottom: 0, fontSize: 12, usePointStyle: false, marginRight: 15 },
        onClick: function (e, legendItem) {
          var index = legendItem.datasetIndex
          var ci = this.chart
          var alreadyHidden = (ci.getDatasetMeta(index).hidden === null) ? false : ci.getDatasetMeta(index).hidden
          ci.data.datasets.forEach(function (e, i) {
            var meta = ci.getDatasetMeta(i)
            if (i !== index) {
              if (!alreadyHidden) {
                meta.hidden = meta.hidden === null ? !meta.hidden : null
              } else if (meta.hidden === null) {
                meta.hidden = true
              }
            } else if (i === index) {
              meta.hidden = null
            }
          })
          ci.update()
        }
      },

      hover: { mode: 'nearest', intersect: true },
      interaction: { mode: 'nearest', intersect: true },
      scales: {
        xAxes: [{ ticks: { fontColor: 'rgba(150,150,150,0.7)', minRotation: 30, autoSkip: true, maxTicksLimit: 15 }, display: true, gridLines: { display: false }, stacked: options.stacked || false }],
        yAxes: [{ ticks: { callback: function (value) { return ulti.number_format(value) }, fontColor: 'rgba(0,0,0,0.8)', min: options.min || 0, autoSkip: true, maxTicksLimit: 10 }, display: true, gridLines: { display: true, color: 'rgba(150,150,200,0.2)', lineWidth: 0.5 }, stacked: options.stacked || false }]
      }
    }
  }

  initChart(id, data) {
    // Simple cook data
    const options = this.initCharOption(data.options || {})
    const chartMap = {}
    const chartData = data.data
    const labelArray = data.label
    const chartOptions = data.options || {}
    chartOptions.class = 'col-sm-4'
    if (typeof chartData.forEach !== 'undefined') {
      chartData.forEach((item, index) => {
        chartMap[item.label] = item.data
      })
    }

    // Makeup dataset
    const chartDataset = []
    Object.keys(chartMap).forEach((index) => {
      const item = chartMap[index]
      const currentColor = ulti.strToRgb(index || '')
      console.log({ currentColor, index })
      const currentFilltype = (chartOptions.fill ? { fill: chartOptions.fill } : chartOptions.type === 'line' ? { fill: false } : { fill: true })
      chartDataset.push({ ...this.initCharBlock(item, index, currentColor), ...currentFilltype })
    })

    const labelId = `chart_${id}`

    const chartContainer = document.getElementById(labelId)

    if (typeof (this[labelId]) !== 'undefined') {
      this.releaseCanvas(chartContainer)
      this[labelId].destroy()
      chartContainer.remove()
    }

    let strChart = `
    <div class='${chartOptions.class}'>
      <div class='chart__item'>
        <div class='chart__title'> ${data.name} </div>
        <div class='chart__content'><canvas id='${labelId}' width='600' height='300' /></div>
      </div>
    </div>`

    if (data.name === 'COST') {
      strChart = `
        <div class='${chartOptions.class}'>
          <div class='chart__item'>
            <div class='chart__title'> ${data.name} </div>
            <div class='chart__alert'>Từ tháng 11/2022 Cost GG + thêm 5%</div>
            <div class='chart__content'><canvas id='${labelId}' width='600' height='300' /></div>
          </div>
        </div>`
    }

    $('#chartShow').append(strChart).ready(function () {
      const ctx = document.getElementById(labelId).getContext('2d')

      let chartType = chartOptions.type || 'line'
      chartType = (chartType === 'line') ? 'LineWithLine' : chartType

      const config = {
        type: chartType,
        options: options,
        hover: { mode: 'nearest', intersect: true },
        data: { labels: labelArray, datasets: chartDataset }
      }


      console.log({ config })
      this[labelId] = new global.Chart(ctx, config)
    })
  }

  clearCanvasDom(i) {
    for (let i = 0; i < 8; i++) {
      let labelId = `chart_${i}`
      this.releaseCanvas(labelId)
      if (typeof (this[labelId]) !== 'undefined') {
        this[labelId].destroy()
      }
    }
  }

  backToGame(e) {
    this.clearCanvasDom()
    window.history.back()
  }

  releaseCanvas(labelId) {
    let canvas = document.getElementById(labelId)
    if (canvas) {
      canvas.width = 1
      canvas.height = 1
      const ctx = canvas.getContext('2d')
      ctx && ctx.clearRect(0, 0, 1, 1)
    }
  }

  handleNationChange(event) {
    this.clearCanvasDom()

    this.setState({ selectedNation: event }, () => {
      this.fetchData()
      this.fetchDetail()
    })
  }

  handleTypeDashboadChange(event) {
    this.clearCanvasDom()
    let api_dashboard = this.state.api_dashboard
    api_dashboard = event === 0 ? 'api_store_performance_dashboard' : 'api_store_performance_dashboard_custom'
    this.setState({ api_dashboard: api_dashboard, is_active: event }, () => {
      this.fetchData()
    })
  }

  fetchDataInfoGame() {
    apiget(rxu.get(rxu.config, 'api_store_performance_info'), { searchin__id: this.state.id, type: 'country' }, {
      1: (json) => {
        this.setState({ editingData: json.data[0] })
      }
    })
  }

  onBlurData(e) {
    const editingData = this.state.editingData
    editingData.desc = e.target.value
    this.setState({ editingData: editingData })
  }

  handleUpdate() {
    //const editingData = this.state.editingData
    //editingData._id = this.state.id
    //apipost(rxu.get(rxu.config, 'api_store_performance') + '/edit', editingData, {
    //  1: (json) => { this.fetchDataInfoGame(true) }
    //})
  }

  renderTable(vm) {
    return <MainTable><thead>{this.renderTableHead()}{this.renderTableHeadSearch()}</thead><tbody>{this.renderTableBody()}</tbody><tfoot>{this.renderTableFooterOverall()}</tfoot></MainTable>
  }

  renderTableHead() {
    return <tr>
      <th className='rxwidth--100'>STT</th>
      <th className={this.helpSortClass('dateUnix', ['rxwidth--100'])} onClick={(e) => this.onClickSort(e, 'dateUnix')}>Date</th>
      <th className={this.helpSortClass('trafficSource', ['rxwidth--100'])} onClick={(e) => this.onClickSort(e, 'trafficSource')}>Traffic Source</th>
      <th className={this.helpSortClass('searchTerm', ['rxwidth--100'])} onClick={(e) => this.onClickSort(e, 'searchTerm')}>Search Term</th>
      <th className={this.helpSortClass('utmSource', ['rxwidth--100'])} onClick={(e) => this.onClickSort(e, 'utmSource')}>UTM Source</th>
      <th className={this.helpSortClass('utmCampaign', ['rxwidth--100'])} onClick={(e) => this.onClickSort(e, 'utmCampaign')}>UTM Campaign</th>

	  <th className={this.helpSortClass('storeListingAcquisitions', ['rxwidth--100'])} onClick={(e) => this.onClickSort(e, 'storeListingAcquisitions')}>Store listing acquisitions</th>
      <th className={this.helpSortClass('storeListingVisitors', ['rxwidth--100'])} onClick={(e) => this.onClickSort(e, 'storeListingVisitors')}>Store listing visitors</th>
      <th className={this.helpSortClass('storeListingConversionRate', ['rxwidth--100'])} onClick={(e) => this.onClickSort(e, 'storeListingConversionRate')}>Store listing conversion rate</th>
    </tr>
  }

  renderTableHeadSearch() {
    return <tr className='table-sm'>
      <th className='' />
      <th className=''></th>
      <th className=''><input className='form-control form-control-sm' onKeyUp={(e) => this.onBlurDatafilter(e, 'trafficSource')} /></th>
      <th className=''><input className='form-control form-control-sm' onKeyUp={(e) => this.onBlurDatafilter(e, 'searchTerm')} /></th>
      <th className=''><input className='form-control form-control-sm' onKeyUp={(e) => this.onBlurDatafilter(e, 'utmSource')} /></th>
      <th className=''><input className='form-control form-control-sm' onKeyUp={(e) => this.onBlurDatafilter(e, 'utmCampaign')} /></th>
      <th className=''></th>
      <th className=''></th>
      <th className=''></th>
    </tr>
  }

  renderTableBody() {
    const dataDetail = this.state.dataOverallToday

    return global.rxu.array(dataDetail).map((perdata, i) => {
      return <tr className='' key={i}>
        <td>{i}</td>
        <td className='onclickFilter' onClick={(e) => this.onBlurDatafilterName(e, perdata.date, 'date')}>{perdata.date}</td>
        <td className='onclickFilter' onClick={(e) => this.onBlurDatafilterName(e, perdata.trafficSource, 'trafficSource')}>{perdata.trafficSource}</td>
        <td className='onclickFilter' onClick={(e) => this.onBlurDatafilterName(e, perdata.searchTerm, 'searchTerm')}>{perdata.searchTerm}</td>
        <td className='onclickFilter' onClick={(e) => this.onBlurDatafilterName(e, perdata.utmSource, 'utmSource')}>{perdata.utmSource}</td>
        <td className='onclickFilter' onClick={(e) => this.onBlurDatafilterName(e, perdata.utmCampaign, 'utmCampaign')}>{perdata.utmCampaign}</td>
	    <td className='rxcenter'><small className='rxtop'>{ulti.number_format_round(perdata.storeListingAcquisitions)}</small><br /></td>
        <td className='rxcenter'><small className='rxtop'>{ulti.number_format_round(perdata.storeListingVisitors)}</small><br /></td>
        <td className='rxcenter'><small className='rxtop'>{ulti.number_format_int(perdata.storeListingConversionRate) } %</small><br /></td>
    
      </tr>
    })
  }

  renderTableFooterOverall() {
    const dataDetail = this.state.dataOverallToday

    let totalStoreListingAcquisitions = 0; 
	let totalStoreListingVisitors = 0;
	let totalStoreListingConversionRate = 0;

    global.rxu.array(dataDetail).map((perdata, i) => {
      totalStoreListingAcquisitions += perdata.storeListingAcquisitions
      totalStoreListingVisitors += perdata.storeListingVisitors
      totalStoreListingConversionRate += perdata.storeListingConversionRate
    })

    return <tr>
      <th className='rxcenter rxwidth--100'>Total</th>
      <th className='rxcenter rxwidth--100'><small className='rxtop' /></th>
      <th className='rxcenter rxwidth--100'><small className='rxtop' /></th>
      <th className='rxcenter rxwidth--100'><small className='rxtop' /></th>
      <th className='rxcenter rxwidth--100'><small className='rxtop' /></th>
      <th className='rxcenter rxwidth--100'><small className='rxtop' /></th>
      <th className='rxcenter rxwidth--100'><small className='rxtop'>{ulti.number_format_round(totalStoreListingAcquisitions)}</small><br /></th>
      <th className='rxcenter rxwidth--100'><small className='rxtop'>{ulti.number_format_round(totalStoreListingVisitors)}</small><br /></th>
      <th className='rxcenter rxwidth--100'><small className='rxtop'>{ulti.number_format_int(totalStoreListingConversionRate/dataDetail.length)} %</small><br /></th>
		

    </tr>
  }

  // R E N D E R S
  render() {
    return (
      <>
        {this.state.loading && <div className='bg_loading'><div className='rxloading'></div></div>}
        <AdminBlock name={'Dashboard ' + rxu.get(this.state, 'data.name')} desc={'Dashboard ' + rxu.get(this.state, 'data.desc')} exclass='adsticky'>

          <div className='row'>
            <div className='filtersum__block'>
              <div className='showFilterBtn attop icon-magnifier-add' />
              <div className='filtersum__nation'><b>Nation</b> {rxu.array(this.state.selectedNation.map(e => e.value)).join(',') || 'All'}</div>
              <div className='filtersum__date'><b>Date</b> {this.state.selectionRange.startDate.toLocaleDateString('vi-VN')} - {this.state.selectionRange.endDate.toLocaleDateString('vi-VN')} </div>
            </div>
          </div>
          <div className='row' id='chartShow' />
          <div className='showFilterBtn icon-magnifier-add' />
          <div className='showFilter'>
            <div className='filterWrap'>
              <div className='filterLabel'>Type Dashboard</div>
              <div className='tabSelect'>
                <div className='tabSelectLabel'><span className={Number(this.state.is_active) === 0 ? 'tabSelectItem tabSelectItemActive' : 'tabSelectItem'} onClick={(e) => this.handleTypeDashboadChange(0)}>Default</span></div>
                <div className='tabSelectLabel'><span className={Number(this.state.is_active) === 1 ? 'tabSelectItem tabSelectItemActive' : 'tabSelectItem'} onClick={(e) => this.handleTypeDashboadChange(1)}>Custom</span></div>
              </div>
              <div className='filterLabel'>Nation</div>
              {/* <MultiSelect options={ulti.countryOptions} value={this.state.selectedNation} onChange={this.handleNationChange.bind(this)} labelledBy='Select' /> */}
              <div className='filterLabel'>Date</div>
              <DateRangePicker
                staticRanges={[
                  {
                    ...staticRangeHandler,
                    label: 'Today',
                    range: () => ({ startDate: this.toDate, endDate: this.toDate })
                  }, {
                    ...staticRangeHandler,
                    label: 'Yesterday',
                    range: () => ({ startDate: this.toDate1Ago, endDate: this.toDate1Ago })
                  }, {
                    ...staticRangeHandler,
                    label: 'Last 3 days',
                    range: () => ({ startDate: this.toDate3Ago, endDate: this.toDate1Ago })
                  }, {
                    ...staticRangeHandler,
                    label: 'Last 7 days',
                    range: () => ({ startDate: this.toDate7Ago, endDate: this.toDate1Ago })
                  }, {
                    ...staticRangeHandler,
                    label: 'Last 15 days',
                    range: () => ({ startDate: this.toDate15Ago, endDate: this.toDate1Ago })
                  }, {
                    ...staticRangeHandler,
                    label: 'Last 30 days',
                    range: () => ({ startDate: this.toDate30Ago, endDate: this.toDate1Ago })
                  }, {
                    ...staticRangeHandler,
                    label: 'Last 90 days',
                    range: () => ({ startDate: this.toDate90Ago, endDate: this.toDate1Ago })
                  }]}
                ranges={[this.state.selectionRange]}
                onChange={this.handleSelect.bind(this)}
              />
            </div>
          </div>

          {/* <Link className='backToGame' to='/admin/game'>X</Link> */}
          <button className='backToGame' onClick={(e) => this.backToGame()}> x </button>
          <div className='noteForGameBtn'>+</div>
          <div className='noteForGame'>
            <div className='filterWrap'>
              <div className='filterLabel'>Note Dashboard</div>
              <textarea className='form-control form-control-sm' value={this.state.editingData?.desc || ''} onChange={(e) => this.onBlurData(e, 'desc')} />
              <div className='tabSelect'>
                <div className='tabSelectLabel'><span className={Number(this.state.is_active) === 0 ? 'tabSelectItem tabSelectItemActive' : 'tabSelectItem'} onClick={(e) => this.handleUpdate(0)}>Update</span></div>
              </div>
            </div>
          </div>
          <div className='rxcol-100'>
            <div className='betable'>
              <div className='adblock__head '>
                <div className='adblock__title'>Bảng summary theo Traffic Source </div>
                <div className='adblock__desc' />
              </div>
              {this.renderTable()}
            </div>
          </div>

        </AdminBlock>
      </>
    )
  }
}

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range()
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    )
  }
}

function isSameDay(date1, date2) {
  try {
    return (date1.toString() === date2.toString())
  } catch (e) {
    return false
  }
}

export default DashboardComponent
