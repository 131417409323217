import { Component } from 'react'
const { apiget, apipost } = global.rootRequire('classes/request')

const WAIT_INTERVAL = 500
class RxCrudEvent extends Component {
  // B U I L D I N
  constructor (props, context) {
    super(props, context)
    this.state = {
      flagUpdate: false,
      paging: { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 },
      editingData: {},
      extra: {},
      data: {},
      flags: {}
    }
  }

  componentWillUnmount () {
    this._isUnmounted = true
  }

  // O V E R R I D E
  setState (obj, callback = () => {}) { if (!this._isUnmounted) { super.setState(obj, callback) } }

  // D A T A
  fetchAlldata () { this.fetchData() }
  fetchData (runCallback = false) {
    apiget(global.rxu.get(global.rxu.config, this.props.api), this.state.paging, {
      1: (json) => { this.setState({ data: json.data, extra: json.extra }) }
    })
    runCallback ? this.run('dataCallback', {}) : console.log()
  }

  run (name, params) {
    if (params) { params.inthis = this } else { params = this }
    if (typeof this.props[name] !== 'undefined' && typeof this.props[name] === 'function') {
      return this.props[name](params)
    } else if (typeof this[name] !== 'undefined' && typeof this[name] === 'function') {
      return this[name]()
    }
  }

  toggle (flagname) {
    const flags = this.state.flags
    flags[flagname] = flags[flagname] ? 0 : 1
    this.setState({ flags: flags })
  }

  // C L I C K   E V E N T
  onClickData (e, perdata) {}
  onClickSort (e, stcol) {
    const paging = this.state.paging
    paging.st_type = (paging.st_col !== stcol) ? -1 : (-1 * (paging.st_type))
    paging.st_col = stcol
    this.setState({ paging: paging }, () => { this.fetchData() })
  }

  onClickDataImport (e) {

  }

  onClickDataExport (e) {
    apiget(global.rxu.get(global.rxu.config, this.props.api), { ...this.state.paging, pg_size: 10000 }, {
      1: (json) => { global.JSONToCSVConvertor(json.data, '') }
    })
  }

  onClickDataNew (e) {
    global.scrolltop()
    const timeStr = Date.now().toString()
    const clone = { name: this.props.name + '_' + timeStr.substr(timeStr.length - 5), desc: '', created_at: 1, is_deleted: 0, is_active: 1, is_hot: 0, price: 100000, app: '', appdist: '' }
    this.setState({ editingData: clone })
  }

  onClickDataEdit (e, perdata) {
    global.scrolltop()
    if (typeof perdata.inthis !== 'undefined') { delete perdata.inthis }
    const clone = JSON.parse(JSON.stringify(perdata))
    this.setState({ editingData: clone, flags: {} })
  }

  onClickDataDelete (e, perdata) {
    const r = window.confirm('Bạn muốn xoá dữ liệu này !')
    if (r === true) {
      apiget(global.rxu.get(global.rxu.config, this.props.api) + '/delete', perdata, {
        1: (json) => { this.fetchData(true) }
      })
    } else {}
  }

  onClickDataRestore (e, perdata) {
    apiget(global.rxu.get(global.rxu.config, this.props.api) + '/restore', perdata, {
      1: (json) => { this.fetchData(true) }
    })
  }

  onClickDataUpdateSubmit (e, perdata) {
    global.scrolltop()
    apipost(global.rxu.get(global.rxu.config, this.props.api) + '/edit', this.state.editingData, {
      1: (json) => { this.fetchData(true) }
    })
    this.onClickDataEdit({}, {})
  }

  onClickDataUpdateSubmitRaw (e, perdata) {
    global.scrolltop()
    apipost(global.rxu.get(global.rxu.config, this.props.api) + '/edit', { ...this.jsoneditor.get(), _id: this.state.editingData._id, rxraw: 1 }, {
      1: (json) => { this.fetchData(true) },
      default: () => {}
    })
    this.onClickDataEdit({}, {})
  }

  onClickDataCreateSubmit (e, perdata) {
    global.scrolltop()
    apipost(global.rxu.get(global.rxu.config, this.props.api), this.state.editingData, {
      1: (json) => { this.fetchData(true) }
    })
    this.onClickDataEdit({}, {})
  }

  onClickDataTrash (e, isdeleted) {
    const paging = this.state.paging
    paging.search_is_deleted = isdeleted
    this.setState({ paging: paging }, () => {
      this.fetchData()
    })
  }

  onClickGenPermission () {
    apiget(global.rxu.config.api_permission_genpermission, {}, {
      1: (json) => {
        this.fetchData()
      }
    })
  }

  // B L U R   E V E N T
  onBlurData (e, name, options) {
    options = options || {}
    const editingData = this.state.editingData
    if (options.strim) {
      editingData[name] = e.target.value.toString().replace(/(,)/g, '')
    } else {
      editingData[name] = e.target.value
    }
    this.setState({ editingData: editingData })
  }

  onBlurDataValue (value, name) {
    const editingData = this.state.editingData
    editingData[name] = value
    this.setState({ editingData: editingData })
  }

  onBlurDatafilter (e, name) {
    clearTimeout(this.timerDatafilter)
    const paging = this.state.paging
    paging['search_' + name] = e.target.value
    this.setState({ paging: paging })

    this.timerDatafilter = setTimeout((e, name) => {
      this.fetchData()
    }, WAIT_INTERVAL)
  }

  // C H A N G E  E V E N T
  onChangeContentCKE (evt) {
    const editingData = this.state.editingData
    const newContent = evt.editor.getData()
    editingData.content = newContent
    this.setState({ editingData: editingData })
  }

  // P A G I N  E V E N T
  onClickPaging (page) {
    const paging = this.state.paging
    const count = global.rxu.get(this.state.extra, 'count', paging.pg_size)
    const maxpage = Math.ceil(count / paging.pg_size)

    if (page < 1) { page = 1 }
    if (page > maxpage) { page = maxpage }

    paging.pg_page = page
    this.setState({ paging: paging }, () => { this.fetchData() })
  }

  onChangePageSize (evt) {
    clearTimeout(this.timerPageSize)
    const pagesize = parseInt(evt.target.value, 10)
    const paging = this.state.paging
    paging.pg_size = pagesize
    paging.pg_page = 1
    if (pagesize >= 1 && pagesize <= 1000) {
      this.setState({ paging: paging }, () => { this.timerPageSize = setTimeout((e, name) => { this.fetchData() }, WAIT_INTERVAL) })
    }
  }

  // U P L O A D  E V E N T
  callbackUpload (e) {
    this.onBlurData({ target: { value: e.images } }, 'img_landscape')
  }

  callbackUploadDetail (e) {
    this.onBlurData({ target: { value: e.images } }, 'img_detail')
  }
}

export default RxCrudEvent
