import React, { Component } from 'react'
import { renderRoutes } from 'react-router-config'
import { Link, Switch } from 'react-router-dom'
import Parser from 'html-react-parser'
import { Redirect } from 'react-router'
import { connect } from 'react-redux'

import { rxsetCookie, rxgetLocal, rxJson } from './../../classes/request' // eslint-disable-line no-unused-vars
const { rxnavToggle, rxnavClose, rxnavChange, logoutAction } = global.rootRequire('redux')

const adminCSS = '<style>.main-container{ width: 100% !important; padding: 0px !important; } .homenav, .footer{ display: none !important; }</style>'

/* function checkpermission(strcheck) {
  let strper = rxgetLocal('arrper')
  let arrper = []
  if (strper && strper.length > 0 && strper.indexOf(',') !== -1) {
    arrper = strper.split(',')
  }
  if (arrper.length !== 0) {
    if (arrper.indexOf(strcheck) !== -1) {
      return true
    } else {
      return false
    }
  } else {
    return true
  }
} */

class Admin extends Component {
  constructor (props) {
    super(props)
    this._mounted = false
    let user = rxgetLocal('rxuser')
    if (user) {
      user = rxJson(user)
      this.isAdmin = global.rxu.get(user, ['user', 'admin'], 0)
      this.isAdminStu = global.rxu.get(user, ['user', 'adminStudio'], 0)
    } else {
      this.isAdmin = 0
    }
    this.state = {
      isAuthenticated: true,
      activeNavs: {}
    }
  }

  componentDidMount () {
    this._mounted = true
    this.checkAuth()
  }

  componentWillUnmount () {
    this._mounted = false
  }

  setStateRx (object, callback) {
    if (this._mounted) { this.setState(object, callback) }
  }

  checkAuth () {
    if (typeof (document) !== 'undefined') {
      const match = document.cookie.match(new RegExp('authorize=([^;]+)'))
      if (!match) {
        // Disable authenticate check
        this.setState({ isAuthenticated: false })
      }
    }
  }

  onClickLogout () {
    const user = { status: false, user: {}, arrper: [] }
    this.setState({ user: user })
    rxsetCookie('authorize', '', 120)
    localStorage.removeItem('exchange_rate')
    this.props.history.push('/login')
    this.props.logoutAction()
  }

  toggleNavs (navName) {
    const activeNavs = this.state.activeNavs
    if (typeof activeNavs[navName] === 'undefined') { activeNavs[navName] = 0 }
    activeNavs[navName] = !activeNavs[navName]
    this.setState({ activeNavs: activeNavs })
  }

  renderActiveNavClass (navpath, addclass = ' rxactive ') {
    const { pathname } = this.props.location
    if (pathname === navpath || (Array.isArray(navpath) && navpath.indexOf(pathname) !== -1)) {
      return addclass
    } else {
      return ''
    }
  }

  render () {
    return (
      <div>
        {this.state.isAuthenticated && <div>
          {Parser(adminCSS)}
          <div className='adtopbar'>
            <div className={global.rxu.get(this.props.rxnav, 'navs.righttoggle') ? 'adtopbar__logo rxsideactive' : 'adtopbar__logo'}>
              <Link to='/admin/dashboard'><img className='adminnav__logoimg' alt='logoimg' src='/images/static/logo.png' /></Link>
            </div>
            <div className={global.rxu.get(this.props.rxnav, 'navs.righttoggle') ? 'adtopbar__togglesidebar rxsideactive' : 'adtopbar__togglesidebar'}><i className='icon-grid topnav-icon' onClick={(e) => this.props.rxnavToggle('righttoggle')} /></div>
            <div className='adtopbar__right'>
              <div className='adminnav__search'><i className='icon-magnifier topnav-icon' /></div>
              <div className='adminnav__help'><i className='icon-question topnav-icon' /></div>
              <div className='adminnav__notify'>
                {/* <div className='adminnav__notifynum'>5</div> */}
                <i className='icon-bell topnav-icon' />
              </div>
              <div className='adminnav__avatar'>
                <span onClick={ele => this.props.rxnavToggle('ex_avatar')}>A</span>
                <div className={this.props.rxnav.navs.ex_avatar ? 'adminnav__avatarmenu rxactive' : 'adminnav__avatarmenu'}>
                  <div className='avatarmenu__fullname'>
                    <div>{global.rxu.get(this.props, 'auth.user.user.fullname')}</div>
                    <div className='avatarmenu__username'>{global.rxu.get(this.props, 'auth.user.user.username')}</div>
                  </div>
                  <div className='avatarmenu__logout' onClick={() => this.onClickLogout()}>
                    <span><i className='icon-power nav-icon' />Đăng xuất</span>
                  </div>
                </div>
              </div>
              <div className='adminnav__adname'>{global.rxu.get(this.props, 'auth.user.fullname')}</div>
              <div className='adminnav__language'><i className='icon-globe topnav-icon' /></div>
            </div>
          </div>

          <div className={global.rxu.get(this.props.rxnav, 'navs.righttoggle') ? 'beside rxsideactive' : 'beside'}>
            <div className='beside__nav'>
              <div className='beside__navitems'>
                <Link to='/admin/dashboard'>
                  <div className='beside__logo'>
                    <div className='beside__logoicon'>Rx</div> <div>Codegen</div>
                    <div className='beside__logoversion'>v3.0.2 titanium</div>
                  </div>
                </Link>
                <div className='beside__navitemsinner rxwindowheight'>
                  <div className={'adnav__groupname adnav__groupname--first adnav__groupname--link' + this.renderActiveNavClass(['/admin/dashboard', '/admin'])}>
                    <Link to='/admin/dashboard'><i className='icon-speedometer nav-icon' />Overviews</Link>
                  </div>
                  <div className={this.props.rxnav.navs.ex_studio ? 'adnav__groupname adnav__groupname--expand' + this.renderActiveNavClass('/rbac/studio') : 'adnav__groupname' + this.renderActiveNavClass('/rbac/studio')}><Link to='/rbac/studio'><i className='icon-diamond nav-icon' />Studio</Link></div>
                  <div className={this.props.rxnav.navs.ex_game ? 'adnav__groupname adnav__groupname--expand' + this.renderActiveNavClass('/admin/game') : 'adnav__groupname' + this.renderActiveNavClass('/admin/game')}><Link to='/admin/game'><i className='icon-diamond nav-icon' />Games</Link></div>
                  
                  {(this.isAdmin === 1 || this.isAdminStu === 1) && <div className={this.props.rxnav.navs.ex_campaign ? 'adnav__groupname expand iconcustom-up' : 'adnav__groupname iconcustom-down'} onClick={ele => this.props.rxnavToggle('ex_campaign')}><span><i className='icon-diamond nav-icon' />Campaigns</span></div>}

                  {(this.isAdmin === 1 || this.isAdminStu === 1) && <div className={this.props.rxnav.navs.ex_campaign ? 'adnav__groupwrap rxactive' : 'adnav__groupwrap'}>
                    <div className={'adnav__itemname' + this.renderActiveNavClass('/admin/reportcampaign')}><Link to='/admin/reportcampaign'><i className='icon-graph nav-icon' />Campaign</Link></div>
                    <div className={'adnav__itemname' + this.renderActiveNavClass('/admin/reportcountry')}><Link to='/admin/reportcountry'><i className='icon-graph nav-icon' />Country</Link></div>
                  </div>}
                  
                  <div className={this.props.rxnav.navs.ex_game ? 'adnav__groupname adnav__groupname--expand' + this.renderActiveNavClass('/admin/account') : 'adnav__groupname' + this.renderActiveNavClass('/admin/account')}><Link to='/admin/account'><i className='icon-diamond nav-icon' />Accounts</Link></div>
                  {(this.isAdmin === 1 || this.isAdminStu === 1) && <div className={this.props.rxnav.navs.ex_config ? 'adnav__groupname expand iconcustom-up' : 'adnav__groupname iconcustom-down'} onClick={ele => this.props.rxnavToggle('ex_config')}><span><i className='icon-diamond nav-icon' />Config</span></div>}

                  {(this.isAdmin === 1 || this.isAdminStu === 1) && <div className={this.props.rxnav.navs.ex_config ? 'adnav__groupwrap rxactive' : 'adnav__groupwrap'}>
                    <div className={'adnav__itemname' + this.renderActiveNavClass('/admin/admod')}><Link to='/admin/admod'><i className='icon-graph nav-icon' />Admod acount</Link></div>
                    <div className={'adnav__itemname' + this.renderActiveNavClass('/admin/ggads')}><Link to='/admin/ggads'><i className='icon-graph nav-icon' />Google Ads Configure</Link></div>
                    <div className={'adnav__itemname' + this.renderActiveNavClass('/admin/report')}><Link to='/admin/report'><i className='icon-graph nav-icon' />Report Game</Link></div>
                    <div className={'adnav__itemname' + this.renderActiveNavClass('/rbac/user')}><Link to='/rbac/user'><i className='icon-graph nav-icon' />Rbac User</Link></div>
                  </div>}
                  {/* [[RX_NAVLINK_START]] */}
                  {/* [[RX_NAVLINK_END]] */}

                  <div className={'adnav__groupname adnav__groupname--last adnav__groupname--link' + this.renderActiveNavClass('/login')} onClick={() => this.onClickLogout()}>
                    <span><i className='icon-power nav-icon' />Đăng xuất</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!!global.rxu.get(this.props.rxnav, 'navs.righttoggle') && <div className='rxsideactive_back' onClick={(e) => this.props.rxnavClose('righttoggle')} />}

          <div className={global.rxu.get(this.props.rxnav, 'navs.righttoggle') ? 'bemain rxsideactive' : 'bemain'}>
            <div className=''>
              <Switch>{renderRoutes(this.props.route.routes)}</Switch>
            </div>
          </div>
        </div>}
        {!this.state.isAuthenticated && <Redirect to='/login' />}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  rxnav: state.rxnav,
  auth: state.auth
})

const mapDispatchToProps = {
  rxnavToggle,
  rxnavClose,
  rxnavChange,
  logoutAction
}

const Admin_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(Admin)

export default Admin_
