import React from 'react'
import { Link } from 'react-router-dom'
// import CKEditor from 'react-ckeditor-component'

const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const RxCrud = global.rootRequire('components/shares/rxCrud').default
const RxUpload = global.rootRequire('components/shares/rxUpload').default
const RxSelectbox = global.rootRequire('components/shares/rxSelectbox').default
const RxToggle = global.rootRequire('components/shares/rxToggle').default

// R E N D E R S
export const renderCatHead = (inthis, vm) =>
  <tr>
    <th className={inthis.helpSortClass('name')} onClick={(e) => inthis.onClickSort(e, 'name')}>Name</th>
    <th className='rxwidth--100'>Action</th>
  </tr>

export const renderCatBody = (inthis, vm) => {
  const tempData = global.rxu.array(inthis.state.data).slice(0)
  tempData.unshift({ _id: 0, name: 'All dashboard' })
  return tempData.map((perdata, index) => (
    <tr key={perdata._id} onClick={(e) => inthis.run('onClickCatItem', perdata)}><td>{perdata.name}</td>
      {perdata._id !== 0 ? <td>
        {inthis.state.paging.search_is_deleted !== 1 &&
          <div> <span className='betable__btnedit' onClick={(e) => inthis.onClickDataEdit(e, perdata)}><i className='icon-pencil' /></span>
            <span className='betable__btndelete' onClick={(e) => inthis.onClickDataDelete(e, perdata)}><i className='icon-close' /></span>
          </div>}
        {inthis.state.paging.search_is_deleted === 1 &&
          <div> <span className='betable__restore' onClick={(e) => inthis.onClickDataRestore(e, perdata)}>Restore</span></div>}
      </td> : <td />}
    </tr>))
}

export const renderTableHead = (context, vm) =>
  <tr>
    <th className='rxwidth--100'>Icon</th>
    <th className={context.helpSortClass('name', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'name')}>Name</th>
    <th className={context.helpSortClass('studio', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'studio')}>Studio</th>
    <th className={context.helpSortClass('accountID', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'accountID')}>Account</th>
    <th className={context.helpSortClass('created_at', ['rxwidth--120'])} onClick={(e) => context.onClickSort(e, 'created_at')}>Created at</th>
    <th className='rxwidth--100'>Action</th>
  </tr>

export const renderTableBody = (context, vm) => global.rxu.array(context.state.data).map(perdata =>
  <tr key={perdata._id}>
    <td><img className='betable__img' alt={perdata.name} src={global.rxu.config.base_api + '/upload/image/' + (perdata.img_landscape || 'ico_app_default.jpg')} /></td>
    <td className='betable__gamelink'><Link to={`/admin/reportcountry/${perdata._id || 123}`}>{perdata.name}</Link></td>
    <td>{perdata && perdata.studioObj && perdata.studioObj.map(x => { const a = x.name + '-'; return a })}</td>
    <td><small>{vm.helpProductcat(perdata?.accountID)}</small></td>
    <td><small>{global.rxu.date(perdata.created_at)}</small></td>
    {context.renderTableBodyBtns(context, perdata)}
  </tr>)

export const renderComponent = (vm) =>
  <AdminBlock name='Game' desc='Manage game'>
    <div className='rxcol-100'>
      <RxCrud
        onClickDataNew={vm.onClickDataNew} renderTableHead={vm.renderTableHead} renderTableBody={vm.renderTableBody} name='game' api='api_game' form={[
          { name: 'Picture', func: (inthis) => (<RxUpload callback={(e) => inthis.callbackUpload(e)} images={inthis.state.editingData.img_landscape} />) },
          { type: 'devide' },
          {
            name: 'Platform',
            func: (inthis) => (<div className='rxradio' onChange={(e) => inthis.onChangeValue(e, 'platform')}>
              <input type='radio' value={0} checked={Number(inthis.state.editingData.platform) === 0} onChange={(e) => inthis.onChangeValue(e, 'platform')} />Android
              <input type='radio' value={1} checked={Number(inthis.state.editingData.platform) === 1} onChange={(e) => inthis.onChangeValue(e, 'platform')} />iOS
            </div>)
          },
          { type: 'devide' },
          { name: 'Account filter', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.accountFilter} onChange={(e) => inthis.onBlurData(e, 'accountFilter')} className='fullwidth-input' />) },

          { type: 'devide' },
          { name: 'Application URL On Store', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.appUrlOnStore} onChange={(e) => inthis.onBlurData(e, 'appUrlOnStore')} className='fullwidth-input' />) },

          { type: 'devide' },
          { name: 'Name', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.name} onChange={(e) => inthis.onBlurData(e, 'name')} className='fullwidth-input' />) },

          { type: 'devide' },
          { name: 'Firebase Property ID', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.gameId} onChange={(e) => inthis.onBlurData(e, 'gameId')} className='fullwidth-input' />) },
          // { name: 'Game Id', func: (inthis) => (<RxSelectbox options={vm.state.gameId} results={inthis.state.editingData.gameId || ''} onChange={(result) => { inthis.onBlurDataValue(result, 'gameId') }} />) },
          { type: 'devide' },
          { name: 'Facebook Application ID', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.gameFbId} onChange={(e) => inthis.onBlurData(e, 'gameFbId')} className='fullwidth-input' />) },
          { type: 'devide' },
          { name: 'Unity Application ID', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.gameUnityId} onChange={(e) => inthis.onBlurData(e, 'gameUnityId')} className='fullwidth-input' />) },
          { type: 'devide' },
          { name: 'Admob App ID', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.gameGgId} onChange={(e) => inthis.onBlurData(e, 'gameGgId')} className='fullwidth-input' />) },
          { type: 'devide' },
          { name: 'Package Name', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.packageName} onChange={(e) => inthis.onBlurData(e, 'packageName')} className='fullwidth-input' />) },
          { type: 'devide' },
          { name: 'GoogleAds Customer Id', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.customer_account_id} onChange={(e) => inthis.onBlurData(e, 'customer_account_id')} className='fullwidth-input' />) },

          { name: 'Adfly Publisher App Name', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.adflyPublisher} onChange={(e) => inthis.onBlurData(e, 'adflyPublisher')} className='fullwidth-input' />) },
          { type: 'devide' },

          { name: 'Mintegral App Id', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.gameMintegral} onChange={(e) => inthis.onBlurData(e, 'gameMintegral')} className='fullwidth-input' />) },
          { type: 'devide' },

          { name: 'Vungle App Id', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.gameVungleId} onChange={(e) => inthis.onBlurData(e, 'gameVungleId')} className='fullwidth-input' />) },
          { type: 'devide' },

          // { name: 'Gamob App Id', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.gameGamobId} onChange={(e) => inthis.onBlurData(e, 'gameGamobId')} className='fullwidth-input' />) },
          // { type: 'devide' },

          { type: 'devide' },
          { name: 'Dashboard name', func: (inthis) => (<RxSelectbox multi options={vm.state.dashboard} results={inthis.state.editingData.dashboard || ''} onChange={(result) => { inthis.onBlurDataValue(result, 'dashboard') }} />) },
          { name: 'Studio name', func: (inthis) => (<RxSelectbox multi options={vm.state.studio} results={inthis.state.editingData.studio || ''} onChange={(result) => { inthis.onBlurDataValue(result, 'studio') }} />) },

          { type: 'devide' },
          { name: 'Account', func: (inthis) => (<RxSelectbox options={vm.state.account} results={inthis.state.editingData.accountID || ''} onChange={(result) => { inthis.onBlurDataValue(result, 'accountID') }} />) },

          { type: 'devide' },
          { name: 'AdmodRevId', func: (inthis) => (<RxSelectbox multi options={vm.state.admod} results={inthis.state.editingData.admodRevId || ''} onChange={(result) => { inthis.onBlurDataValue(result, 'admodRevId') }} />) },

          { type: 'devide' },
          { name: 'Admod Remote', func: (inthis) => (<RxToggle value={inthis.state.editingData.is_admodapi} onToggle={(newValue) => inthis.onBlurDataValue(newValue, 'is_admodapi')} />) },

          { type: 'devide' },
          { name: 'Source Get Rev Facebook', func: (inthis) => (<RxSelectbox multi={false} options={[{_id: 'admod', name: 'Source Admod'}, {_id: 'applovin', name: 'Source Applovin'}]} results={inthis.state.editingData.sourceRevFb || ''} onChange={(result) => { inthis.onBlurDataValue(result, 'sourceRevFb') }} />) }
          
          // { type: 'devide' },
          // { name: 'Google Ads Configure', func: (inthis) => (<RxSelectbox multi options={vm.state.arrGgAds} results={inthis.state.editingData.ggAdsId || ''} onChange={(result) => { inthis.onBlurDataValue(result, 'ggAdsId') }} />) }

          // { type: 'devide' },
          // { type: 'fullinput', name: 'Notes', func: (context) => (<CKEditor activeClass='p10' content={context.state.editingData.content} events={{ 'change': (evt) => { let editingData = context.state.editingData; let newContent = evt.editor.getData(); editingData.content = newContent; context.setState({ editingData: editingData }) } }} />) },

          // [[RX_WEB_DATA_CUSTOM]] //

        // [[RX_WEB_DATA_CUSTOM_END]] //
        ]}
      />
    </div>
  </AdminBlock>
