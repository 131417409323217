import React from 'react'

const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const RxCrud = global.rootRequire('components/shares/rxCrud').default
const RxSelectbox = global.rootRequire('components/shares/rxSelectbox').default
const RxUpload = global.rootRequire('components/shares/rxUpload').default
const RxToggle = global.rootRequire('components/shares/rxToggle').default

export const renderTableHead = (inthis, vm) =>
  <tr>
    <th className={inthis.helpSortClass('keyId', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'keyId')}>keyId</th>
    <th className='rxwidth--100'>gameId</th>
    <th className='rxwidth--100'>country</th>
    <th className='rxwidth--100'>a1</th>
    <th className='rxwidth--100'>new_user</th>
    <th className='rxwidth--100'>rr0</th>
    <th className='rxwidth--100'>rr1</th>
    <th className='rxwidth--100'>rr7</th>
    <th className='rxwidth--100'>rr30</th>
    <th className='rxwidth--100'>rr1_rate</th>
    <th className='rxwidth--100'>rr7_rate</th>
    <th className='rxwidth--100'>rr30_rate</th>
    <th className='rxwidth--100'>is_sync</th>

    <th className={inthis.helpSortClass('date', ['rxwidth--120'])} onClick={(e) => inthis.onClickSort(e, 'date')}>Created at</th>
    <th className='rxwidth--100'>Action</th>
  </tr>

export const renderTableBody = (inthis, vm) =>
  global.rxu.array(inthis.state.data).map(perdata => (<tr key={perdata._id}>
    <td>{perdata.keyId}</td>
    <td>{perdata.gameId}</td>
    <td>{perdata.country}</td>
    <td>{perdata.a1}</td>
    <td>{perdata.new_user}</td>
    <td>{perdata.rr0}</td>
    <td>{perdata.rr1}</td>
    <td>{perdata.rr7}</td>
    <td>{perdata.rr30}</td>
    <td>{perdata.rr1_rate}</td>
    <td>{perdata.rr7_rate}</td>
    <td>{perdata.rr30_rate}</td>
    <td>{perdata.is_sync}</td>

    <td><small>{global.rxu.date(perdata.date)}</small></td>
    {inthis.renderTableBodyBtns(inthis, perdata)}
  </tr>))

export const renderComponent = (vm) =>
  <AdminBlock name='Note' desc='Manage note'>
    <div className='rxcol-100'>
      <RxCrud
        renderTableHead={vm.renderTableHead} flagUpdate={vm.state.flagUpdateCat} parentUpdate={vm.runUpdateCat} renderTableBody={vm.renderTableBody} name='Note' api='api_note' form={[
          { name: 'Picture', func: (inthis) => (<RxUpload callback={(e) => inthis.callbackUpload(e)} images={inthis.state.editingData.img_landscape} />) },

          { type: 'devide' },
          { name: 'Name', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.name} onChange={(e) => inthis.onBlurData(e, 'name')} className='fullwidth-input' />) },
          { name: 'Desc', func: (inthis) => (<input tabIndex='2' type='text' value={inthis.state.editingData.desc} onChange={(e) => inthis.onBlurData(e, 'desc')} className='fullwidth-input' />) },
          { name: 'Cate name', func: (inthis) => (<RxSelectbox options={vm.state.cate} results={inthis.state.editingData.appdist || ''} onChange={(result) => { inthis.onBlurDataValue(result, 'appdist') }} />) },

          { type: 'devide' },
          { name: 'Status', func: (inthis) => (<RxToggle value={inthis.state.editingData.is_active} onToggle={(newValue) => inthis.onBlurDataValue(newValue, 'is_active')} />) }
        ]}
      />
    </div>
  </AdminBlock>
