import React from 'react'

const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const RxCrud = global.rootRequire('components/shares/rxCrud').default
// const RxToggle = global.rootRequire('components/shares/rxToggle').default

// R E N D E R S
export const renderCatHead = (inthis, vm) =>
  <tr>
    <th className={inthis.helpSortClass('name')} onClick={(e) => inthis.onClickSort(e, 'name')}>Name</th>
    <th className='rxwidth--100'>Action</th>
  </tr>

export const renderCatBody = (inthis, vm) => {
  const tempData = global.rxu.array(inthis.state.data).slice(0)
  tempData.unshift({ _id: 0, name: 'All dashboard' })
  return tempData.map((perdata, index) => (
    <tr key={perdata._id} onClick={(e) => inthis.run('onClickCatItem', perdata)}><td>{perdata.name}</td>
      {perdata._id !== 0 ? <td>
        {inthis.state.paging.search_is_deleted !== 1 &&
          <div> <span className='betable__btnedit' onClick={(e) => inthis.onClickDataEdit(e, perdata)}><i className='icon-pencil' /></span>
            <span className='betable__btndelete' onClick={(e) => inthis.onClickDataDelete(e, perdata)}><i className='icon-close' /></span>
          </div>}
        {inthis.state.paging.search_is_deleted === 1 &&
          <div> <span className='betable__restore' onClick={(e) => inthis.onClickDataRestore(e, perdata)}>Restore</span></div>}
      </td> : <td />}
    </tr>))
}

export const renderTableHead = (context, vm) =>
  <tr>
    <th className='rxwidth--100'>Icon</th>
    <th className={context.helpSortClass('name', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'name')}>Name</th>
    <th className={context.helpSortClass('studio', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'studio')}>Publisher_id</th>
    <th className={context.helpSortClass('name', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'name')}>Client_id</th>
    <th className={context.helpSortClass('studio', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'studio')}>Client_secret</th>
    <th className={context.helpSortClass('name', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'name')}>Refresh_token</th>
    <th className={context.helpSortClass('studio', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'studio')}>Cookie</th>
    <th className={context.helpSortClass('created_at', ['rxwidth--120'])} onClick={(e) => context.onClickSort(e, 'created_at')}>Created at</th>
    <th className='rxwidth--100'>Action</th>
  </tr>

export const renderTableBody = (context, vm) => global.rxu.array(context.state.data).map(perdata =>
  <tr key={perdata._id}>
    <td><img className='betable__img' alt={perdata.name} src={global.rxu.config.base_api + '/upload/image/' + (perdata.img_landscape || 'ico_app_default.jpg')} /></td>
    <td className='betable__gamelink'>{perdata.name}</td>
    <td>{perdata.publisher_id}</td>
    <td>{perdata.client_id}</td>
    <td>{perdata.client_secret}</td>
    <td>{perdata.refresh_token}</td>
    <td>{perdata.cookie}</td>
    <td><small>{global.rxu.date(perdata.created_at)}</small></td>
    {context.renderTableBodyBtns(context, perdata)}
  </tr>)

export const renderComponent = (vm) =>
  <AdminBlock name='Admod Acount' desc='Manage admod acount'>
    <div className='rxcol-100'>
      <RxCrud
        onClickDataNew={vm.onClickDataNew} renderTableHead={vm.renderTableHead} renderTableBody={vm.renderTableBody} name='admod' api='api_admod' form={[

          { type: 'devide' },
          { name: 'Name', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.name} onChange={(e) => inthis.onBlurData(e, 'name')} className='fullwidth-input' />) },
          { name: 'Publisher_id', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.publisher_id} onChange={(e) => inthis.onBlurData(e, 'publisher_id')} className='fullwidth-input' />) },
          { type: 'devide' },
          { name: 'Client_id', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.client_id} onChange={(e) => inthis.onBlurData(e, 'client_id')} className='fullwidth-input' />) },
          { name: 'Client_secret', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.client_secret} onChange={(e) => inthis.onBlurData(e, 'client_secret')} className='fullwidth-input' />) },
          { type: 'devide' },
          { name: 'Refresh_token', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.refresh_token} onChange={(e) => inthis.onBlurData(e, 'refresh_token')} className='fullwidth-input' />) },
          { name: 'Cookie', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.cookie} onChange={(e) => inthis.onBlurData(e, 'cookie')} className='fullwidth-input' />) }

          // [[RX_WEB_DATA_CUSTOM]] //

          // [[RX_WEB_DATA_CUSTOM_END]] //

        ]}
      />
    </div>
  </AdminBlock>
