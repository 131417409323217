import React, { Component } from 'react'
import CKEditor from 'react-ckeditor-component'

const { apiget } = global.rootRequire('classes/request')
const RxCrud = global.rootRequire('components/shares/rxCrud').default
const RxSelectbox = global.rootRequire('components/shares/rxSelectbox').default
const RxUpload = global.rootRequire('components/shares/rxUpload').default
const RxToggle = global.rootRequire('components/shares/rxToggle').default

const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')

class productComponent extends Component {
  constructor (props) {
    super(props)
    this.state = { flagUpdateCat: false, curCat: 0, cate: [] }

    this.onClickCatItem = this.onClickCatItem.bind(this)
    this.runUpdateCat = this.runUpdateCat.bind(this)
    this.fetchData = this.fetchData.bind(this)

    this.renderCatBody = this.renderCatBody.bind(this)

    this.fetchData()
  }

  componentDidMount () {
  }

  fetchData () {
    apiget(global.rxu.config.api_productcate, { pg_size: 100 }, { 1: (json) => { this.setState({ cate: json.data, flagUpdateCat: !this.state.flagUpdateCat }) } })
  }

  onClickCatItem (e) {
    this.setState({ flagUpdateCat: !this.state.flagUpdateCat, curCat: e._id })
  }

  runUpdateCat (e) {
    const paging = (this.state.curCat) ? { searchid_appdist: this.state.curCat, st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 } : { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 }
    e.inthis.setState({ paging: paging }, () => { e.inthis.fetchData() })
  }

  // R E N D E R S
  renderCatHead (inthis) {
    return (<tr>
      <th className='rxwidth--100'>Pic</th>
      <th className={inthis.helpSortClass('name')} onClick={(e) => inthis.onClickSort(e, 'name')}>Name</th>
      <th className='rxwidth--100'>Action</th>
    </tr>)
  }

  renderCatBody (inthis) {
    const tempData = global.rxu.array(inthis.state.data).slice(0)
    tempData.unshift({ _id: 0, name: 'All cate' })
    return tempData.map((perdata, index) => (
      <tr key={perdata._id} onClick={(e) => inthis.run('onClickCatItem', perdata)} className={(this.state.curCat === perdata._id) ? 'betable__row--active' : ''}>
        <td><img className='betable__img' alt={perdata.name} src={global.rxu.config.base_api + '/upload/image/' + (perdata.img_landscape || 'ico_app_default.jpg')} /></td>
        <td>{perdata.name}</td>
        {perdata._id !== 0 ? inthis.renderTableBodyBtns(inthis, perdata) : <td />}
      </tr>))
  }

  renderTableHead (inthis) {
    return (<tr>
      <th className='rxwidth--100'>Pic</th>
      <th className={inthis.helpSortClass('name', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'name')}>Name</th>
      <th className={inthis.helpSortClass('price', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'price')}>Price</th>
      <th className={inthis.helpSortClass('stock', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'stock')}>Stock</th>
      <th className='rxwidth--100'>Cate</th>
      <th className={inthis.helpSortClass('created_at', ['rxwidth--120'])} onClick={(e) => inthis.onClickSort(e, 'created_at')}>Created at</th>
      <th className='rxwidth--100'>Action</th>
    </tr>)
  }

  renderTableBody (inthis) {
    return global.rxu.array(inthis.state.data).map(perdata => (<tr key={perdata._id}>
      <td><img className='betable__img' alt={perdata.name} src={global.rxu.config.base_api + '/upload/image/' + (perdata.img_landscape || 'ico_app_default.jpg')} /></td>
      <td>{perdata.name}</td>
      <td>
        {perdata.price ? <small className='betable__price'>{global.rxu.strToMoney(perdata.price)}</small> : <small />}
        {perdata.price_old ? <small className='betable__priceold'> {global.rxu.strToMoney(perdata.price_old)} <b>(-{(100 * (perdata.price_old - perdata.price) / perdata.price_old).toFixed(2)})</b></small> : <small />}
      </td>
      <td>{perdata.stock}</td>
      <td><small>{inthis.helpProductcat(perdata.appdistobj)}</small></td>
      <td><small>{global.rxu.date(perdata.created_at)}</small></td>
      {inthis.renderTableBodyBtns(inthis, perdata)}
    </tr>))
  }

  render () {
    return (
      <AdminBlock name='Products' desc='Manage products'>
        <div className='rxcol-35'>
          <RxCrud
            renderTableHead={this.renderCatHead} dataCallback={this.fetchData} renderTableBody={this.renderCatBody} onClickCatItem={this.onClickCatItem} name='productcate' api='api_productcate' form={[
              { name: 'Cate name', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.name} onChange={(e) => inthis.onBlurData(e, 'name')} className='fullwidth-input' />) },

              { type: 'devide' },
              { name: 'Status', func: (inthis) => (<RxToggle value={inthis.state.editingData.is_active} onToggle={(newValue) => inthis.onBlurDataValue(newValue, 'is_active')} />) }
            ]}
          />
        </div>

        <div className='rxcol-65'>
          <RxCrud
            renderTableHead={this.renderTableHead} flagUpdate={this.state.flagUpdateCat} parentUpdate={this.runUpdateCat} renderTableBody={this.renderTableBody} name='product' api='api_product' form={[
              { name: 'Picture', func: (inthis) => (<RxUpload callback={(e) => inthis.callbackUpload(e)} images={inthis.state.editingData.img_landscape} />) },

              { type: 'devide' },
              { name: 'Name', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.name} onChange={(e) => inthis.onBlurData(e, 'name')} className='fullwidth-input' />) },
              { name: 'Packed', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.packed} onChange={(e) => inthis.onBlurData(e, 'packed')} className='fullwidth-input' />) },

              { type: 'devide' },
              { name: 'Price', func: (inthis) => (<input tabIndex='1' type='text' value={global.rxu.strToMoney(inthis.state.editingData.price)} onChange={(e) => inthis.onBlurData(e, 'price', { strim: true })} className='fullwidth-input' />) },
              { name: 'Price old', func: (inthis) => (<input tabIndex='1' type='text' value={global.rxu.strToMoney(inthis.state.editingData.price_old)} onChange={(e) => inthis.onBlurData(e, 'price_old', { strim: true })} className='fullwidth-input' />) },

              { type: 'devide' },
              { type: 'fullinput', name: 'Content', func: (inthis) => (<CKEditor activeClass='p10' content={inthis.state.editingData.content} events={{ change: (evt) => { const editingData = inthis.state.editingData; const newContent = evt.editor.getData(); editingData.content = newContent; inthis.setState({ editingData: editingData }) } }} />) },
              // [[RX_WEB_DATA_CUSTOM]] //
              // [[RX_WEB_DATA_CUSTOM_END]] //

              { type: 'devide' },
              { name: 'Cate name', func: (inthis) => (<RxSelectbox options={this.state.cate} results={inthis.state.editingData.appdist || ''} onChange={(result) => { inthis.onBlurDataValue(result, 'appdist') }} />) },

              { type: 'devide' },
              { name: 'Seo slug', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.seoslug} onChange={(e) => inthis.onBlurData(e, 'seoslug')} className='fullwidth-input' />) },
              { name: 'Seo title', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.seotitle} onChange={(e) => inthis.onBlurData(e, 'seotitle')} className='fullwidth-input' />) },
              { name: 'Seo meta', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.seometa} onChange={(e) => inthis.onBlurData(e, 'seometa')} className='fullwidth-input' />) },

              { type: 'devide' },
              { name: 'Hot', func: (inthis) => (<RxToggle value={inthis.state.editingData.is_hot} onToggle={(newValue) => inthis.onBlurDataValue(newValue, 'is_hot')} />) },
              { type: 'devide' },
              { name: 'Status', func: (inthis) => (<RxToggle value={inthis.state.editingData.is_active} onToggle={(newValue) => inthis.onBlurDataValue(newValue, 'is_active')} />) }
            ]}
          />
        </div>
      </AdminBlock>
    )
  }
}

export default productComponent
