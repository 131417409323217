global.rootRequire = global.rootRequire || function () { return { default: {} } }
const Admin = global.rootRequire('components/admin').default
// const noteComponent = global.rootRequire('components/admin/note').default
const dashboardComponent = global.rootRequire('components/admin/dashboard').default
const gameComponent = global.rootRequire('components/admin/game').default
const gamedashboardComponent = global.rootRequire('components/admin/game/dashboard').default
const reportGameComponent = global.rootRequire('components/admin/reportGame').default
const admodComponent = global.rootRequire('components/admin/admod').default
const ggadsComponent = global.rootRequire('components/admin/ggads').default
const accountComponent = global.rootRequire('components/admin/account').default
const accountdashboardComponent = global.rootRequire('components/admin/account/dashboard').default

const studioComponent = global.rootRequire('components/admin/rbac/studio').default
const userComponent = global.rootRequire('components/admin/rbac/user').default
const roleComponent = global.rootRequire('components/admin/rbac/role').default
const permissionComponent = global.rootRequire('components/admin/rbac/permission').default
const loginComponent = global.rootRequire('components/authenticate/login').default
const registerComponent = global.rootRequire('components/authenticate/register').default

const studioDashboardComponent = global.rootRequire('components/admin/rbac/dashboard').default

const campaignDashboardComponent = global.rootRequire('components/admin/campaign/campaignDashboard').default
const campaignDashboardDetailComponent = global.rootRequire('components/admin/campaign/campaignDashboardDetail').default
const campaignCountryDashboardComponent = global.rootRequire('components/admin/campaign/campaignCountryDashboard').default
const campaignCountryDashboardDetailComponent = global.rootRequire('components/admin/campaign/campaignCountryDashboardDetail').default

const storePerformanceConfigAccountComponent = global.rootRequire('components/admin/storePerformance/configAccount').default
const storePerformanceCountryDashboardComponent = global.rootRequire('components/admin/storePerformance/countryDashboard').default
const storePerformanceCountryDashboardDetailComponent = global.rootRequire('components/admin/storePerformance/countryDashboardDetail').default
const storePerformanceTrafficSourceDashboardComponent = global.rootRequire('components/admin/storePerformance/trafficSourceDashboard').default
const storePerformanceTrafficSourceDashboardDetailComponent = global.rootRequire('components/admin/storePerformance/trafficSourceDashboardDetail').default

// [[RX_ROUTES_IMPORT]] //
const adminRoutes = { path: '/admin', component: Admin, routes: [{ path: '/admin', component: dashboardComponent, exact: true }] }
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/dashboard', component: dashboardComponent, exact: true }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/dashboard/:id', component: gamedashboardComponent }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/report', component: reportGameComponent }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/game', component: gameComponent }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/admod', component: admodComponent }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/ggads', component: ggadsComponent }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/account', component: accountComponent, exact: true }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/account/:id', component: accountdashboardComponent }])

adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/reportcampaign', component: campaignDashboardComponent, exact: true }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/reportcampaign/:id', component: campaignDashboardDetailComponent, exact: true }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/reportcountry', component: campaignCountryDashboardComponent, exact: true }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/reportcountry/:id', component: campaignCountryDashboardDetailComponent, exact: true }])

adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/storeperformance', component: storePerformanceConfigAccountComponent, exact: true }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/performancecountry', component: storePerformanceCountryDashboardComponent, exact: true }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/performancecountry/:id', component: storePerformanceCountryDashboardDetailComponent, exact: true }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/performancetraffic', component: storePerformanceTrafficSourceDashboardComponent, exact: true }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/performancetraffic/:id', component: storePerformanceTrafficSourceDashboardDetailComponent, exact: true }])
// [[RX_ROUTES_admin]] //
let Modulroutes = []; Modulroutes = Modulroutes.concat([adminRoutes])

const rxgenrbacRoutes = { path: '/rbac', component: Admin, routes: [{ path: '/rbac', component: userComponent, exact: true }] }
rxgenrbacRoutes.routes = rxgenrbacRoutes.routes.concat([{ path: '/rbac/studio', component: studioComponent }])
rxgenrbacRoutes.routes = rxgenrbacRoutes.routes.concat([{ path: '/rbac/user', component: userComponent }])
rxgenrbacRoutes.routes = rxgenrbacRoutes.routes.concat([{ path: '/rbac/role', component: roleComponent }])
rxgenrbacRoutes.routes = rxgenrbacRoutes.routes.concat([{ path: '/rbac/permission', component: permissionComponent }])
rxgenrbacRoutes.routes = rxgenrbacRoutes.routes.concat([{ path: '/rbac/dashboard', component: studioDashboardComponent, exact: true }])
rxgenrbacRoutes.routes = rxgenrbacRoutes.routes.concat([{ path: '/rbac/dashboard/:id', component: studioDashboardComponent }])

const loginRoutes = { path: '/login', component: loginComponent }
const registerRoutes = { path: '/register', component: registerComponent }
// [[RX_ROUTES_RBAC]] //
Modulroutes = Modulroutes.concat([rxgenrbacRoutes, loginRoutes, registerRoutes])
// [[RX_ROUTES]] //
module.exports = Modulroutes
