import React, { Component } from 'react'
import { Link } from 'react-router-dom'

// const { apiget } = global.rootRequire('classes/request')
const RxCrud = global.rootRequire('components/shares/rxCrud').default
const RxUpload = global.rootRequire('components/shares/rxUpload').default
const RxToggle = global.rootRequire('components/shares/rxToggle').default

class studioComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  renderTableHead (inthis) {
    return (<tr>
      <th className='rxwidth--100'>Picture</th>
      <th className={inthis.helpSortClass('name', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'name')}>FullName</th>
      <th className={inthis.helpSortClass('phone', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'phone')}>Phone</th>
      <th className={inthis.helpSortClass('created_at')} onClick={(e) => inthis.onClickSort(e, 'created_at')}>Created at</th>
      <th className='rxwidth--100'>Action</th>
    </tr>)
  }

  renderTableBody (inthis) {
    return global.rxu.array(inthis.state.data).map(perdata => (<tr key={perdata._id}>
      <td><img className='betable__img' alt={perdata.name} src={global.rxu.config.base_api + '/upload/image/' + (perdata.img_landscape || 'ico_app_default.jpg')} /></td>
      <td className='betable__gamelink'><Link to={`/rbac/dashboard/${perdata._id || 123}`}>{perdata.name}</Link></td>
      <td>{perdata.phone}</td>
      <td><small>{global.rxu.date(perdata.created_at)}</small></td>
      <td>
        {inthis.state.paging.search_is_deleted !== 1 &&
          <div> {(perdata.is_hot) && <span className='betable__icohot'><span className='icon-fire' />Hot</span>}
            {(perdata.is_active !== 0) && <span className='betable__icoon'>On</span>}{(perdata.is_active === 0) && <span className='betable__icooff'>Off</span>}
            <span className='betable__btnedit' onClick={(e) => inthis.onClickDataEdit(e, perdata)}><i className='icon-pencil' /></span>
            <span className='betable__btndelete' onClick={(e) => inthis.onClickDataDelete(e, perdata)}><i className='icon-close' /></span>
          </div>}
        {inthis.state.paging.search_is_deleted === 1 &&
          <div> <span className='betable__restore' onClick={(e) => inthis.onClickDataRestore(e, perdata)}>Restore</span></div>}
      </td>
    </tr>))
  }

  // R E N D E R S
  render () {
    return (
      <div className='adblock'>
        <div className='adblock__head'>
          <div className='adblock__title'>Studio</div>
          <div className='adblock__desc'>Studio</div>
        </div>
        <div className='adblock__body'>
          <div className='row adblock__inner'>
            <div className='rxcol-100'>
              <RxCrud
                renderTableHead={this.renderTableHead} renderTableBody={this.renderTableBody} flagUpdate={this.state.flagUpdateCat} parentUpdate={this.runUpdateCat} name='Studio' api='api_studio' form={[
                  { name: 'Picture', func: (inthis) => (<RxUpload callback={(e) => inthis.callbackUpload(e)} images={inthis.state.editingData.img_landscape} />) },
                  { type: 'devide' },

                  { name: 'Studio Name *', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.name || ''} onChange={(e) => inthis.onBlurData(e, 'name')} className='fullwidth-input' />) },
                  { name: 'Email *', func: (inthis) => (<input tabIndex='3' type='text' value={inthis.state.editingData.email || ''} onChange={(e) => inthis.onBlurData(e, 'email')} className='fullwidth-input' />) },
                  { type: 'devide' },
                  { name: 'Phone *', func: (inthis) => (<input tabIndex='4' type='text' value={inthis.state.editingData.phone || ''} onChange={(e) => inthis.onBlurData(e, 'phone')} className='fullwidth-input' />) },
                  { name: 'Address *', func: (inthis) => (<input tabIndex='4' type='text' value={inthis.state.editingData.address || ''} onChange={(e) => inthis.onBlurData(e, 'address')} className='fullwidth-input' />) },
                  { type: 'devide' },
                  { name: 'Desc', func: (inthis) => (<input tabIndex='5' type='text' value={inthis.state.editingData.desc} onChange={(e) => inthis.onBlurData(e, 'desc')} className='fullwidth-input' />) },

                  { type: 'devide' },
                  { name: 'Status', func: (inthis) => (<RxToggle value={inthis.state.editingData.is_active} onToggle={(newValue) => inthis.onBlurDataValue(newValue, 'is_active')} />) }
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default studioComponent
