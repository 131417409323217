import React from 'react'
import { Link } from 'react-router-dom'
// import CKEditor from 'react-ckeditor-component'

const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const RxCrud = global.rootRequire('components/shares/rxCrud').default
const RxUpload = global.rootRequire('components/shares/rxUpload').default
const RxSelectbox = global.rootRequire('components/shares/rxSelectbox').default
const RxToggle = global.rootRequire('components/shares/rxToggle').default

// R E N D E R S
export const renderCatHead = (inthis, vm) =>
  <tr>
    <th className={inthis.helpSortClass('name')} onClick={(e) => inthis.onClickSort(e, 'name')}>Name</th>
    <th className='rxwidth--100'>Action</th>
  </tr>

export const renderCatBody = (inthis, vm) => {
  const tempData = global.rxu.array(inthis.state.data).slice(0)
  tempData.unshift({ _id: 0, name: 'All dashboard' })
  return tempData.map((perdata, index) => (
    <tr key={perdata._id} onClick={(e) => inthis.run('onClickCatItem', perdata)}><td>{perdata.name}</td>
      {perdata._id !== 0 ? <td>
        {inthis.state.paging.search_is_deleted !== 1 &&
          <div> <span className='betable__btnedit' onClick={(e) => inthis.onClickDataEdit(e, perdata)}><i className='icon-pencil' /></span>
            <span className='betable__btndelete' onClick={(e) => inthis.onClickDataDelete(e, perdata)}><i className='icon-close' /></span>
          </div>}
        {inthis.state.paging.search_is_deleted === 1 &&
          <div> <span className='betable__restore' onClick={(e) => inthis.onClickDataRestore(e, perdata)}>Restore</span></div>}
      </td> : <td />}
    </tr>))
}

export const renderTableHead = (context, vm) =>
  <tr>
    <th className='rxwidth--100'>Icon</th>
    <th className={context.helpSortClass('name', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'name')}>Name</th>
    <th className={context.helpSortClass('studio', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'studio')}>Studio</th>
    <th className={context.helpSortClass('accountID', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'accountID')}>Account</th>
    <th className={context.helpSortClass('created_at', ['rxwidth--120'])} onClick={(e) => context.onClickSort(e, 'created_at')}>Created at</th>
    <th className='rxwidth--100'>Action</th>
  </tr>

export const renderTableBody = (context, vm) => global.rxu.array(context.state.data).map(perdata =>
  <tr key={perdata._id}>
    <td><img className='betable__img' alt={perdata.name} src={global.rxu.config.base_api + '/upload/image/' + (perdata.img_landscape || 'ico_app_default.jpg')} /></td>
    <td className='betable__gamelink'><Link to={`/admin/performancecountry/${perdata._id || 123}`}>{perdata.name}</Link></td>
    <td>{perdata && perdata.studioObj && perdata.studioObj.map(x => { const a = x.name + '-'; return a })}</td>
    <td><small>{vm.helpProductcat(perdata?.accountID)}</small></td>
    <td><small>{global.rxu.date(perdata.created_at)}</small></td>
    {context.renderTableBodyBtns(context, perdata)}
  </tr>)

export const renderComponent = (vm) =>
  <AdminBlock name='Store Performance' desc='Manage store performance by country'>
    <div className='rxcol-100'>
      <RxCrud
        onClickDataNew={vm.onClickDataNew} renderTableHead={vm.renderTableHead} renderTableBody={vm.renderTableBody} name='game' api='api_game' 
      />
    </div>
  </AdminBlock>
