/* global $ */
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import './game/dashboard.css'
import { DateRangePicker } from 'react-date-range'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
// import { MultiSelect } from 'react-multi-select-component'
// import MultiSelect from 'react-multi-select-component'

const RxSelectbox = global.rootRequire('components/shares/rxSelectbox').default
const { MainTable } = global.rootRequire('components/shares/rxTemplates')
const { apiget, rxgetExchangeRate, getExchangeRate } = global.rootRequire('classes/request')
const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const ulti = require('./game/dashboardUlti').default

const rxu = global.rxu

class DashboardComponent extends Component {
  constructor (props) {
    super(props)
    this.toDate = new Date()

    this.toDate1Ago = new Date()
    this.toDate1Ago.setDate(this.toDate1Ago.getDate() - 1)

    this.toDate3Ago = new Date()
    this.toDate3Ago.setDate(this.toDate3Ago.getDate() - 3)

    this.toDate7Ago = new Date()
    this.toDate7Ago.setDate(this.toDate7Ago.getDate() - 7)

    this.toDate15Ago = new Date()
    this.toDate15Ago.setDate(this.toDate15Ago.getDate() - 15)

    this.toDate30Ago = new Date()
    this.toDate30Ago.setDate(this.toDate30Ago.getDate() - 30)

    this.toDate90Ago = new Date()
    this.toDate90Ago.setDate(this.toDate30Ago.getDate() - 90)

    this.typeDashboard = [{ _id: 0, name: 'Default', api: 'api_overview' }, { _id: 1, name: 'Custom', api: 'api_overview' }]
    this.state = {
      data: [],
      dataDetailToday: [],
      dataOverallToday: [],
	  dataSourceRevToday: [],
      dataDetailYesterday: [],
      dataOverallYesterday: [],
      dataSourceRevYesterday: [],
      gameOptions: [],
      studioOptions: [],
      id: rxu.get(this.props, 'match.params.id'),
      selectionRange: {
        startDate: this.toDate1Ago,
        endDate: this.toDate1Ago,
        key: 'selection'
      },
      selectedNation: [],
      selectedStudio: [],
      selectedGame: [],
      api_dashboard: 'api_overview',
      is_active: 0,
      flags: {},
      loading: false,
      paging: { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 100 }
    }
  }

  run (name, params) {
    if (params) { params.inthis = this } else { params = this }
    if (typeof this.props[name] !== 'undefined' && typeof this.props[name] === 'function') {
      return this.props[name](params)
    } else if (typeof this[name] !== 'undefined' && typeof this[name] === 'function') {
      return this[name]()
    }
  }

  toggle (flagname) {
    const flags = this.state.flags
    flags[flagname] = flags[flagname] ? 0 : 1
    this.setState({ flags: flags })
  }

  flag (flagname) {
    return rxu.get(this.state, ['flags', flagname], 0)
  }

  handleSelect (ranges) {
    this.clearCanvasDom()

    // if (ranges && ranges.selection) {
    //   if (ranges.selection.startDate !== ranges.selection.endDate) {
    //     ranges.selection.startDate.setDate(ranges.selection.startDate.getDate() - 1)
    //     ranges.selection.endDate.setDate(ranges.selection.endDate.getDate() + 1)
    //   }
    // }
    
    console.log(ranges.selection)
    
    this.setState({
      selectionRange: ranges.selection
    }, () => {
      this.fetchData()
      // this.fetchOverall()
      // this.fetchDetail()
    })
  }

  componentDidMount () {
    this.fetchData()
    this.fetchStudio()
    this.fetchGame()
	rxgetExchangeRate(true)
    // this.fetchOverall()
    // this.fetchDetail()
  }

  componentWillUnmount () {

  }

  static getDerivedStateFromProps (props, state) {
    const id = rxu.get(props, 'match.params.id')
    const idOld = state.id

    const navigated = id !== idOld
    if (navigated) {
      return { idOld, id }
    }
    return null
  }

  fetchStudio () {
    apiget(global.rxu.config.api_studio, {}, { 1: (json) => { this.setState({ studioOptions: json.data }) } })
  }

  fetchGame () {
    const params = {}
    if (this.state.selectedStudio && this.state.selectedStudio !== '') {
      params.searchin_studio = this.state.selectedStudio
    }
    apiget(global.rxu.config.api_game, params, { 1: (json) => { this.setState({ gameOptions: json.data }) } })
  }

  fetchData () {
    this.setState({ loading: true }, () => {
      const apiDashboard = this.state.api_dashboard

      apiget(rxu.get(rxu.config, apiDashboard), {
        ...this.state.paging,
        id: this.state.id,
        startDate: this.state.selectionRange.startDate,
        endDate: this.state.selectionRange.endDate,
        nations: rxu.array(this.state.selectedNation.map(e => e.value)).join(','),
        studios: this.state.selectedStudio,
        gameIds: this.state.selectedGame
      }, {
        1: (json) => {
          this.setState({ data: json.data, loading: false }, () => {
            this.initCharAll(json.data.data)
            this.fetchOverall()
            this.fetchSourceRev()
            this.fetchDetail()
          })
        }
      })
    })
  }

  fetchDetail () {
    apiget(global.rxu.config.api_report_game_detail, {
      ...this.state.paging,
      startDate: this.state.selectionRange.startDate,
      endDate: this.state.selectionRange.endDate,
      studios: this.state.selectedStudio,
      gameIds: this.state.selectedGame
    }, {
      1: (json) => {
        this.setState({ dataDetailToday: json.data.data, dataDetailYesterday: json.data.databefore })
      }
    })
  }

  fetchOverall () {
    apiget(global.rxu.config.api_report_overall, {
      ...this.state.paging,
      startDate: this.state.selectionRange.startDate,
      endDate: this.state.selectionRange.endDate,
      studios: this.state.selectedStudio
    }, {
      1: (json) => {
        this.setState({ dataOverallToday: json.data.data, dataOverallYesterday: json.data.databefore })
      }
    })
  }

  fetchSourceRev () {
    apiget(global.rxu.config.api_report_source_rev, {
      ...this.state.paging,
      startDate: this.state.selectionRange.startDate,
      endDate: this.state.selectionRange.endDate,
      studios: this.state.selectedStudio
    }, {
      1: (json) => {
        this.setState({ dataSourceRevToday: json.data.data, dataSourceRevYesterday: json.data.databefore })
      }
    })
  }

  onClickSort (e, stcol) {
    const paging = this.state.paging
    paging.st_type = (paging.st_col !== stcol) ? -1 : (-1 * (paging.st_type))
    paging.st_col = stcol
    this.setState({ paging: paging }, () => { this.fetchDetail() })
  }

  onBlurDatafilter (e, name) {
    clearTimeout(this.timerDatafilter)
    const paging = this.state.paging
    paging['search_' + name] = e.target.value
    this.setState({ paging: paging })

    this.timerDatafilter = setTimeout((e, name) => {
      this.fetchDetail()
    }, 500)
  }

  helpSortClass (stcol, extraclass) {
    extraclass = extraclass || []
    let result = ''
    if (this.state.paging.st_col === stcol) {
      result = this.state.paging.st_type === 1 ? 'rx-sort-asc' : 'rx-sort-desc'
    }
    for (let i = 0; i < extraclass.length; i++) { result += ' ' + extraclass[i] }
    return result
  }

  initCharAll (data) {
    $('#chartShow').html('')
    for (const i in data) {
      const curData = data[i]
      this.initChart(i, curData)
    }
  }

  initCharBlock (item, index, color) {
    return {
      lineTension: 0,
      data: item,
      label: index,
      backgroundColor: global.rxu.strRgbaAddAlpha(color, 1),
      borderColor: color,
      borderWidth: 2.5,
      pointBackgroundColor: global.rxu.strRgbaAddAlpha(color, 1),
      pointBorderWidth: 1 // pointStyle: 'circle'
    }
  }

  initCharOption (options) {
    return {
      elements: {
        point: {
          radius: 2
        }
      },
      maintainAspectRatio: false,
      title: { display: false },
      tooltips: {
        position: 'nearest',
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function (tooltipItem, chart) {
            var dataLabel = `${chart.datasets[tooltipItem.datasetIndex].label}` || ''
            const returnLabel = options?.format ? (dataLabel + ': ' + ulti.number_format_int(tooltipItem.yLabel, 2)) : (dataLabel + ': ' + ulti.number_format(tooltipItem.yLabel, 2))

            // return returnLabel // dataLabel + ':' + ulti.number_format(tooltipItem.yLabel, 2);

            var corporation = chart.datasets[tooltipItem.datasetIndex].label
            var valor = chart.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]

            // Loop through all datasets to get the actual total of the index
            var total = 0
            for (var i = 0; i < chart.datasets.length; i++) { total += chart.datasets[i].data[tooltipItem.index] }

            // If it is not the last dataset, you display it as you usually do
            if (tooltipItem.datasetIndex !== chart.datasets.length - 1) {
              return returnLabel
            } else { // .. else, you display the dataset and the total, using an array
              return options?.format ? [corporation + ': ' + ulti.number_format_int(valor, 2), 'Total : ' + ulti.number_format_int(total, 2)] : [corporation + ': ' + ulti.number_format(valor, 2), 'Total : ' + ulti.number_format(total, 2)]
            }
          }

        }
      },
      legend: {
        display: true,
        position: 'top',
        labels: { pointBorderWidth: 0, boxWidth: 2, boxHeight: 2, marginBottom: 0, fontSize: 12, usePointStyle: false, marginRight: 15 },
        onClick: function (e, legendItem) {
          var index = legendItem.datasetIndex
          var ci = this.chart
          var alreadyHidden = (ci.getDatasetMeta(index).hidden === null) ? false : ci.getDatasetMeta(index).hidden
          ci.data.datasets.forEach(function (e, i) {
            var meta = ci.getDatasetMeta(i)
            if (i !== index) {
              if (!alreadyHidden) {
                meta.hidden = meta.hidden === null ? !meta.hidden : null
              } else if (meta.hidden === null) {
                meta.hidden = true
              }
            } else if (i === index) {
              meta.hidden = null
            }
          })
          ci.update()
        }
      },
      hover: { mode: 'nearest', intersect: true },
      interaction: { mode: 'nearest', intersect: true },
      scales: {
        xAxes: [{ ticks: { fontColor: 'rgba(150,150,150,0.7)', minRotation: 30, autoSkip: true, maxTicksLimit: 15 }, display: true, gridLines: { display: false }, stacked: options.stacked || false }],
        yAxes: [{ ticks: { callback: function (value) { return ulti.number_format(value) }, fontColor: 'rgba(0,0,0,0.8)', autoSkip: true, maxTicksLimit: 10 }, display: true, gridLines: { display: true, color: 'rgba(150,150,200,0.2)', lineWidth: 0.5 }, stacked: options.stacked || false }]
      }
    }
  }

  initChart (id, data) {
    // Simple cook data
    const options = this.initCharOption(data.options || {})
    const chartMap = {}
    const chartData = data.data
    const labelArray = data.label
    const chartOptions = data.options || {}
    if (typeof chartData.forEach !== 'undefined') {
      chartData.forEach((item, index) => {
        chartMap[item.label] = item.data
      })
    }

    // Makeup dataset
    const chartDataset = []
    Object.keys(chartMap).forEach((index) => {
      const item = chartMap[index]
      const currentColor = ulti.strToRgb(index || '')
      const currentFilltype = (chartOptions.type === 'line' ? { fill: false } : { fill: true })
      chartDataset.push({ ...this.initCharBlock(item, index, currentColor), ...currentFilltype })
    })

    const labelId = `chart_${id}`
    console.log(labelId)
    if (typeof (this[labelId]) !== 'undefined') { this[labelId].destroy() }
    $('#chartShow').append(`
    <div class='${chartOptions.class}'>
      <div class='chart__item'>
        <div class='chart__title'> ${data.name} </div>
        <div class='chart__content'><canvas id='${labelId}' width='600' height='300' /></div>
      </div>
    </div>`).ready(function () {
      const ctx = document.getElementById(labelId).getContext('2d')

      let chartType = chartOptions.type || 'line'
      chartType = (chartType === 'line') ? 'LineWithLine' : chartType

      const config = {
        type: chartType,
        options: options,
        hover: { mode: 'nearest', intersect: true },
        data: { labels: labelArray, datasets: chartDataset }
      }
      this[labelId] = new global.Chart(ctx, config)
    })
  }

  handleNationChange (event) {
    this.clearCanvasDom()
    this.setState({ selectedNation: event }, () => {
      this.fetchData()
    })
  }

  backToGame(e) {
    this.clearCanvasDom()
    window.history.back()
  }

  releaseCanvas(labelId) {
    let canvas = document.getElementById(labelId)
    if (canvas) {
      canvas.width = 1
      canvas.height = 1
      const ctx = canvas.getContext('2d')
      ctx && ctx.clearRect(0, 0, 1, 1)
    }
  }

  clearCanvasDom() {
    for (let i = 0; i < 8; i++) {
      let labelId = `chart_${i}`
      this.releaseCanvas(labelId)
      if (typeof (this[labelId]) !== 'undefined') { 
        this[labelId].destroy()
      }
    }
  }

  onBlurDataValue (value, name) {
    if (name === 'selectedStudio') {
      this.setState({ selectedStudio: value }, () => {
        this.fetchData()
        this.fetchGame()
        this.fetchOverall()
        this.fetchDetail()
        this.fetchSourceRev()
      })
    } else {
      this.setState({ selectedGame: value }, () => {
        this.fetchData()
        this.fetchDetail()
      })
    }
  }

  handleTypeDashboadChange (event) {
    this.clearCanvasDom()
    const apiDashboard = event === 0 ? 'api_overview' : 'api_overview'
    this.setState({ api_dashboard: apiDashboard, is_active: event }, () => {
      this.fetchData()
    })
  }

  // R E N D E R S
  renderDaterangeSelect () {
    return (
      <select className='chart__select'>
        <option value='1week'> Theo tuần</option>
        <option value='1mouth'> Theo tháng</option>
        <option value='1year'> Theo năm</option>
      </select>
    )
  }

  // T A B L E
  renderTableOverall (vm) {
    return <MainTable><thead>{this.renderTableHeadOverall()}</thead><tbody>{this.renderTableBodyOverall()}</tbody><tfoot>{this.renderTableFooterOverall()}</tfoot></MainTable>
  }

  renderTableHeadOverall () {
    return <tr>
      <th className='rxcenter rxwidth--100'>TEAM</th>
      <th className='rxcenter'>Tổng INSTALL</th>
      <th className='rxcenter'>Tổng DAU</th>
      <th className='rxcenter'>Tổng COST</th>
      <th className='rxcenter'>Tổng PROFIT</th>
      <th className='rxcenter'>Tổng REV</th>
    </tr>
  }

  renderTableBodyOverall () {
    const dataDetail = this.state.dataOverallToday
    const dataOverallYesterday = this.state.dataOverallYesterday

    const exchangeRate = getExchangeRate()

    return global.rxu.array(dataDetail).map((perdata, i) => {
      const dataOverallAgo = dataOverallYesterday.filter(x => x.name === perdata.name)

      const percenNru = ulti.number_format_((perdata.nru - dataOverallAgo[0]?.nru) / dataOverallAgo[0]?.nru * 100)
      const percenDau = ulti.number_format_((perdata.dau - dataOverallAgo[0]?.dau) / dataOverallAgo[0]?.dau * 100)
      const percenRev = ulti.number_format_((perdata.rev - dataOverallAgo[0]?.rev) / dataOverallAgo[0]?.rev * 100)
      const percenCost = ulti.number_format_((perdata.cost - dataOverallAgo[0]?.cost) / dataOverallAgo[0]?.cost * 100)
      const profit = perdata.rev - perdata.cost / exchangeRate
      const profitAgo = dataOverallAgo[0]?.rev - dataOverallAgo[0]?.cost / exchangeRate
      const percenPL = ulti.number_format_((profit - profitAgo) / profitAgo * 100)

      return <tr key={perdata.name}>
        <td className='rxcenter'>{perdata.name}</td>
        <td className='rxcenter'><small className='rxtop'>{ulti.number_format_(perdata.nru)}</small><br /><small className={percenNru < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{percenNru} %</small></td>
        <td className='rxcenter'><small className='rxtop'>{ulti.number_format_(perdata.dau)}</small><br /><small className={percenDau < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{percenDau} %</small></td>
        <td className='rxcenter'><small className='rxtop'>{ulti.number_format_(perdata.cost / exchangeRate)}</small><br /><small className={percenCost < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{percenCost} %</small></td>
        <td className='rxcenter'><small className='rxtop'>{ulti.number_format_(perdata?.rev - (perdata?.cost / exchangeRate))}</small><br /><small className={percenPL < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{percenPL} %</small></td>
        <td className='rxcenter'><small className='rxtop'>{ulti.number_format_(perdata.rev)}</small><br /><small className={percenRev < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{percenRev} %</small></td>
      </tr>
    })
  }

  renderTableFooterOverall () {
    const dataDetail = this.state.dataOverallToday
    const dataDetailAgo = this.state.dataOverallYesterday
    let totalInstall = 0; let totalDau = 0; let totalRev = 0; let totalCost = 0
    let totalInstallAgo = 0; let totalDauAgo = 0; let totalRevAgo = 0; let totalCostAgo = 0
    const exchangeRate = getExchangeRate()
    // eslint-disable-next-line
    global.rxu.array(dataDetail).map((perdata, i) => { totalInstall += perdata.nru; totalDau += perdata.dau; totalRev += perdata.rev; totalCost += perdata.cost; })
    // eslint-disable-next-line
    global.rxu.array(dataDetailAgo).map((per, j) => { totalInstallAgo += per.nru; totalDauAgo += per.dau; totalRevAgo += per.rev; totalCostAgo += per.cost;  })

    const perNru = ulti.number_format_((totalInstall - totalInstallAgo) / totalInstallAgo * 100)
    const perDau = ulti.number_format_((totalDau - totalDauAgo) / totalDauAgo * 100)
    const perRev = ulti.number_format_((totalRev - totalRevAgo) / totalRevAgo * 100)
    const perCost = ulti.number_format_((totalCost - totalCostAgo) / totalCostAgo * 100)

    const profit = totalRev - totalCost / exchangeRate
    const profitAgo = totalRevAgo - totalCostAgo / exchangeRate
    const perPL = ulti.number_format_((profit - profitAgo) / profitAgo * 100)

    return <tr>
      <th className='rxcenter rxwidth--100'>Total</th>
      <th className='rxcenter rxwidth--100'><small className='rxtop'>{ulti.number_format_(totalInstall)}</small><br /><small className={perNru < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{perNru} %</small></th>
      <th className='rxcenter rxwidth--100'><small className='rxtop'>{ulti.number_format_(totalDau)}</small><br /><small className={perDau < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{perDau} %</small></th>
      <th className='rxcenter rxwidth--100'><small className='rxtop'>{ulti.number_format_(totalCost / exchangeRate)}</small><br /><small className={perCost < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{perCost} %</small></th>
      <th className='rxcenter rxwidth--100'><small className='rxtop'>{ulti.number_format_(profit)}</small><br /><small className={perPL < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{perPL} %</small></th>
      <th className='rxcenter rxwidth--100'><small className='rxtop'>{ulti.number_format_(totalRev)}</small><br /><small className={perRev < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{perRev} %</small></th>
    </tr>
  }

  renderTableSourceRev (vm) {
    return <MainTable><thead>{this.renderTableHeadSourceRev()}</thead><tbody>{this.renderTableBodySourceRev()}</tbody><tfoot>{this.renderTableFooterSourceRev()}</tfoot></MainTable>
  }

  renderTableHeadSourceRev () {
    return <tr>
      <th className='rxcenter rxwidth--100'>Source</th>
      <th className='rxcenter'>Tổng REV</th>
    </tr>
  }

  renderTableBodySourceRev () {
    const dataDetail = this.state.dataSourceRevToday
    const dataOverallYesterday = this.state.dataSourceRevYesterday

    return global.rxu.array(dataDetail).map((perdata, i) => {
      const dataOverallAgo = dataOverallYesterday.filter(x => x.name === perdata.name)

      const percenRev = ulti.number_format_((perdata.value - dataOverallAgo[0]?.value) / dataOverallAgo[0]?.value * 100)

      return <tr key={perdata.name}>
        <td className='rxcenter'>{perdata.name}</td>
        <td className='rxcenter'><small className='rxtop'>{ulti.number_format_(perdata.value)}</small><br /><small className={percenRev < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{percenRev} %</small></td>
      </tr>
    })
  }

  renderTableFooterSourceRev () {
    const dataDetail = this.state.dataSourceRevToday
    const dataDetailAgo = this.state.dataSourceRevYesterday
    let totalRev = 0; let totalRevAgo = 0;
    // eslint-disable-next-line
    global.rxu.array(dataDetail).map((perdata, i) => { totalRev += perdata.value; })
    // eslint-disable-next-line
    global.rxu.array(dataDetailAgo).map((per, j) => { totalRevAgo += per.value;  })

    const perRev = ulti.number_format_((totalRev - totalRevAgo) / totalRevAgo * 100)

    return <tr>
      <th className='rxcenter rxwidth--100'>Total</th>
      <th className='rxcenter rxwidth--100'><small className='rxtop'>{ulti.number_format_(totalRev)}</small><br /><small className={perRev < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{perRev} %</small></th>
    </tr>
  }

  renderTable (vm) {
    return <MainTable><thead>{this.renderTableHead()}{this.renderTableHeadSearch()}</thead><tbody>{this.renderTableBody()}</tbody></MainTable>
  }

  renderTableHead () {
    return <tr>
      <th className='rxwidth--100'>STT</th>
      <th className={this.helpSortClass('studio', ['rxwidth--100'])} onClick={(e) => this.onClickSort(e, 'studio')}>Studio</th>
      <th className={this.helpSortClass('gameName', ['rxwidth--100'])} onClick={(e) => this.onClickSort(e, 'gameName')}>Game</th>
      <th className={this.helpSortClass('n1', ['rxright'])} onClick={(e) => this.onClickSort(e, 'n1')}>Total Install (n1)</th>
      <th className={this.helpSortClass('a1', ['rxright'])} onClick={(e) => this.onClickSort(e, 'a1')}>Organics Install</th>
      <th className={this.helpSortClass('a1', ['rxright'])} onClick={(e) => this.onClickSort(e, 'a1')}>Install -1 (a1)</th>
      <th className={this.helpSortClass('rr1', ['rxright'])} onClick={(e) => this.onClickSort(e, 'rr1')}>RR1-1 (rr1)</th>
      <th className={this.helpSortClass('a1', ['rxright'])} onClick={(e) => this.onClickSort(e, 'a1')}>DAU</th>
      <th className={this.helpSortClass('rev', ['rxright'])} onClick={(e) => this.onClickSort(e, 'rev')}>Rev (usd)</th>
      <th className={this.helpSortClass('rev', ['rxright'])} onClick={(e) => this.onClickSort(e, 'rev')}>ARPDAU (rev / a1)</th>
      <th className={this.helpSortClass('cost', ['rxright'])} onClick={(e) => this.onClickSort(e, 'cost')}>CPI (cost / conver)</th>
      <th className={this.helpSortClass('cost', ['rxright'])} onClick={(e) => this.onClickSort(e, 'cost')}>UA COST</th>
      <th className={this.helpSortClass('profit', ['rxright'])} onClick={(e) => this.onClickSort(e, 'profit')}>P/L</th>
    </tr>
  }

  renderTableHeadSearch () {
    return <tr className='table-sm'>
      <th className='' />
      <th className=''><input className='form-control form-control-sm' aria-label="column name: 'name' filter input" onKeyUp={(e) => this.onBlurDatafilter(e, 'studio_name')} /></th>
      <th className=''><input className='form-control form-control-sm' aria-label="column name: 'name' filter input" onKeyUp={(e) => this.onBlurDatafilter(e, 'name')} /></th>
      <th className=''><input className='form-control form-control-sm' aria-label="column name: 'registered' filter input" /></th>
      <th className=''><input className='form-control form-control-sm' aria-label="column name: 'role' filter input" /></th>
      <th className=''><input className='form-control form-control-sm' aria-label="column name: 'status' filter input" /></th>
      <th className=''><input className='form-control form-control-sm' aria-label="column name: 'name' filter input" /></th>
      <th className=''><input className='form-control form-control-sm' aria-label="column name: 'registered' filter input" /></th>
      <th className=''><input className='form-control form-control-sm' aria-label="column name: 'role' filter input" /></th>
      <th className=''><input className='form-control form-control-sm' aria-label="column name: 'status' filter input" /></th>
      <th className=''><input className='form-control form-control-sm' aria-label="column name: 'status' filter input" /></th>
      <th className=''><input className='form-control form-control-sm' aria-label="column name: 'status' filter input" /></th>
      <th className=''><input className='form-control form-control-sm' aria-label="column name: 'status' filter input" /></th>
    </tr>
  }

  renderTableBody () {
    const dataDetail = this.state.dataDetailToday
    const dataDetailYesterday = this.state.dataDetailYesterday
    const exchangeRate = getExchangeRate()

    return global.rxu.array(dataDetail).map((perdata, i) => {
      let perN1 = 0; let perDau = 0; let perRev = 0; let perArpd = 0; let perCpi = 0; let perCost = 0; let perPL = 0
      let dataDetailAgo = []
      if (dataDetailYesterday && dataDetailYesterday.length > 0) {
        dataDetailAgo = dataDetailYesterday.filter(x => x.gameId === perdata.gameId)
        perN1 = ulti.number_format_((perdata?.n1 - dataDetailAgo[0]?.n1) / dataDetailAgo[0]?.n1 * 100)
        perDau = ulti.number_format_((perdata?.a1 - dataDetailAgo[0]?.a1) / dataDetailAgo[0]?.a1 * 100)
        perRev = ulti.number_format_((perdata?.rev - dataDetailAgo[0]?.rev) / dataDetailAgo[0]?.rev * 100)
        perArpd = ulti.number_format_(((perdata?.rev / perdata?.a1) - (dataDetailAgo[0]?.rev / dataDetailAgo[0]?.a1)) / (dataDetailAgo[0]?.rev / dataDetailAgo[0]?.a1) * 100)
        perCpi = ulti.number_format_(((perdata?.cost / perdata.conversions) - (dataDetailAgo[0]?.cost / dataDetailAgo[0]?.conversions)) / (dataDetailAgo[0]?.cost / dataDetailAgo[0]?.conversions) * 100)
        perCost = ulti.number_format_(((perdata?.cost / exchangeRate) - (dataDetailAgo[0]?.cost / exchangeRate)) / (dataDetailAgo[0]?.cost / exchangeRate) * 100)
        const profit = perdata?.rev - (perdata?.cost / exchangeRate)
        const profitAgo = dataDetailAgo[0]?.rev - (dataDetailAgo[0]?.cost / exchangeRate)

        perPL = ulti.number_format_((profit - profitAgo) / profitAgo * 100)
      }
      return <tr className='' key={perdata.gameId}>
        <td>{i}</td>
        <td>{global.rxu.get(perdata, ['studio', 0])}</td>
        <td className='betable__gamelink'><Link to={`/admin/dashboard/${global.rxu.get(perdata, ['game_Id', 0]) || 123}`}>{global.rxu.get(perdata, ['gameName', 0])}</Link></td>
        <td className='rxright'><small className='rxtop'>{ulti.number_format_int(perdata.n1)}</small><br /><small className={perN1 < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{!isNaN(perN1) && perN1} %</small></td>
        <td className='rxright'>{0}</td>
        <td className='rxright'>{ulti.number_format_int(rxu.get(dataDetailAgo, [0, 'n1'], 0))}</td>
        <td className='rxright'>{ulti.number_format_int(rxu.get(dataDetailAgo, [0, 'rr1'], 0))}</td>
        <td className='rxright'><small className='rxtop'>{ulti.number_format_int(perdata.a1)}</small><br /><small className={perDau < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{!isNaN(perDau) && perDau} %</small></td>
        <td className='rxright'><small className='rxtop'>{ulti.number_format_int(perdata.rev)}</small><br /><small className={perRev < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{!isNaN(perRev) && perRev} %</small></td>
        <td className='rxright'><small className='rxtop'>{ulti.number_format_int(perdata.rev / perdata.a1)}</small><br /><small className={perArpd < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{!isNaN(perArpd) && perArpd} %</small></td>
        <td className='rxright'><small className='rxtop'>{ulti.number_format_int(perdata.cost / perdata.conversions)}</small><br /><small className={perCpi < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{!isNaN(perCpi) && perCpi} %</small></td>
        <td className='rxright'><small className='rxtop'>{ulti.number_format_(perdata.cost / exchangeRate)}</small><br /><small className={perCost < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{!isNaN(perCost) ? perCost : ''} %</small></td>
        <td className='rxright'><small className='rxtop'>{ulti.number_format_(perdata.rev - perdata.cost / exchangeRate)}</small><br /><small className={perPL < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{!isNaN(perPL) ? perPL : ''} %</small></td>
      </tr>
    })
  }

  render () {
    return (
      <>
        { this.state.loading && <div className='bg_loading'><div className='rxloading'></div></div> }
        <AdminBlock name={'Dashboard ' + rxu.get(this.state, 'data.name')} desc={'Dashboard ' + rxu.get(this.state, 'data.desc')} exclass='adsticky'>
          <div className='row'>
            <div className='filtersum__block'>
              <div className='showFilterBtn attop icon-magnifier-add' />
              <div className='filtersum__nation'><b>Nation</b> {rxu.array(this.state.selectedNation.map(e => e.value)).join(',') || 'All'}</div>
              <div className='filtersum__date'><b>Date</b> {this.state.selectionRange.startDate.toLocaleDateString('vi-VN')} - {this.state.selectionRange.endDate.toLocaleDateString('vi-VN')} </div>
            </div>
          </div>
          <div className='row' id='chartShow' />
          <div className='showFilterBtn icon-magnifier-add' />
          <div className='showFilter'>
            <div className='filterWrap'>
              <div className='filterLabel'>Type Dashboard</div>
              <div className='tabSelect'>
                <div className='tabSelectLabel'><span className={Number(this.state.is_active) === 0 ? 'tabSelectItem tabSelectItemActive' : 'tabSelectItem'} onClick={(e) => this.handleTypeDashboadChange(0)}>Default</span></div>
                <div className='tabSelectLabel'><span className={Number(this.state.is_active) === 1 ? 'tabSelectItem tabSelectItemActive' : 'tabSelectItem'} onClick={(e) => this.handleTypeDashboadChange(1)}>Custom</span></div>
              </div>
              <div className='filterLabel'>Nation</div>
              {/* <MultiSelect options={ulti.countryOptions} value={this.state.selectedNation} onChange={this.handleNationChange.bind(this)} labelledBy='Select' /> */}
              <div className='filterLabel'>Studio</div>
              {this.state.studioOptions && this.state.studioOptions.length !== 0 && <RxSelectbox multi={false} options={this.state.studioOptions} results={this.state.selectedStudio} onChange={(result) => { this.onBlurDataValue(result, 'selectedStudio') }} />}
              <div className='filterLabel'>Game</div>
              {this.state.gameOptions && this.state.gameOptions.length !== 0 && <RxSelectbox multi options={this.state.gameOptions} results={this.state.selectedGame} onChange={(result) => { this.onBlurDataValue(result, 'selectedGame') }} />}
              <div className='filterLabel'>Date</div>
              <DateRangePicker
                staticRanges={[
                  {
                    ...staticRangeHandler,
                    label: 'Today',
                    range: () => ({ startDate: this.toDate, endDate: this.toDate })
                  }, {
                    ...staticRangeHandler,
                    label: 'Yesterday',
                    range: () => ({ startDate: this.toDate1Ago, endDate: this.toDate1Ago })
                  }, {
                    ...staticRangeHandler,
                    label: 'Last 3 days',
                    range: () => ({ startDate: this.toDate3Ago, endDate: this.toDate1Ago })
                  }, {
                    ...staticRangeHandler,
                    label: 'Last 7 days',
                    range: () => ({ startDate: this.toDate7Ago, endDate: this.toDate1Ago })
                  }, {
                    ...staticRangeHandler,
                    label: 'Last 15 days',
                    range: () => ({ startDate: this.toDate15Ago, endDate: this.toDate1Ago })
                  }, {
                    ...staticRangeHandler,
                    label: 'Last 30 days',
                    range: () => ({ startDate: this.toDate30Ago, endDate: this.toDate1Ago })
                  }, {
                    ...staticRangeHandler,
                    label: 'Last 90 days',
                    range: () => ({ startDate: this.toDate90Ago, endDate: this.toDate1Ago })
                  }]}
                ranges={[this.state.selectionRange]}
                onChange={this.handleSelect.bind(this)}
              />
            </div>
          </div>
          {/* <Link className='backToGame' to='/admin/dashboard'>X</Link> */}
          {/* <button className='backToGame' onClick={(e) => this.backToGame()}> x </button> */}

          <div className='rxcol-100'>
            <div className='betable'>
              <div className='adblock'>
                <div className='adblock__head '>
                  <div className='adblock__title'>Bảng report overall </div>
                  <div className='adblock__desc' />
                </div>
                {this.renderTableOverall()}
              </div>
            </div>
          </div>

		  <div className='rxcol-100'>
            <div className='betable'>
              <div className='adblock'>
                <div className='adblock__head '>
                  <div className='adblock__title'>Bảng report source rev </div>
                  <div className='adblock__desc' />
                </div>
                {this.renderTableSourceRev()}
              </div>
            </div>
          </div>

          <div className='rxcol-100'>
            <div className='betable'>
              <div className='adblock__head '>
                <div className='adblock__title'>Bảng report game detail </div>
                <div className='adblock__desc' />
              </div>
              {this.renderTable()}
            </div>
          </div>

        </AdminBlock>
      </>
    )
  }
}

const staticRangeHandler = {
  range: {},
  isSelected (range) {
    const definedRange = this.range()
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    )
  }
}

function isSameDay (date1, date2) {
  try {
    return (date1.toString() === date2.toString())
  } catch (e) {
    return false
  }
}

export default DashboardComponent
