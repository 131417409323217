import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
global.isclient && require('./footer.css')

class Footer extends Component {
  render () {
    return (
      <div className='footer'>
        <div className='footer__inner'>
          {/* <div className='container'>
            <div className='col-md-3 col-sm-3 col-xs-12'>
              <div className='footer__title'>Liên hệ</div>
              <div className='footer__block'>
                <div className='footer__head'>Địa chỉ:</div>
                <div />

                <div className='footer__head'>Số điện thoại:</div>
                <div>+0123 - 456 - 78 - 89</div>
                <div>+0123 - 456 - 78 - 89</div>

                <div className='footer__head'>Thư điện tử:</div>
                <div>wisehotline@gmail.com</div>
              </div>
            </div>
            <div className='col-md-3 col-sm-3 col-xs-12'>
              <div className='footer__title'>Tài khoản</div>
              <div className='footer__block'>
                <div className='footer__accountitem'>Thông tin</div>
                <div className='footer__accountitem'>Lịch sử đơn hàng</div>
                <div className='footer__accountitem'>Khác</div>
              </div>
            </div>
            <div className='col-md-3 col-sm-3 col-xs-12'>
              <div className='footer__title'>Sản phẩm</div>
              <div className='footer__block'>
                <div className='footer__accountitem'>Tất cả sản phẩm</div>
                <div className='footer__accountitem'>Khác</div>
              </div>
            </div>
            <div className='col-md-3 col-sm-3 col-xs-12 footer__blocklast'>
              <div className='footer__title footer__title--center'>Đăng ký nhận thư</div>
              <div className='footer__block'>
                <div className='footer__accountitem footer__accountitem--center'>
                Đừng bỏ lỡ hàng ngàn sản phẩm và chương trình siêu hấp dẫn
                </div>
                <div className='footer__accountitem footer__accountitem--contact'>
                  <div className='footer__contactinput'>Email của bạn</div>
                  <div className='footer__contactbtn'>ĐĂNG KÝ</div>
                </div>
                <div className='footer__accountitem' />
                <div className='footer__accountitem' />
                <div className='footer__accountitem' />
                <div className='footer__accountitem' />
              </div>
            </div>
          </div> */}
        </div>
        <img className='footer__background' src='/images/static/footerbackground.jpg' alt='' />
        {/* }<div className='footer__bot'>
          <div className='footer__copyright'>© 2018 RxCodeGen Pty Ltd. Terms | Privacy <Link to='/admin/dashboard'>Aaa</Link></div>
          <div className='footer__dkkd'>Giấy chứng nhận Đăng ký Kinh doanh do Sở Kế hoạch và Đầu tư Thành phố Hồ Chí Minh cấp ngày 09/12/2015.</div>
        </div> */}
      </div>)
  }
}

export default Footer
