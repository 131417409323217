import React from 'react'
import { Link } from 'react-router-dom'

const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const RxCrud = global.rootRequire('components/shares/rxCrud').default

export const renderTableHead = (inthis, vm) =>
  <tr>
    <th className='rxwidth--100'>Name</th>
    <th className='rxwidth--100'>Desc</th>
    
    <th className={inthis.helpSortClass('date', ['rxwidth--120'])} onClick={(e) => inthis.onClickSort(e, 'date')}>Created at</th>
    <th className='rxwidth--100'>Action</th>
  </tr>

export const renderTableBody = (inthis, vm) =>
  global.rxu.array(inthis.state.data).map(perdata => (<tr key={perdata._id}>
    <td className='betable__gamelink'><Link to={`/admin/account/${perdata._id}`}>{perdata.name}</Link></td>
    <td>{perdata.desc}</td>
    <td><small>{global.rxu.date(perdata.date)}</small></td>
    {inthis.renderTableBodyBtns(inthis, perdata)}
  </tr>))

export const renderComponent = (vm) =>
  <AdminBlock name='Account' desc='Manage Account'>
    <div className='rxcol-100'>
    <RxCrud
      renderTableHead={vm.renderTableHead} renderTableBody={vm.renderTableBody} name='account' api='api_account' form={[
        { name: 'Name *', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.name || ''} onChange={(e) => inthis.onBlurData(e, 'name')} className='fullwidth-input' />) },
        { name: 'Desc', func: (inthis) => (<input tabIndex='5' type='text' value={inthis.state.editingData.desc} onChange={(e) => inthis.onBlurData(e, 'desc')} className='fullwidth-input' />) },
      ]}
    />
    </div>
  </AdminBlock>
