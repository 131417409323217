import * as Jsx from './review.jsx'
const RxComponent = global.rootRequire('components/shares/rxComponent').default

class productComponent extends RxComponent {
  constructor (props, context) {
    super(props, context, Jsx)
    this.state = {}
  }

  render () { return this.renderComponent() }
}

export default productComponent
